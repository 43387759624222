import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Warehouse } from '../../domain/Warehouse';
import { ListResults } from '../../common/List/List';
import { ProductQuantity } from '../../domain/ProductQuantity';
import { UserSetting } from '../../domain/UserSetting';

export const fetchWarehousesStart = () => ({
  type: actionTypes.FETCH_WAREHOUSES_START,
});

export const fetchWarehousesSuccess = (
  warehousesList: ListResults<Warehouse>,
) => ({
  type: actionTypes.FETCH_WAREHOUSES_SUCCESS,
  warehousesList,
});

export const fetchWarehousesFail = (warehousesError: HttpError) => ({
  type: actionTypes.FETCH_WAREHOUSES_FAIL,
  warehousesError,
});

export const fetchAvailableWarehousesStart = () => ({
  type: actionTypes.FETCH_AVAILABLE_WAREHOUSES_START,
});

export const fetchAvailableWarehousesSuccess = (
  availableWarehousesList: Warehouse[],
) => ({
  type: actionTypes.FETCH_AVAILABLE_WAREHOUSES_SUCCESS,
  availableWarehousesList,
});

export const fetchAvailableWarehousesFail = (
  availableWarehousesError: HttpError,
) => ({
  type: actionTypes.FETCH_AVAILABLE_WAREHOUSES_FAIL,
  availableWarehousesError,
});

export const fetchWarehouseStart = () => ({
  type: actionTypes.FETCH_WAREHOUSE_START,
});

export const fetchWarehouseSuccess = (warehouse: Warehouse) => ({
  type: actionTypes.FETCH_WAREHOUSE_SUCCESS,
  warehouse,
});

export const fetchWarehouseFail = (warehouseError: HttpError) => ({
  type: actionTypes.FETCH_WAREHOUSE_FAIL,
  warehouseError,
});

export const fetchWarehouseStocksStart = () => ({
  type: actionTypes.FETCH_WAREHOUSE_STOCKS_START,
});

export const fetchWarehouseStocksSuccess = (
  warehouseStocksList: ListResults<ProductQuantity>,
) => ({
  type: actionTypes.FETCH_WAREHOUSE_STOCKS_SUCCESS,
  warehouseStocksList,
});

export const fetchWarehouseStocksFail = (warehouseStocksError: HttpError) => ({
  type: actionTypes.FETCH_WAREHOUSE_STOCKS_FAIL,
  warehouseStocksError,
});

export const createWarehouseStart = () => ({
  type: actionTypes.CREATE_WAREHOUSE_START,
});

export const createWarehouseSuccess = (createdWarehouse: Warehouse) => ({
  type: actionTypes.CREATE_WAREHOUSE_SUCCESS,
  createdWarehouse,
});

export const createWarehouseFail = (warehouseCreateError: HttpError) => ({
  type: actionTypes.CREATE_WAREHOUSE_FAIL,
  warehouseCreateError,
});

export const updateWarehouseStart = () => ({
  type: actionTypes.UPDATE_WAREHOUSE_START,
});

export const updateWarehouseSuccess = () => ({
  type: actionTypes.UPDATE_WAREHOUSE_SUCCESS,
});

export const updateWarehouseFail = (warehouseUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_WAREHOUSE_FAIL,
  warehouseUpdateError,
});

export const deleteWarehouseStart = () => ({
  type: actionTypes.DELETE_WAREHOUSE_START,
});

export const deleteWarehouseSuccess = () => ({
  type: actionTypes.DELETE_WAREHOUSE_SUCCESS,
});

export const deleteWarehouseFail = (warehouseDeleteError: HttpError) => ({
  type: actionTypes.DELETE_WAREHOUSE_FAIL,
  warehouseDeleteError,
});

export const setSelectedWarehouse = (selectedWarehouse: number | null) => {
  if (!selectedWarehouse) {
    localStorage.removeItem('selectedWarehouse');
  } else {
    localStorage.setItem('selectedWarehouse', selectedWarehouse.toString());
  }

  return {
    type: actionTypes.SET_SELECTED_WAREHOUSE,
    selectedWarehouse,
  };
};

export const resetWarehouseStore = () => ({
  type: actionTypes.RESET_WAREHOUSE_STORE,
});

export const setSelectedWarehouseSettings = (settings: UserSetting[]) => {
  return {
    type: actionTypes.SET_WAREHOUSE_SELECTED_SETTINGS,
    settings,
  };
};
