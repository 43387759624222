import * as actionTypes from './actionTypes';
import { OrderFulfillmentItemsActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { OrderFulfillmentItem } from '../../domain/OrderFulfillmentItem';

export type OrderFulfillmentItemStateType = {
  orderFulfillmentItem: OrderFulfillmentItem | null;
  setCollectedQuantityLoading: boolean;
  setCollectedQuantitySuccess: boolean;
  setCollectedQuantityError: HttpError;
  confirmCollectedQuantityLoading: Record<string, boolean>;
  confirmCollectedQuantitySuccess: Record<string, boolean>;
  confirmCollectedQuantityError: HttpError;
};

export type OrderFulfillmentItemActionType = OrderFulfillmentItemStateType & {
  type: OrderFulfillmentItemsActionTypes;
  id: string;
};

export const initialState: OrderFulfillmentItemStateType = {
  orderFulfillmentItem: null,
  confirmCollectedQuantityError: null,
  confirmCollectedQuantityLoading: {},
  confirmCollectedQuantitySuccess: {},
  setCollectedQuantityError: null,
  setCollectedQuantityLoading: false,
  setCollectedQuantitySuccess: false,
};

const setCollectedQuantityStart = (
  state: OrderFulfillmentItemStateType,
  action: OrderFulfillmentItemActionType,
): OrderFulfillmentItemStateType => {
  const confirmedLoadings = state.confirmCollectedQuantityLoading;
  const confirmedSuccesses = state.confirmCollectedQuantitySuccess;

  confirmedLoadings[action.id] = false;
  confirmedSuccesses[action.id] = false;

  return {
    ...state,
    setCollectedQuantityLoading: true,
    orderFulfillmentItem: null,
    setCollectedQuantitySuccess: false,
    confirmCollectedQuantitySuccess: confirmedSuccesses,
    confirmCollectedQuantityLoading: confirmedLoadings,
  };
};

const setCollectedQuantitySuccess = (
  state: OrderFulfillmentItemStateType,
  action: OrderFulfillmentItemActionType,
): OrderFulfillmentItemStateType => ({
  ...state,
  orderFulfillmentItem: action.orderFulfillmentItem,
  setCollectedQuantityLoading: false,
  setCollectedQuantitySuccess: true,
  setCollectedQuantityError: null,
});

const setCollectedQuantityFail = (
  state: OrderFulfillmentItemStateType,
  action: OrderFulfillmentItemActionType,
): OrderFulfillmentItemStateType => ({
  ...state,
  setCollectedQuantityError: action.setCollectedQuantityError,
  setCollectedQuantityLoading: false,
});

const confirmCollectedQuantityStart = (
  state: OrderFulfillmentItemStateType,
  action: OrderFulfillmentItemActionType,
): OrderFulfillmentItemStateType => {
  const confirmedLoadings = state.confirmCollectedQuantityLoading;
  const confirmedSuccesses = state.confirmCollectedQuantitySuccess;

  confirmedLoadings[action.id] = true;
  confirmedSuccesses[action.id] = false;

  return {
    ...state,
    confirmCollectedQuantityLoading: confirmedLoadings,
    confirmCollectedQuantitySuccess: confirmedSuccesses,
    setCollectedQuantitySuccess: false,
    orderFulfillmentItem: null,
  };
};

const confirmCollectedQuantitySuccess = (
  state: OrderFulfillmentItemStateType,
  action: OrderFulfillmentItemActionType,
): OrderFulfillmentItemStateType => {
  const confirmedLoadings = state.confirmCollectedQuantityLoading;
  const confirmedSuccesses = state.confirmCollectedQuantitySuccess;

  confirmedLoadings[action.id] = false;
  confirmedSuccesses[action.id] = true;

  return {
    ...state,
    orderFulfillmentItem: action.orderFulfillmentItem,
    confirmCollectedQuantityLoading: confirmedLoadings,
    confirmCollectedQuantitySuccess: confirmedSuccesses,
  };
};

const confirmCollectedQuantityFail = (
  state: OrderFulfillmentItemStateType,
  action: OrderFulfillmentItemActionType,
): OrderFulfillmentItemStateType => {
  const confirmedLoadings = state.confirmCollectedQuantityLoading;

  confirmedLoadings[action.id] = false;

  return {
    ...state,
    confirmCollectedQuantityError: action.confirmCollectedQuantityError,
    confirmCollectedQuantityLoading: confirmedLoadings,
  };
};

const resetOrderFulfillmentItemStore = (): OrderFulfillmentItemStateType => {
  return {
    ...initialState,
    confirmCollectedQuantityLoading: {},
    confirmCollectedQuantitySuccess: {},
  };
};

const logout = (): OrderFulfillmentItemStateType => ({
  ...initialState,
  confirmCollectedQuantityLoading: {},
  confirmCollectedQuantitySuccess: {},
});

const reducer = (
  state = initialState,
  action: OrderFulfillmentItemActionType,
) => {
  switch (action.type) {
    case actionTypes.SET_COLLECTED_QUANTITY_START:
      return setCollectedQuantityStart(state, action);
    case actionTypes.SET_COLLECTED_QUANTITY_SUCCESS:
      return setCollectedQuantitySuccess(state, action);
    case actionTypes.SET_COLLECTED_QUANTITY_FAIL:
      return setCollectedQuantityFail(state, action);
    case actionTypes.CONFIRM_COLLECTED_QUANTITY_START:
      return confirmCollectedQuantityStart(state, action);
    case actionTypes.CONFIRM_COLLECTED_QUANTITY_SUCCESS:
      return confirmCollectedQuantitySuccess(state, action);
    case actionTypes.CONFIRM_COLLECTED_QUANTITY_FAIL:
      return confirmCollectedQuantityFail(state, action);
    case actionTypes.RESET_ORDER_FULFILLMENT_ITEMS_STORE:
      return resetOrderFulfillmentItemStore();
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;
