import * as actionTypes from './actionTypes';
import { CourierActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Courier } from '../../domain/Courier';
import { ListResults } from '../../common/List/List';

export type CourierStateType = {
  couriersList: ListResults<Courier> | null;
  couriersLoading: boolean;
  couriersError: HttpError;
  couriersListUpdateNeeded: boolean;
  courierStatusLoading: boolean;
  courierStatusSuccess: boolean;
  courierStatusError: HttpError;
};

export type CourierActionType = CourierStateType & {
  type: CourierActionTypes;
};

export const initialState: CourierStateType = {
  couriersList: null,
  couriersLoading: true,
  couriersError: null,
  couriersListUpdateNeeded: false,
  courierStatusError: null,
  courierStatusSuccess: false,
  courierStatusLoading: false,
};

const fetchCouriersStart = (state: CourierStateType): CourierStateType => ({
  ...state,
  couriersLoading: true,
});

const fetchCouriersSuccess = (
  state: CourierStateType,
  action: CourierActionType,
): CourierStateType => ({
  ...state,
  couriersList: action.couriersList,
  couriersLoading: false,
  couriersError: null,
  couriersListUpdateNeeded: false,
});

const fetchCouriersFail = (
  state: CourierStateType,
  action: CourierActionType,
): CourierStateType => ({
  ...state,
  couriersError: action.couriersError,
  couriersLoading: false,
});

const toggleCourierStatusStart = (
  state: CourierStateType,
): CourierStateType => ({
  ...state,
  courierStatusLoading: true,
});

const toggleCourierStatusSuccess = (
  state: CourierStateType,
): CourierStateType => ({
  ...state,
  courierStatusLoading: false,
  courierStatusError: null,
  courierStatusSuccess: true,
  couriersListUpdateNeeded: true,
});

const toggleCourierStatusFail = (
  state: CourierStateType,
  action: CourierActionType,
): CourierStateType => ({
  ...state,
  courierStatusLoading: false,
  courierStatusError: action.courierStatusError,
});

const resetCourierStore = (): CourierStateType => ({ ...initialState });

const logout = (): CourierStateType => ({ ...initialState });

const reducer = (state = initialState, action: CourierActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_COURIERS_START:
      return fetchCouriersStart(state);
    case actionTypes.FETCH_COURIERS_SUCCESS:
      return fetchCouriersSuccess(state, action);
    case actionTypes.FETCH_COURIERS_FAIL:
      return fetchCouriersFail(state, action);
    case actionTypes.TOGGLE_COURIER_STATUS_START:
      return toggleCourierStatusStart(state);
    case actionTypes.TOGGLE_COURIER_STATUS_SUCCESS:
      return toggleCourierStatusSuccess(state);
    case actionTypes.TOGGLE_COURIER_STATUS_FAIL:
      return toggleCourierStatusFail(state, action);
    case actionTypes.RESET_COURIER_STORE:
      return resetCourierStore();
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;
