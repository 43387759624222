import axios from '../../config/Axios/axios-instance';
import {
  fetchLanguageFail,
  fetchLanguagesFail,
  fetchLanguagesStart,
  fetchLanguagesSuccess,
  fetchLanguageStart,
  fetchLanguageSuccess,
} from './actions';
import { Dispatch } from 'redux';

const API_URL = '/languages';

export const fetchLanguage = (locale: string) => (dispatch: Dispatch) => {
  dispatch(fetchLanguageStart());
  return axios
    .get(`${API_URL}/${locale}`)
    .then((response) => {
      dispatch(fetchLanguageSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchLanguageFail(err.response.data.error));
    });
};

export const fetchLanguages = () => (dispatch: Dispatch) => {
  dispatch(fetchLanguagesStart());
  return axios
    .get(API_URL)
    .then((response) => {
      dispatch(fetchLanguagesSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchLanguagesFail(err.response.data.error));
    });
};
