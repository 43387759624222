import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Company } from '../../domain/Company';
import { ListResults } from '../../common/List/List';
import { Asset } from '../../domain/Asset';
import { UserSetting } from '../../domain/UserSetting';

export const fetchCompaniesStart = () => ({
  type: actionTypes.FETCH_COMPANIES_START,
});

export const fetchCompaniesSuccess = (companiesList: ListResults<Company>) => ({
  type: actionTypes.FETCH_COMPANIES_SUCCESS,
  companiesList,
});

export const fetchCompaniesFail = (companiesError: HttpError) => ({
  type: actionTypes.FETCH_COMPANIES_FAIL,
  companiesError,
});

export const fetchCompanyOptionsStart = () => ({
  type: actionTypes.FETCH_COMPANY_OPTIONS_START,
});

export const fetchCompanyOptionsSuccess = (companyOptions: Company[]) => ({
  type: actionTypes.FETCH_COMPANY_OPTIONS_SUCCESS,
  companyOptions,
});

export const fetchCompanyOptionsFail = (companyOptionsError: HttpError) => ({
  type: actionTypes.FETCH_COMPANY_OPTIONS_FAIL,
  companyOptionsError,
});

export const fetchCompanyStart = () => ({
  type: actionTypes.FETCH_COMPANY_START,
});

export const fetchCompanySuccess = (company: Company) => ({
  type: actionTypes.FETCH_COMPANY_SUCCESS,
  company,
});

export const fetchCompanyFail = (companyError: HttpError) => ({
  type: actionTypes.FETCH_COMPANY_FAIL,
  companyError,
});

export const createCompanyStart = () => ({
  type: actionTypes.CREATE_COMPANY_START,
});

export const createCompanySuccess = (createdCompany: Company) => ({
  type: actionTypes.CREATE_COMPANY_SUCCESS,
  createdCompany,
});

export const createCompanyFail = (companyCreateError: HttpError) => ({
  type: actionTypes.CREATE_COMPANY_FAIL,
  companyCreateError,
});

export const registerCompanyStart = () => ({
  type: actionTypes.REGISTER_COMPANY_START,
});

export const registerCompanySuccess = () => ({
  type: actionTypes.REGISTER_COMPANY_SUCCESS,
});

export const registerCompanyFail = (registerCompanyError: HttpError) => ({
  type: actionTypes.REGISTER_COMPANY_FAIL,
  registerCompanyError,
});

export const updateCompanyStart = () => ({
  type: actionTypes.UPDATE_COMPANY_START,
});

export const updateCompanySuccess = () => ({
  type: actionTypes.UPDATE_COMPANY_SUCCESS,
});

export const updateCompanyFail = (companyUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_COMPANY_FAIL,
  companyUpdateError,
});

export const setSelectedCompany = (
  selectedCompany: number | null,
  companyLogo: Asset | null,
) => {
  localStorage.removeItem('companyLogo');

  if (companyLogo) {
    localStorage.setItem('companyLogo', JSON.stringify(companyLogo));
  }

  return {
    type: actionTypes.SET_SELECTED_COMPANY,
    selectedCompany,
    companyLogo,
  };
};

export const toggleCompanyStatusStart = () => ({
  type: actionTypes.TOGGLE_COMPANY_STATUS_START,
});

export const toggleCompanyStatusSuccess = () => ({
  type: actionTypes.TOGGLE_COMPANY_STATUS_SUCCESS,
});

export const toggleCompanyStatusFail = (companyStatusError: HttpError) => ({
  type: actionTypes.TOGGLE_COMPANY_STATUS_FAIL,
  companyStatusError,
});

export const deleteCompanyStart = () => ({
  type: actionTypes.DELETE_COMPANY_START,
});

export const deleteCompanySuccess = () => ({
  type: actionTypes.DELETE_COMPANY_SUCCESS,
});

export const deleteCompanyFail = (companyDeleteError: HttpError) => ({
  type: actionTypes.DELETE_COMPANY_FAIL,
  companyDeleteError,
});

export const updateCompanyBalanceStart = () => ({
  type: actionTypes.UPDATE_COMPANY_BALANCE_START,
});

export const updateCompanyBalanceSuccess = () => ({
  type: actionTypes.UPDATE_COMPANY_BALANCE_SUCCESS,
});

export const updateCompanyBalanceFail = (
  companyBalanceUpdateError: HttpError,
) => ({
  type: actionTypes.UPDATE_COMPANY_BALANCE_FAIL,
  companyBalanceUpdateError,
});

export const assignWarehouseStart = () => ({
  type: actionTypes.ASSIGN_WAREHOUSE_START,
});

export const assignWarehouseSuccess = () => ({
  type: actionTypes.ASSIGN_WAREHOUSE_SUCCESS,
});

export const assignWarehouseFail = (assignWarehouseError: HttpError) => ({
  type: actionTypes.ASSIGN_WAREHOUSE_FAIL,
  assignWarehouseError,
});

export const removeWarehouseStart = () => ({
  type: actionTypes.REMOVE_WAREHOUSE_START,
});

export const removeWarehouseSuccess = () => ({
  type: actionTypes.REMOVE_WAREHOUSE_SUCCESS,
});

export const removeWarehouseFail = (removeWarehouseError: HttpError) => ({
  type: actionTypes.REMOVE_WAREHOUSE_FAIL,
  removeWarehouseError,
});

export const setCompanySettingsStart = () => ({
  type: actionTypes.SET_COMPANY_SETTINGS_START,
});

export const setCompanySettingsSuccess = () => ({
  type: actionTypes.SET_COMPANY_SETTINGS_SUCCESS,
});

export const setCompanySettingsFail = (setCompanySettingsError: HttpError) => ({
  type: actionTypes.SET_COMPANY_SETTINGS_FAIL,
  setCompanySettingsError,
});

export const resetCompanyStore = () => ({
  type: actionTypes.RESET_COMPANY_STORE,
});

export const resetWholeCompanyStore = () => ({
  type: actionTypes.RESET_WHOLE_COMPANY_STORE,
});

export const resetCompanyWarehouseStore = () => ({
  type: actionTypes.RESET_COMPANY_WAREHOUSE_STORE,
});

export const setSelectedCompanySettings = (settings: UserSetting[]) => {
  return {
    type: actionTypes.SET_COMPANY_SELECTED_SETTINGS,
    settings,
  };
};
