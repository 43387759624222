import * as actionTypes from './actionTypes';
import { OrderFulfillmentActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { OrderFulfillment } from '../../domain/OrderFulfillment';

export type OrderFulfillmentStateType = {
  orderFulfillmentsList: ListResults<OrderFulfillment> | null;
  orderFulfillmentsLoading: boolean;
  orderFulfillmentsError: HttpError;
  orderFulfillment: OrderFulfillment | null;
  orderFulfillmentLoading: boolean;
  orderFulfillmentError: HttpError;
  orderFulfillmentsListUpdateNeeded: boolean;
  completeOrderFulfillmentError: HttpError;
  completeOrderFulfillmentLoading: boolean;
  completeOrderFulfillmentSuccess: boolean;
  uploadTrackingDataLoading: boolean;
  uploadTrackingDataSuccess: boolean;
  uploadTrackingDataError: HttpError;
};

export type OrderFulfillmentActionType = OrderFulfillmentStateType & {
  type: OrderFulfillmentActionTypes;
};

export const initialState: OrderFulfillmentStateType = {
  orderFulfillmentsList: null,
  orderFulfillmentsLoading: true,
  orderFulfillmentsError: null,
  orderFulfillmentsListUpdateNeeded: false,
  orderFulfillment: null,
  orderFulfillmentLoading: false,
  orderFulfillmentError: null,
  completeOrderFulfillmentError: null,
  completeOrderFulfillmentSuccess: false,
  completeOrderFulfillmentLoading: false,
  uploadTrackingDataSuccess: false,
  uploadTrackingDataError: null,
  uploadTrackingDataLoading: false,
};

const fetchOrderFulfillmentsStart = (
  state: OrderFulfillmentStateType,
): OrderFulfillmentStateType => ({
  ...state,
  orderFulfillmentsLoading: true,
});

const fetchOrderFulfillmentsSuccess = (
  state: OrderFulfillmentStateType,
  action: OrderFulfillmentActionType,
): OrderFulfillmentStateType => ({
  ...state,
  orderFulfillmentsList: action.orderFulfillmentsList,
  orderFulfillmentsLoading: false,
  orderFulfillmentsError: null,
  orderFulfillmentsListUpdateNeeded: false,
  uploadTrackingDataSuccess: false,
});

const fetchOrderFulfillmentsFail = (
  state: OrderFulfillmentStateType,
  action: OrderFulfillmentActionType,
): OrderFulfillmentStateType => ({
  ...state,
  orderFulfillmentsError: action.orderFulfillmentsError,
  orderFulfillmentsLoading: false,
});

const fetchOrderFulfillmentStart = (
  state: OrderFulfillmentStateType,
): OrderFulfillmentStateType => ({
  ...state,
  orderFulfillmentLoading: true,
});

const fetchOrderFulfillmentSuccess = (
  state: OrderFulfillmentStateType,
  action: OrderFulfillmentActionType,
): OrderFulfillmentStateType => ({
  ...state,
  orderFulfillment: action.orderFulfillment,
  orderFulfillmentLoading: false,
  orderFulfillmentError: null,
});

const fetchOrderFulfillmentFail = (
  state: OrderFulfillmentStateType,
  action: OrderFulfillmentActionType,
): OrderFulfillmentStateType => ({
  ...state,
  orderFulfillmentError: action.orderFulfillmentError,
  orderFulfillmentLoading: false,
});

const completeOrderFulfillmentStart = (
  state: OrderFulfillmentStateType,
): OrderFulfillmentStateType => ({
  ...state,
  completeOrderFulfillmentLoading: true,
  completeOrderFulfillmentSuccess: false,
});

const completeOrderFulfillmentSuccess = (
  state: OrderFulfillmentStateType,
): OrderFulfillmentStateType => ({
  ...state,
  completeOrderFulfillmentLoading: false,
  completeOrderFulfillmentError: null,
  completeOrderFulfillmentSuccess: true,
});

const completeOrderFulfillmentFail = (
  state: OrderFulfillmentStateType,
  action: OrderFulfillmentActionType,
): OrderFulfillmentStateType => ({
  ...state,
  completeOrderFulfillmentLoading: false,
  completeOrderFulfillmentError: action.completeOrderFulfillmentError,
});

const uploadTrackingDataStart = (
  state: OrderFulfillmentStateType,
): OrderFulfillmentStateType => ({
  ...state,
  uploadTrackingDataLoading: true,
});

const uploadTrackingDataSuccess = (
  state: OrderFulfillmentStateType,
): OrderFulfillmentStateType => ({
  ...state,
  uploadTrackingDataLoading: false,
  uploadTrackingDataError: null,
  uploadTrackingDataSuccess: true,
  orderFulfillmentsListUpdateNeeded: true,
});

const uploadTrackingDataFail = (
  state: OrderFulfillmentStateType,
  action: OrderFulfillmentActionType,
): OrderFulfillmentStateType => ({
  ...state,
  uploadTrackingDataLoading: false,
  uploadTrackingDataError: action.uploadTrackingDataError,
});

const resetOrderFulfillmentStore = (): OrderFulfillmentStateType => ({
  ...initialState,
});

const logout = (): OrderFulfillmentStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: OrderFulfillmentActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ORDER_FULFILLMENTS_START:
      return fetchOrderFulfillmentsStart(state);
    case actionTypes.FETCH_ORDER_FULFILLMENTS_SUCCESS:
      return fetchOrderFulfillmentsSuccess(state, action);
    case actionTypes.FETCH_ORDER_FULFILLMENTS_FAIL:
      return fetchOrderFulfillmentsFail(state, action);
    case actionTypes.FETCH_ORDER_FULFILLMENT_START:
      return fetchOrderFulfillmentStart(state);
    case actionTypes.FETCH_ORDER_FULFILLMENT_SUCCESS:
      return fetchOrderFulfillmentSuccess(state, action);
    case actionTypes.FETCH_ORDER_FULFILLMENT_FAIL:
      return fetchOrderFulfillmentFail(state, action);
    case actionTypes.COMPLETE_ORDER_FULFILLMENT_START:
      return completeOrderFulfillmentStart(state);
    case actionTypes.COMPLETE_ORDER_FULFILLMENT_SUCCESS:
      return completeOrderFulfillmentSuccess(state);
    case actionTypes.COMPLETE_ORDER_FULFILLMENT_FAIL:
      return completeOrderFulfillmentFail(state, action);
    case actionTypes.UPLOAD_TRACKING_DATA_START:
      return uploadTrackingDataStart(state);
    case actionTypes.UPLOAD_TRACKING_DATA_SUCCESS:
      return uploadTrackingDataSuccess(state);
    case actionTypes.UPLOAD_TRACKING_DATA_FAIL:
      return uploadTrackingDataFail(state, action);
    case actionTypes.RESET_ORDER_FULFILLMENT_STORE:
      return resetOrderFulfillmentStore();
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;
