export const FETCH_WAREHOUSES_START = 'FETCH_WAREHOUSES_START';
export const FETCH_WAREHOUSES_SUCCESS = 'FETCH_WAREHOUSES_SUCCESS';
export const FETCH_WAREHOUSES_FAIL = 'FETCH_WAREHOUSES_FAIL';

export const FETCH_WAREHOUSE_START = 'FETCH_WAREHOUSE_START';
export const FETCH_WAREHOUSE_SUCCESS = 'FETCH_WAREHOUSE_SUCCESS';
export const FETCH_WAREHOUSE_FAIL = 'FETCH_WAREHOUSE_FAIL';

export const FETCH_WAREHOUSE_STOCKS_START = 'FETCH_WAREHOUSE_STOCKS_START';
export const FETCH_WAREHOUSE_STOCKS_SUCCESS = 'FETCH_WAREHOUSE_STOCKS_SUCCESS';
export const FETCH_WAREHOUSE_STOCKS_FAIL = 'FETCH_WAREHOUSE_STOCKS_FAIL';

export const FETCH_AVAILABLE_WAREHOUSES_START =
  'FETCH_AVAILABLE_WAREHOUSES_START';
export const FETCH_AVAILABLE_WAREHOUSES_SUCCESS =
  'FETCH_AVAILABLE_WAREHOUSES_SUCCESS';
export const FETCH_AVAILABLE_WAREHOUSES_FAIL =
  'FETCH_AVAILABLE_WAREHOUSES_FAIL';

export const CREATE_WAREHOUSE_START = 'CREATE_WAREHOUSE_START';
export const CREATE_WAREHOUSE_SUCCESS = 'CREATE_WAREHOUSE_SUCCESS';
export const CREATE_WAREHOUSE_FAIL = 'CREATE_WAREHOUSE_FAIL';

export const UPDATE_WAREHOUSE_START = 'UPDATE_WAREHOUSE_START';
export const UPDATE_WAREHOUSE_SUCCESS = 'UPDATE_WAREHOUSE_SUCCESS';
export const UPDATE_WAREHOUSE_FAIL = 'UPDATE_WAREHOUSE_FAIL';

export const DELETE_WAREHOUSE_START = 'DELETE_WAREHOUSE_START';
export const DELETE_WAREHOUSE_SUCCESS = 'DELETE_WAREHOUSE_SUCCESS';
export const DELETE_WAREHOUSE_FAIL = 'DELETE_WAREHOUSE_FAIL';

export const SET_SELECTED_WAREHOUSE = 'SET_SELECTED_WAREHOUSE';
export const SET_WAREHOUSE_SELECTED_SETTINGS =
  'SET_WAREHOUSE_SELECTED_SETTINGS';

export const RESET_WAREHOUSE_STORE = 'RESET_WAREHOUSE_STORE';

export const LOGOUT = 'LOGOUT';

export type WarehouseActionTypes =
  | typeof FETCH_WAREHOUSES_START
  | typeof FETCH_WAREHOUSES_SUCCESS
  | typeof FETCH_WAREHOUSES_FAIL
  | typeof FETCH_WAREHOUSE_START
  | typeof FETCH_WAREHOUSE_SUCCESS
  | typeof FETCH_WAREHOUSE_FAIL
  | typeof FETCH_WAREHOUSE_STOCKS_START
  | typeof FETCH_WAREHOUSE_STOCKS_SUCCESS
  | typeof FETCH_WAREHOUSE_STOCKS_FAIL
  | typeof CREATE_WAREHOUSE_START
  | typeof CREATE_WAREHOUSE_SUCCESS
  | typeof CREATE_WAREHOUSE_FAIL
  | typeof UPDATE_WAREHOUSE_START
  | typeof UPDATE_WAREHOUSE_SUCCESS
  | typeof UPDATE_WAREHOUSE_FAIL
  | typeof RESET_WAREHOUSE_STORE
  | typeof DELETE_WAREHOUSE_START
  | typeof DELETE_WAREHOUSE_SUCCESS
  | typeof DELETE_WAREHOUSE_FAIL
  | typeof FETCH_AVAILABLE_WAREHOUSES_START
  | typeof FETCH_AVAILABLE_WAREHOUSES_SUCCESS
  | typeof FETCH_AVAILABLE_WAREHOUSES_FAIL
  | typeof SET_WAREHOUSE_SELECTED_SETTINGS
  | typeof SET_SELECTED_WAREHOUSE
  | typeof LOGOUT;
