export const SET_COLLECTED_QUANTITY_START = 'SET_COLLECTED_QUANTITY_START';
export const SET_COLLECTED_QUANTITY_SUCCESS = 'SET_COLLECTED_QUANTITY_SUCCESS';
export const SET_COLLECTED_QUANTITY_FAIL = 'SET_COLLECTED_QUANTITY_FAIL';

export const CONFIRM_COLLECTED_QUANTITY_START =
  'CONFIRM_COLLECTED_QUANTITY_START';
export const CONFIRM_COLLECTED_QUANTITY_SUCCESS =
  'CONFIRM_COLLECTED_QUANTITY_SUCCESS';
export const CONFIRM_COLLECTED_QUANTITY_FAIL =
  'CONFIRM_COLLECTED_QUANTITY_FAIL';

export const RESET_ORDER_FULFILLMENT_ITEMS_STORE =
  'RESET_ORDER_FULFILLMENT_ITEMS_STORE';

export const LOGOUT = 'LOGOUT';

export type OrderFulfillmentItemsActionTypes =
  | typeof SET_COLLECTED_QUANTITY_START
  | typeof SET_COLLECTED_QUANTITY_SUCCESS
  | typeof SET_COLLECTED_QUANTITY_FAIL
  | typeof CONFIRM_COLLECTED_QUANTITY_START
  | typeof CONFIRM_COLLECTED_QUANTITY_SUCCESS
  | typeof CONFIRM_COLLECTED_QUANTITY_FAIL
  | typeof RESET_ORDER_FULFILLMENT_ITEMS_STORE
  | typeof LOGOUT;
