import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import styles from './WarehouseSelectForm.module.scss';
import { setSelectedWarehouse } from '../../store/warehouse/actions';
import { useIntl } from 'react-intl';
import { Warehouse } from '../../domain/Warehouse';
import { translate } from '../../utility/messageTranslator/translate';
import Form from '../../common/Form/Form';
import FormCard from '../../common/FormCard/FormCard';
import TextField from '../../common/TextField/TextField';
import Button from '../../common/Button/Button';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { useForm } from '../../hooks/useForm/useForm';
import { UserSetting, UserSettingType } from '../../domain/UserSetting';
import * as userSettingService from '../../store/user-setting/service';

type Props = {
  warehouseOptions: Warehouse[];
  onWarehouseSelect: (warehouseId: number) => void;
  onSuccessSelect?: () => void;
  selectedWarehouse: number | null;
  onSetSetting: (type: UserSettingType, value: string) => void;
  createdUserSetting: UserSetting | null;
};

type FormInputs = {
  warehouseId: string;
};

export const WarehouseSelectForm = ({
  warehouseOptions,
  onWarehouseSelect,
  onSuccessSelect,
  selectedWarehouse,
  createdUserSetting,
  onSetSetting,
}: Props) => {
  const [isSelectedLoading, setIsSelectLoading] = useState<boolean>(false);
  const intl = useIntl();

  const options = useMemo(
    () =>
      warehouseOptions.map((warehouse: Warehouse) => {
        return {
          value: warehouse.id.toString(),
          label: warehouse.name,
        };
      }) || [],
    [warehouseOptions],
  );

  const INPUTS = [
    {
      name: 'warehouseId',
      label: translate(intl, 'WAREHOUSE.SELECT_FORM_NAME'),
      type: 'autocomplete',
      validation: [
        {
          type: 'required',
        },
      ],
      options: [],
      value: selectedWarehouse?.toString() ?? '',
    },
  ];

  useEffect(() => {
    if (
      createdUserSetting?.type === UserSettingType.WAREHOUSE &&
      isSelectedLoading
    ) {
      const warehouse = warehouseOptions.find(
        (warehouseOption) =>
          warehouseOption.id === Number(createdUserSetting.value),
      );

      if (warehouse) {
        onWarehouseSelect(warehouse.id);
      }

      onSuccessSelect?.();

      setIsSelectLoading(false);
    }
  }, [createdUserSetting]);

  const handleSubmit = async (submitInputs: FormInputs) => {
    onSetSetting(UserSettingType.WAREHOUSE, submitInputs.warehouseId);
    setIsSelectLoading(true);
  };

  const {
    inputs,
    onSubmit,
    onInputChange,
    onLoseInputFocus,
    onSelectChange,
    setNewInputObject,
  } = useForm<FormInputs>(INPUTS, handleSubmit);

  useEffect(() => {
    if (options) {
      setNewInputObject('warehouseId', {
        options: options,
      });
    }
  }, [options]);

  return (
    <div className={styles.warehouseSelectForm}>
      <Form className={styles.form} error={null} onSubmit={onSubmit}>
        <FormCard>
          {inputs.map((input) => (
            <TextField
              key={input.name}
              onChange={onInputChange}
              value={input.value?.toString() ?? ''}
              label={input.label ?? ''}
              errors={input.validationErrors ?? []}
              onBlur={onLoseInputFocus}
              name={input.name}
              type={input.type}
              options={input.options}
              onSelectChange={onSelectChange}
            />
          ))}
          <Button
            isLoadingButton
            onClick={onSubmit}
            buttonVariant="contained"
            color="primary"
            type="submit"
          >
            {translate(intl, 'WAREHOUSE.SELECT_WAREHOUSE')}
          </Button>
        </FormCard>
      </Form>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedWarehouse: state.warehouse.selectedWarehouse,
  createdUserSetting: state.userSetting.createdUserSetting,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onWarehouseSelect: (selectWarehouseId: number) =>
    dispatch(setSelectedWarehouse(selectWarehouseId)),
  onSetSetting: (type: UserSettingType, value: string) =>
    dispatch(
      userSettingService.setUserSetting({
        type,
        value,
      }),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WarehouseSelectForm);
