export const SET_ARRIVED_QUANTITY_START = 'SET_ARRIVED_QUANTITY_START';
export const SET_ARRIVED_QUANTITY_SUCCESS = 'SET_ARRIVED_QUANTITY_SUCCESS';
export const SET_ARRIVED_QUANTITY_FAIL = 'SET_ARRIVED_QUANTITY_FAIL';

export const CONFIRM_ARRIVED_QUANTITY_START = 'CONFIRM_ARRIVED_QUANTITY_START';
export const CONFIRM_ARRIVED_QUANTITY_SUCCESS =
  'CONFIRM_ARRIVED_QUANTITY_SUCCESS';
export const CONFIRM_ARRIVED_QUANTITY_FAIL = 'CONFIRM_ARRIVED_QUANTITY_FAIL';

export const RESET_BILL_OF_LADING_PRODUCTS_STORE =
  'RESET_BILL_OF_LADING_PRODUCTS_STORE';

export const LOGOUT = 'LOGOUT';

export type BillOfLadingProductsActionTypes =
  | typeof SET_ARRIVED_QUANTITY_START
  | typeof SET_ARRIVED_QUANTITY_SUCCESS
  | typeof SET_ARRIVED_QUANTITY_FAIL
  | typeof CONFIRM_ARRIVED_QUANTITY_START
  | typeof CONFIRM_ARRIVED_QUANTITY_SUCCESS
  | typeof CONFIRM_ARRIVED_QUANTITY_FAIL
  | typeof RESET_BILL_OF_LADING_PRODUCTS_STORE
  | typeof LOGOUT;
