import * as actionTypes from './actionTypes';
import { CompanyActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Company } from '../../domain/Company';
import { ListResults } from '../../common/List/List';
import { Asset } from '../../domain/Asset';
import { UserSetting, UserSettingType } from '../../domain/UserSetting';

export type CompanyStateType = {
  companiesList: ListResults<Company> | null;
  companiesLoading: boolean;
  companiesError: HttpError;
  companyOptions: Company[];
  companyOptionsLoading: boolean;
  companyOptionsError: HttpError;
  companiesListUpdateNeeded: boolean;
  companyCreateLoading: boolean;
  companyCreateError: HttpError;
  companyCreateSuccess: boolean;
  createdCompany: Company | null;
  companyUpdateLoading: boolean;
  companyUpdateError: HttpError;
  companyUpdateSuccess: boolean;
  company: Company | null;
  companyLoading: boolean;
  companyError: HttpError;
  selectedCompany: number | null;
  companyLogo: Asset | null;
  companyStatusLoading: boolean;
  companyStatusSuccess: boolean;
  companyStatusError: HttpError;
  companyDeleteLoading: boolean;
  companyDeleteError: HttpError;
  companyBalanceUpdateSuccess: boolean;
  companyBalanceUpdateLoading: boolean;
  companyBalanceUpdateError: HttpError;
  assignWarehouseSuccess: boolean;
  assignWarehouseLoading: boolean;
  assignWarehouseError: HttpError;
  removeWarehouseSuccess: boolean;
  removeWarehouseLoading: boolean;
  removeWarehouseError: HttpError;
  setCompanySettingsSuccess: boolean;
  setCompanySettingsLoading: boolean;
  setCompanySettingsError: HttpError;
  registerCompanySuccess: boolean;
  registerCompanyLoading: boolean;
  registerCompanyError: HttpError;
};

export type CompanyActionType = CompanyStateType & {
  type: CompanyActionTypes;
  settings: UserSetting[];
};

export const initialState: CompanyStateType = {
  companiesList: null,
  companiesLoading: true,
  companiesError: null,
  companyOptions: [],
  companyOptionsLoading: true,
  companyOptionsError: null,
  companiesListUpdateNeeded: false,
  companyCreateLoading: false,
  companyCreateError: null,
  companyCreateSuccess: false,
  createdCompany: null,
  companyUpdateLoading: false,
  companyUpdateError: null,
  companyUpdateSuccess: false,
  company: null,
  companyLoading: false,
  companyError: null,
  selectedCompany: null,
  companyLogo: localStorage.getItem('companyLogo')
    ? (JSON.parse(localStorage.getItem('companyLogo') ?? '') as Asset)
    : null,
  companyStatusError: null,
  companyStatusSuccess: false,
  companyStatusLoading: false,
  companyDeleteLoading: false,
  companyDeleteError: null,
  companyBalanceUpdateError: null,
  companyBalanceUpdateLoading: false,
  companyBalanceUpdateSuccess: false,
  assignWarehouseError: null,
  assignWarehouseLoading: false,
  assignWarehouseSuccess: false,
  removeWarehouseError: null,
  removeWarehouseLoading: false,
  removeWarehouseSuccess: false,
  setCompanySettingsError: null,
  setCompanySettingsSuccess: false,
  setCompanySettingsLoading: false,
  registerCompanyError: null,
  registerCompanyLoading: false,
  registerCompanySuccess: false,
};

const fetchCompaniesStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companiesLoading: true,
});

const fetchCompaniesSuccess = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companiesList: action.companiesList,
  companiesLoading: false,
  companiesError: null,
  companiesListUpdateNeeded: false,
});

const fetchCompaniesFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companiesError: action.companiesError,
  companiesLoading: false,
});

const fetchCompanyOptionsStart = (
  state: CompanyStateType,
): CompanyStateType => ({
  ...state,
  companyOptionsLoading: true,
});

const fetchCompanyOptionsSuccess = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyOptions: action.companyOptions,
  companyOptionsLoading: false,
  companyOptionsError: null,
});

const fetchCompanyOptionsFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyOptionsError: action.companyOptionsError,
  companyOptionsLoading: false,
});

const fetchCompanyStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyLoading: true,
  companyUpdateSuccess: false,
  companyCreateSuccess: false,
  companyCreateError: null,
  companyUpdateError: null,
  companyBalanceUpdateSuccess: false,
  setCompanySettingsSuccess: false,
});

const fetchCompanySuccess = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  company: action.company,
  companyLoading: false,
  companyError: null,
});

const fetchCompanyFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyError: action.companyError,
  companyLoading: false,
});

const createCompanyStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyCreateLoading: true,
});

const createCompanySuccess = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyCreateLoading: false,
  companyCreateError: null,
  companyCreateSuccess: true,
  createdCompany: action.createdCompany,
  companiesListUpdateNeeded: true,
});

const createCompanyFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyCreateLoading: false,
  companyCreateError: action.companyCreateError,
});

const registerCompanyStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  registerCompanyLoading: true,
});

const registerCompanySuccess = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  registerCompanyLoading: false,
  registerCompanyError: null,
  registerCompanySuccess: true,
});

const registerCompanyFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  registerCompanyLoading: false,
  registerCompanyError: action.registerCompanyError,
});

const updateCompanyStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyUpdateLoading: true,
});

const updateCompanySuccess = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyUpdateLoading: false,
  companyUpdateError: null,
  companyUpdateSuccess: true,
  companiesListUpdateNeeded: true,
});

const updateCompanyFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyUpdateLoading: false,
  companyUpdateError: action.companyUpdateError,
});

const setSelectedCompany = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  selectedCompany: action.selectedCompany,
  companyLogo: action.companyLogo,
});

const toggleCompanyStatusStart = (
  state: CompanyStateType,
): CompanyStateType => ({
  ...state,
  companyStatusLoading: true,
});

const toggleCompanyStatusSuccess = (
  state: CompanyStateType,
): CompanyStateType => ({
  ...state,
  companyStatusLoading: false,
  companyStatusError: null,
  companyStatusSuccess: true,
  companiesListUpdateNeeded: true,
});

const toggleCompanyStatusFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyStatusLoading: false,
  companyStatusError: action.companyStatusError,
});

const deleteCompanyStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyDeleteLoading: true,
});

const deleteCompanySuccess = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyDeleteLoading: false,
  companyDeleteError: null,
  companiesListUpdateNeeded: true,
});

const deleteCompanyFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyDeleteLoading: false,
  companyDeleteError: action.companyDeleteError,
});

const updateCompanyBalanceStart = (
  state: CompanyStateType,
): CompanyStateType => ({
  ...state,
  companyBalanceUpdateLoading: true,
});

const updateCompanyBalanceSuccess = (
  state: CompanyStateType,
): CompanyStateType => ({
  ...state,
  companyBalanceUpdateLoading: false,
  companyBalanceUpdateError: null,
  companyBalanceUpdateSuccess: true,
});

const updateCompanyBalanceFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyBalanceUpdateLoading: false,
  companyBalanceUpdateError: action.companyBalanceUpdateError,
});

const assignWarehouseStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  assignWarehouseLoading: true,
});

const assignWarehouseSuccess = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  assignWarehouseLoading: false,
  assignWarehouseError: null,
  assignWarehouseSuccess: true,
});

const assignWarehouseFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  assignWarehouseLoading: false,
  assignWarehouseError: action.assignWarehouseError,
});

const removeWarehouseStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  removeWarehouseLoading: true,
});

const removeWarehouseSuccess = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  removeWarehouseLoading: false,
  removeWarehouseError: null,
  removeWarehouseSuccess: true,
});

const removeWarehouseFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  removeWarehouseLoading: false,
  removeWarehouseError: action.removeWarehouseError,
});

const setCompanySettingsStart = (
  state: CompanyStateType,
): CompanyStateType => ({
  ...state,
  setCompanySettingsLoading: true,
});

const setCompanySettingsSuccess = (
  state: CompanyStateType,
): CompanyStateType => ({
  ...state,
  setCompanySettingsLoading: false,
  setCompanySettingsError: null,
  setCompanySettingsSuccess: true,
});

const setCompanySettingsFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  setCompanySettingsLoading: false,
  setCompanySettingsError: action.setCompanySettingsError,
});

const setSelectedCompanySettings = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  selectedCompany: Number(
    action.settings.find((setting) => setting.type === UserSettingType.COMPANY)
      ?.value,
  ),
});

const resetCompanyWarehouseStore = (
  state: CompanyStateType,
): CompanyStateType => {
  return {
    ...state,
    assignWarehouseSuccess: false,
    removeWarehouseSuccess: false,
  };
};

const resetCompanyStore = (state: CompanyStateType): CompanyStateType => {
  return {
    ...initialState,
    companyOptions: state.companyOptions,
    selectedCompany: state.selectedCompany,
  };
};

const resetWholeCompanyStore = (): CompanyStateType => {
  return {
    ...initialState,
    selectedCompany: null,
    companyLogo: null,
  };
};

const logout = (): CompanyStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: CompanyActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_COMPANIES_START:
      return fetchCompaniesStart(state);
    case actionTypes.FETCH_COMPANIES_SUCCESS:
      return fetchCompaniesSuccess(state, action);
    case actionTypes.FETCH_COMPANIES_FAIL:
      return fetchCompaniesFail(state, action);
    case actionTypes.FETCH_COMPANY_OPTIONS_START:
      return fetchCompanyOptionsStart(state);
    case actionTypes.FETCH_COMPANY_OPTIONS_SUCCESS:
      return fetchCompanyOptionsSuccess(state, action);
    case actionTypes.FETCH_COMPANY_OPTIONS_FAIL:
      return fetchCompanyOptionsFail(state, action);
    case actionTypes.FETCH_COMPANY_START:
      return fetchCompanyStart(state);
    case actionTypes.FETCH_COMPANY_SUCCESS:
      return fetchCompanySuccess(state, action);
    case actionTypes.FETCH_COMPANY_FAIL:
      return fetchCompanyFail(state, action);
    case actionTypes.CREATE_COMPANY_START:
      return createCompanyStart(state);
    case actionTypes.CREATE_COMPANY_SUCCESS:
      return createCompanySuccess(state, action);
    case actionTypes.CREATE_COMPANY_FAIL:
      return createCompanyFail(state, action);
    case actionTypes.UPDATE_COMPANY_START:
      return updateCompanyStart(state);
    case actionTypes.UPDATE_COMPANY_SUCCESS:
      return updateCompanySuccess(state);
    case actionTypes.UPDATE_COMPANY_FAIL:
      return updateCompanyFail(state, action);
    case actionTypes.SET_SELECTED_COMPANY:
      return setSelectedCompany(state, action);
    case actionTypes.TOGGLE_COMPANY_STATUS_START:
      return toggleCompanyStatusStart(state);
    case actionTypes.TOGGLE_COMPANY_STATUS_SUCCESS:
      return toggleCompanyStatusSuccess(state);
    case actionTypes.TOGGLE_COMPANY_STATUS_FAIL:
      return toggleCompanyStatusFail(state, action);
    case actionTypes.DELETE_COMPANY_START:
      return deleteCompanyStart(state);
    case actionTypes.DELETE_COMPANY_SUCCESS:
      return deleteCompanySuccess(state);
    case actionTypes.DELETE_COMPANY_FAIL:
      return deleteCompanyFail(state, action);
    case actionTypes.RESET_COMPANY_STORE:
      return resetCompanyStore(state);
    case actionTypes.UPDATE_COMPANY_BALANCE_START:
      return updateCompanyBalanceStart(state);
    case actionTypes.UPDATE_COMPANY_BALANCE_SUCCESS:
      return updateCompanyBalanceSuccess(state);
    case actionTypes.UPDATE_COMPANY_BALANCE_FAIL:
      return updateCompanyBalanceFail(state, action);
    case actionTypes.ASSIGN_WAREHOUSE_START:
      return assignWarehouseStart(state);
    case actionTypes.ASSIGN_WAREHOUSE_SUCCESS:
      return assignWarehouseSuccess(state);
    case actionTypes.ASSIGN_WAREHOUSE_FAIL:
      return assignWarehouseFail(state, action);
    case actionTypes.REMOVE_WAREHOUSE_START:
      return removeWarehouseStart(state);
    case actionTypes.REMOVE_WAREHOUSE_SUCCESS:
      return removeWarehouseSuccess(state);
    case actionTypes.REMOVE_WAREHOUSE_FAIL:
      return removeWarehouseFail(state, action);
    case actionTypes.SET_COMPANY_SETTINGS_START:
      return setCompanySettingsStart(state);
    case actionTypes.SET_COMPANY_SETTINGS_SUCCESS:
      return setCompanySettingsSuccess(state);
    case actionTypes.SET_COMPANY_SETTINGS_FAIL:
      return setCompanySettingsFail(state, action);
    case actionTypes.SET_COMPANY_SELECTED_SETTINGS:
      return setSelectedCompanySettings(state, action);
    case actionTypes.REGISTER_COMPANY_START:
      return registerCompanyStart(state);
    case actionTypes.REGISTER_COMPANY_SUCCESS:
      return registerCompanySuccess(state);
    case actionTypes.REGISTER_COMPANY_FAIL:
      return registerCompanyFail(state, action);
    case actionTypes.RESET_COMPANY_WAREHOUSE_STORE:
      return resetCompanyWarehouseStore(state);
    case actionTypes.RESET_WHOLE_COMPANY_STORE:
      return resetWholeCompanyStore();
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;
