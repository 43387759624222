import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../../common/Loader/Loader';
import { StoreState } from '../StoreProvider/StoreProvider';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as authService from '../../store/auth/service';
import * as userService from '../../store/user/service';
import { routes } from './routes';
import Layout from '../../common/Layout/Layout';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { JwtToken } from '../Axios/axios-instance';
import { logout, selectLocale } from '../../store/auth/actions';
import * as languageService from '../../store/language/service';
import { Language } from '../../domain/Language';
import { DEFAULT_LOCALE } from '../constants';
import { Locale } from '../../domain/Translation';
import { IntlProvider } from 'react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../common/ErrorFallback/ErrorFallback';
import { Roles } from '../../domain/Role';
import { User } from '../../domain/User';

const LoginPage = React.lazy(
  () => import('../../pages/Public/LoginPage/LoginPage'),
);

const RegistrationPage = React.lazy(
  () => import('../../pages/Public/RegistrationPage/RegistrationPage'),
);

const RegistrationConfirmationPage = React.lazy(
  () =>
    import(
      '../../pages/Public/RegistrationConfirmationPage/RegistrationConfirmationPage'
    ),
);

const PasswordRemindPage = React.lazy(
  () => import('../../pages/Public/PasswordRemindPage/PasswordRemindPage'),
);

const PasswordResetPage = React.lazy(
  () => import('../../pages/Public/PasswordResetPage/PasswordResetPage'),
);

const UsersListPage = React.lazy(
  () => import('../../pages/Admin/User/AdminListPage/AdminListPage'),
);

const UserCreatePage = React.lazy(
  () => import('../../pages/Admin/User/AdminCreatePage/AdminCreatePage'),
);

const UserEditPage = React.lazy(
  () => import('../../pages/Admin/User/AdminEditPage/AdminEditPage'),
);

const TranslationsPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Translation/TranslationListPage/TranslationListPage'
    ),
);

const Companies = React.lazy(
  () => import('../../pages/Admin/Company/Companies/Companies'),
);

const CompanyCreate = React.lazy(
  () => import('../../pages/Admin/Company/CompanyCreate/CompanyCreate'),
);

const CompanyEdit = React.lazy(
  () => import('../../pages/Admin/Company/CompanyEdit/CompanyEdit'),
);

const Projects = React.lazy(
  () => import('../../pages/Admin/Project/Projects/Projects'),
);

const ProjectCreate = React.lazy(
  () => import('../../pages/Admin/Project/ProjectCreate/ProjectCreate'),
);

const ProjectEdit = React.lazy(
  () => import('../../pages/Admin/Project/ProjectEdit/ProjectEdit'),
);

const Products = React.lazy(
  () => import('../../pages/Admin/Product/Products/Products'),
);

const ProductCreate = React.lazy(
  () => import('../../pages/Admin/Product/ProductCreate/ProductCreate'),
);

const ProductEdit = React.lazy(
  () => import('../../pages/Admin/Product/ProductEdit/ProductEdit'),
);

const Orders = React.lazy(
  () => import('../../pages/Admin/Order/Orders/Orders'),
);

const OrderCreate = React.lazy(
  () => import('../../pages/Admin/Order/OrderCreate/OrderCreate'),
);

const OrderEdit = React.lazy(
  () => import('../../pages/Admin/Order/OrderEdit/OrderEdit'),
);

const ActiveBillOfLadings = React.lazy(
  () => import('../../pages/Admin/BillOfLading/BillOfLadings/BillOfLadings'),
);

const BillOfLadingArrivedDetails = React.lazy(
  () =>
    import(
      '../../pages/Admin/BillOfLading/BillOfLadingArrivedDetails/BillOfLadingArrivedDetails'
    ),
);

const BillOfLadingCreate = React.lazy(
  () =>
    import(
      '../../pages/Admin/BillOfLading/BillOfLadingCreate/BillOfLadingCreate'
    ),
);

const Warehouses = React.lazy(
  () => import('../../pages/Admin/Warehouse/Warehouses/Warehouses'),
);

const WarehouseCreate = React.lazy(
  () => import('../../pages/Admin/Warehouse/WarehouseCreate/WarehouseCreate'),
);

const WarehouseEdit = React.lazy(
  () => import('../../pages/Admin/Warehouse/WarehouseEdit/WarehouseEdit'),
);

const DynamicPage = React.lazy(
  () => import('../../pages/Public/DynamicPage/DynamicPage'),
);

const PaymentResponsePage = React.lazy(
  () =>
    import('../../pages/Admin/Payment/PaymentResponsePage/PaymentResponsePage'),
);

const Couriers = React.lazy(
  () => import('../../pages/Admin/Courier/Couriers/Couriers'),
);

const Payments = React.lazy(
  () => import('../../pages/Admin/Payment/Payments/Payments'),
);

const BalanceHistories = React.lazy(
  () =>
    import(
      '../../pages/Admin/BalanceHistory/BalanceHistories/BalanceHistories'
    ),
);

const MyCompany = React.lazy(
  () => import('../../pages/Admin/Company/MyCompany/MyCompany'),
);

const StockmansPage = React.lazy(
  () => import('../../pages/Admin/User/StockmanListPage/StockmanListPage'),
);

const OrderFulfillments = React.lazy(
  () =>
    import(
      '../../pages/Admin/OrderFulfillment/OrderFulfillments/OrderFulfillments'
    ),
);

const OrderFulfillmentDetails = React.lazy(
  () =>
    import(
      '../../pages/Admin/OrderFulfillment/OrderFulfillmentDetails/OrderFulfillmentDetails'
    ),
);

const Refunds = React.lazy(
  () => import('../../pages/Admin/BillOfLading/Refunds/Refunds'),
);

const RefundDetails = React.lazy(
  () =>
    import(
      '../../pages/Admin/BillOfLading/BillOfLadingRefundDetails/BillOfLadingRefundDetails'
    ),
);

const ReturnedItems = React.lazy(
  () => import('../../pages/Admin/RefundItem/RefundItems/RefundItems'),
);

const CompanyRegister = React.lazy(
  () => import('../../pages/Public/CompanyRegisterPage/CompanyRegisterPage'),
);

export type Props = {
  isInitCompleted: boolean;
  isAuthenticated: boolean;
  onTryAutoSignup: () => void;
  isCurrentUserLoading: boolean;
  refreshTokenLoading: boolean;
  onFetchCurrentUser: () => void;
  onRefreshToken: () => void;
  jwtToken: string | null;
  lastActionAt: moment.Moment | null;
  onLogout: () => void;
  onLanguageFetch: (locale: string) => void;
  language: Language | null;
  onLanguagesInit: () => void;
  onSelectLocale: (locale: Locale) => void;
  selectedLocale: Locale;
  languages: Language[] | null;
  userRoles: Roles[];
  selectedProject: number | null;
  selectedCompany: number | null;
  selectedWarehouse: number | null;
  currentUser: User | null;
};

const ALLOWED_LOCALES = ['en', 'lt'];

export const Router = ({
  isInitCompleted,
  isAuthenticated,
  onTryAutoSignup,
  isCurrentUserLoading,
  onFetchCurrentUser,
  refreshTokenLoading,
  onRefreshToken,
  jwtToken,
  lastActionAt,
  onLogout,
  onLanguageFetch,
  language,
  onLanguagesInit,
  selectedLocale,
  onSelectLocale,
  languages,
  userRoles,
  selectedProject,
  selectedCompany,
  selectedWarehouse,
  currentUser,
}: Props) => {
  const locale =
    window.location?.pathname?.substring(1)?.split('/')?.[0] ?? DEFAULT_LOCALE;
  const parsedLocale = (
    locale && ALLOWED_LOCALES.includes(locale) ? locale : DEFAULT_LOCALE
  ) as Locale;

  useEffect(() => {
    if (!jwtToken) {
      return;
    }

    const decodedJson: JwtToken = jwtDecode(jwtToken);

    if (!decodedJson) {
      return;
    }

    const difference = moment.duration(
      moment(decodedJson.exp * 1000).diff(moment()),
    );
    const differenceLastAction = moment.duration(moment().diff(lastActionAt));

    if (
      difference.asMinutes() < 5 &&
      differenceLastAction.asMinutes() < 5 &&
      !refreshTokenLoading
    ) {
      onRefreshToken();
    }

    const timeout = setTimeout(() => {
      onLogout();
    }, difference.asMilliseconds());

    return () => clearTimeout(timeout);
  }, [jwtToken, lastActionAt]);

  useEffect(() => {
    onTryAutoSignup();
  }, []);

  useEffect(() => {
    onFetchCurrentUser();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      onFetchCurrentUser();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!parsedLocale) {
      onLanguageFetch(parsedLocale ?? DEFAULT_LOCALE);
      return;
    }

    moment.locale(parsedLocale === 'en' ? 'en-gb' : parsedLocale);
    onLanguageFetch(parsedLocale);
    onLanguagesInit();
    onSelectLocale(parsedLocale);
  }, [parsedLocale]);

  const getCurrentLanguage = () => {
    const foundLanguage = languages?.find(
      (singleLanguage) => singleLanguage.locale === selectedLocale,
    );

    return foundLanguage ?? language;
  };

  const mappedTranslations = getCurrentLanguage()?.translations.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.alias]: item.value ? item.value : item.defaultValue,
      }),
    {},
  );

  const getRoutes = () => {
    if (!isAuthenticated) {
      return (
        <>
          <Route
            path={routes.admin}
            element={<Navigate replace to={routes.login} />}
          />
          <Route path={routes.login} element={<LoginPage />} />
          <Route path={routes.remind} element={<PasswordRemindPage />} />
          <Route path={routes.resetPassword} element={<PasswordResetPage />} />
          <Route path={routes.register} element={<RegistrationPage />} />
          <Route path={routes.companyRegister} element={<CompanyRegister />} />
          <Route
            path={routes.registrationConfirmation}
            element={<RegistrationConfirmationPage />}
          />
          <Route path="*" element={<Navigate to={routes.login} />} />
        </>
      );
    }

    if (userRoles.includes(Roles.ADMIN)) {
      return (
        <>
          <Route
            path={routes.admin}
            element={
              <Navigate
                replace
                to={selectedProject ? routes.products.list : routes.admins.list}
              />
            }
          />
          <Route path={routes.companyRegister} element={<CompanyRegister />} />
          <Route path={routes.admins.create} element={<UserCreatePage />} />
          <Route path={routes.admins.edit} element={<UserEditPage />} />
          <Route path={routes.admins.list} element={<UsersListPage />} />
          <Route path={routes.translations} element={<TranslationsPage />} />
          <Route path={routes.companies.list} element={<Companies />} />
          <Route path={routes.companies.create} element={<CompanyCreate />} />
          <Route path={routes.companies.edit} element={<CompanyEdit />} />
          <Route path={routes.couriers.list} element={<Couriers />} />
          <Route
            path={routes.warehouses.companyList}
            element={<Warehouses />}
          />
          <Route path={routes.warehouses.list} element={<Warehouses />} />
          <Route
            path={routes.warehouses.create}
            element={<WarehouseCreate />}
          />
          <Route path={routes.warehouses.edit} element={<WarehouseEdit />} />
          <Route path={routes.projects.create} element={<ProjectCreate />} />
          <Route path={routes.projects.edit} element={<ProjectEdit />} />
          <Route path={routes.payments.list} element={<Payments />} />
          <Route
            path={routes.billOfLadings.details}
            element={<BillOfLadingArrivedDetails />}
          />
          {selectedCompany && (
            <>
              <Route
                path={routes.balanceHistory}
                element={<BalanceHistories />}
              />
            </>
          )}

          {selectedProject && (
            <>
              <Route path={routes.products.list} element={<Products />} />
              <Route
                path={routes.products.create}
                element={<ProductCreate />}
              />
              <Route path={routes.products.edit} element={<ProductEdit />} />
              <Route path={routes.orders.list} element={<Orders />} />
              <Route path={routes.orders.create} element={<OrderCreate />} />
              <Route path={routes.orders.edit} element={<OrderEdit />} />
              <Route
                path={routes.billOfLadings.list}
                element={<ActiveBillOfLadings />}
              />
              <Route
                path={routes.billOfLadings.create}
                element={<BillOfLadingCreate />}
              />
              <Route
                path={routes.orderFulfillments.details}
                element={<OrderFulfillmentDetails />}
              />
              <Route
                path={routes.billOfLadings.returnedItems}
                element={<ReturnedItems />}
              />
            </>
          )}

          <Route path={routes.dynamicPage} element={<DynamicPage />} />
          <Route path="*" element={<Navigate to={routes.admin} />} />
        </>
      );
    }

    if (userRoles.includes(Roles.OWNER) && currentUser?.companies.length) {
      return (
        <>
          <Route
            path="*"
            element={
              <Navigate
                to={selectedProject ? routes.products.list : routes.admin}
              />
            }
          />
          <Route path={routes.admin} element={<></>} />
          <Route
            path={routes.payments.response}
            element={<PaymentResponsePage />}
          />

          {selectedCompany && (
            <>
              <Route
                path={routes.warehouses.companyList}
                element={<Warehouses />}
              />
              <Route
                path={routes.warehouses.create}
                element={<WarehouseCreate />}
              />
              <Route
                path={routes.warehouses.edit}
                element={<WarehouseEdit />}
              />
              <Route path={routes.projects.list} element={<Projects />} />
              <Route
                path={routes.projects.ownerCreate}
                element={<ProjectCreate />}
              />
              <Route
                path={routes.projects.ownerEdit}
                element={<ProjectEdit />}
              />
              <Route
                path={routes.balanceHistory}
                element={<BalanceHistories />}
              />
              <Route path={routes.companies.my} element={<MyCompany />} />
            </>
          )}

          {selectedProject && (
            <>
              <Route path={routes.products.list} element={<Products />} />
              <Route
                path={routes.products.create}
                element={<ProductCreate />}
              />
              <Route path={routes.products.edit} element={<ProductEdit />} />
              <Route path={routes.orders.list} element={<Orders />} />
              <Route path={routes.orders.create} element={<OrderCreate />} />
              <Route path={routes.orders.edit} element={<OrderEdit />} />
              <Route
                path={routes.billOfLadings.list}
                element={<ActiveBillOfLadings />}
              />
              <Route
                path={routes.billOfLadings.details}
                element={<BillOfLadingArrivedDetails />}
              />
              <Route
                path={routes.billOfLadings.create}
                element={<BillOfLadingCreate />}
              />
              <Route
                path={routes.orderFulfillments.details}
                element={<OrderFulfillmentDetails />}
              />
              <Route
                path={routes.billOfLadings.returnedItems}
                element={<ReturnedItems />}
              />
            </>
          )}
        </>
      );
    }

    if (userRoles.includes(Roles.STOCKMAN) && currentUser?.warehouseId) {
      return (
        <>
          <Route
            path="*"
            element={<Navigate to={routes.billOfLadings.list} />}
          />

          <Route
            path={routes.billOfLadings.list}
            element={<ActiveBillOfLadings />}
          />
          <Route path={routes.billOfLadings.refunds} element={<Refunds />} />
          <Route
            path={routes.billOfLadings.refund}
            element={<RefundDetails />}
          />
          <Route
            path={routes.billOfLadings.details}
            element={<BillOfLadingArrivedDetails />}
          />
          <Route
            path={routes.orderFulfillments.list}
            element={<OrderFulfillments />}
          />
          <Route
            path={routes.orderFulfillments.details}
            element={<OrderFulfillmentDetails />}
          />
        </>
      );
    }

    if (userRoles.includes(Roles.WAREHOUSE_OWNER) && selectedWarehouse) {
      return (
        <>
          <Route
            path="*"
            element={<Navigate to={routes.billOfLadings.list} />}
          />

          <Route
            path={routes.billOfLadings.list}
            element={<ActiveBillOfLadings />}
          />
          <Route path={routes.billOfLadings.refunds} element={<Refunds />} />
          <Route
            path={routes.billOfLadings.refund}
            element={<RefundDetails />}
          />
          <Route
            path={routes.billOfLadings.details}
            element={<BillOfLadingArrivedDetails />}
          />
          <Route
            path={routes.orderFulfillments.list}
            element={<OrderFulfillments />}
          />
          <Route
            path={routes.orderFulfillments.details}
            element={<OrderFulfillmentDetails />}
          />
          <Route path={routes.stockmans} element={<StockmansPage />} />
          <Route path={routes.warehouses.my} element={<WarehouseEdit />} />
        </>
      );
    }

    if (!userRoles.includes(Roles.ADMIN)) {
      return (
        <>
          <Route path={routes.admin} element={<></>} />
          <Route path="*" element={<Navigate to={routes.admin} />} />
        </>
      );
    }
  };

  return (
    <BrowserRouter basename="/">
      {isInitCompleted && !isCurrentUserLoading && language ? (
        <IntlProvider
          messages={mappedTranslations}
          locale={language?.locale ?? DEFAULT_LOCALE}
          defaultLocale="en"
        >
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              window.location.reload();
            }}
          >
            <Suspense fallback={<Loader isLoading isFullScreen />}>
              <Layout isAuthenticated={isAuthenticated}>
                <Routes>{getRoutes()}</Routes>
              </Layout>
            </Suspense>
          </ErrorBoundary>
        </IntlProvider>
      ) : (
        <Loader isLoading isFullScreen />
      )}
    </BrowserRouter>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isInitCompleted: state.auth.isInitCompleted,
  isAuthenticated: state.auth.isAuthenticated,
  isCurrentUserLoading: state.user.currentUserLoading,
  refreshTokenLoading: state.auth.refreshTokenLoading,
  jwtToken: state.auth.jwtToken,
  lastActionAt: state.auth.lastStoreActionAt,
  language: state.language.language,
  selectedLocale: state.auth.selectedLocale,
  languages: state.language.languages,
  userRoles: state.auth.userRoles,
  selectedProject: state.project.selectedProject,
  selectedCompany: state.company.selectedCompany,
  selectedWarehouse: state.warehouse.selectedWarehouse,
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onTryAutoSignup: () => dispatch(authService.authCheckState()),
  onFetchCurrentUser: () => dispatch(userService.fetchCurrentUser()),
  onRefreshToken: () => dispatch(authService.refreshToken()),
  onLanguageFetch: (locale: string) =>
    dispatch(languageService.fetchLanguage(locale)),
  onLanguagesInit: () => dispatch(languageService.fetchLanguages()),
  onLogout: () => dispatch(logout()),
  onSelectLocale: (locale: Locale) => dispatch(selectLocale(locale)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
