import * as actionTypes from './actionTypes';
import { ProductActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Product } from '../../domain/Product';
import { ListResults } from '../../common/List/List';

export type ProductStateType = {
  productsList: ListResults<Product> | null;
  productsLoading: boolean;
  productsError: HttpError;
  productsListUpdateNeeded: boolean;
  productCreateLoading: boolean;
  productCreateError: HttpError;
  productCreateSuccess: boolean;
  createdProduct: Product | null;
  productUpdateLoading: boolean;
  productUpdateError: HttpError;
  productUpdateSuccess: boolean;
  product: Product | null;
  productLoading: boolean;
  productError: HttpError;
  productDeleteLoading: boolean;
  productDeleteError: HttpError;
};

export type ProductActionType = ProductStateType & {
  type: ProductActionTypes;
};

export const initialState: ProductStateType = {
  productsList: null,
  productsLoading: true,
  productsError: null,
  productsListUpdateNeeded: false,
  productCreateLoading: false,
  productCreateError: null,
  productCreateSuccess: false,
  createdProduct: null,
  productUpdateLoading: false,
  productUpdateError: null,
  productUpdateSuccess: false,
  product: null,
  productLoading: false,
  productError: null,
  productDeleteLoading: false,
  productDeleteError: null,
};

const fetchProductsStart = (state: ProductStateType): ProductStateType => ({
  ...state,
  productsLoading: true,
});

const fetchProductsSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  productsList: action.productsList,
  productsLoading: false,
  productsError: null,
  productsListUpdateNeeded: false,
});

const fetchProductsFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  productsError: action.productsError,
  productsLoading: false,
});

const fetchProductStart = (state: ProductStateType): ProductStateType => ({
  ...state,
  productLoading: true,
  productUpdateSuccess: false,
  productCreateSuccess: false,
  productCreateError: null,
  productUpdateError: null,
});

const fetchProductSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  product: action.product,
  productLoading: false,
  productError: null,
});

const fetchProductFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  productError: action.productError,
  productLoading: false,
});

const createProductStart = (state: ProductStateType): ProductStateType => ({
  ...state,
  productCreateLoading: true,
});

const createProductSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  productCreateLoading: false,
  productCreateError: null,
  productCreateSuccess: true,
  createdProduct: action.createdProduct,
  productsListUpdateNeeded: true,
});

const createProductFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  productCreateLoading: false,
  productCreateError: action.productCreateError,
});

const updateProductStart = (state: ProductStateType): ProductStateType => ({
  ...state,
  productUpdateLoading: true,
});

const updateProductSuccess = (state: ProductStateType): ProductStateType => ({
  ...state,
  productUpdateLoading: false,
  productUpdateError: null,
  productUpdateSuccess: true,
  productsListUpdateNeeded: true,
});

const updateProductFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  productUpdateLoading: false,
  productUpdateError: action.productUpdateError,
});

const deleteProductStart = (state: ProductStateType): ProductStateType => ({
  ...state,
  productDeleteLoading: true,
});

const deleteProductSuccess = (state: ProductStateType): ProductStateType => ({
  ...state,
  productDeleteLoading: false,
  productDeleteError: null,
  productsListUpdateNeeded: true,
});

const deleteProductFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  productDeleteLoading: false,
  productDeleteError: action.productDeleteError,
});

const resetProductStore = (): ProductStateType => ({
  ...initialState,
});

const logout = (): ProductStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: ProductActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_PRODUCTS_START:
      return fetchProductsStart(state);
    case actionTypes.FETCH_PRODUCTS_SUCCESS:
      return fetchProductsSuccess(state, action);
    case actionTypes.FETCH_PRODUCTS_FAIL:
      return fetchProductsFail(state, action);
    case actionTypes.FETCH_PRODUCT_START:
      return fetchProductStart(state);
    case actionTypes.FETCH_PRODUCT_SUCCESS:
      return fetchProductSuccess(state, action);
    case actionTypes.FETCH_PRODUCT_FAIL:
      return fetchProductFail(state, action);
    case actionTypes.CREATE_PRODUCT_START:
      return createProductStart(state);
    case actionTypes.CREATE_PRODUCT_SUCCESS:
      return createProductSuccess(state, action);
    case actionTypes.CREATE_PRODUCT_FAIL:
      return createProductFail(state, action);
    case actionTypes.UPDATE_PRODUCT_START:
      return updateProductStart(state);
    case actionTypes.UPDATE_PRODUCT_SUCCESS:
      return updateProductSuccess(state);
    case actionTypes.UPDATE_PRODUCT_FAIL:
      return updateProductFail(state, action);
    case actionTypes.DELETE_PRODUCT_START:
      return deleteProductStart(state);
    case actionTypes.DELETE_PRODUCT_SUCCESS:
      return deleteProductSuccess(state);
    case actionTypes.DELETE_PRODUCT_FAIL:
      return deleteProductFail(state, action);
    case actionTypes.RESET_PRODUCT_STORE:
      return resetProductStore();
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;
