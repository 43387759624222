import * as actionTypes from './actionTypes';
import { BillOfLadingActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { BillOfLading } from '../../domain/BillOfLading';

export type BillOfLadingStateType = {
  billOfLadingsList: ListResults<BillOfLading> | null;
  billOfLadingsLoading: boolean;
  billOfLadingsError: HttpError;
  billOfLading: BillOfLading | null;
  billOfLadingLoading: boolean;
  billOfLadingError: HttpError;
  billOfLadingsListUpdateNeeded: boolean;
  billOfLadingCreateLoading: boolean;
  billOfLadingCreateError: HttpError;
  billOfLadingCreateSuccess: boolean;
  completeBillOfLadingError: HttpError;
  completeBillOfLadingLoading: boolean;
  completeBillOfLadingSuccess: boolean;
};

export type BillOfLadingActionType = BillOfLadingStateType & {
  type: BillOfLadingActionTypes;
};

export const initialState: BillOfLadingStateType = {
  billOfLadingsList: null,
  billOfLadingsLoading: true,
  billOfLadingsError: null,
  billOfLadingsListUpdateNeeded: false,
  billOfLading: null,
  billOfLadingLoading: false,
  billOfLadingError: null,
  billOfLadingCreateLoading: false,
  billOfLadingCreateError: null,
  billOfLadingCreateSuccess: false,
  completeBillOfLadingError: null,
  completeBillOfLadingSuccess: false,
  completeBillOfLadingLoading: false,
};

const fetchBillOfLadingsStart = (
  state: BillOfLadingStateType,
): BillOfLadingStateType => ({
  ...state,
  billOfLadingsLoading: true,
  billOfLadingCreateSuccess: false,
  billOfLadingCreateError: null,
  billOfLadingCreateLoading: false,
});

const fetchBillOfLadingsSuccess = (
  state: BillOfLadingStateType,
  action: BillOfLadingActionType,
): BillOfLadingStateType => ({
  ...state,
  billOfLadingsList: action.billOfLadingsList,
  billOfLadingsLoading: false,
  billOfLadingsError: null,
  billOfLadingsListUpdateNeeded: false,
});

const fetchBillOfLadingsFail = (
  state: BillOfLadingStateType,
  action: BillOfLadingActionType,
): BillOfLadingStateType => ({
  ...state,
  billOfLadingsError: action.billOfLadingsError,
  billOfLadingsLoading: false,
});

const fetchBillOfLadingStart = (
  state: BillOfLadingStateType,
): BillOfLadingStateType => ({
  ...state,
  billOfLadingLoading: true,
});

const fetchBillOfLadingSuccess = (
  state: BillOfLadingStateType,
  action: BillOfLadingActionType,
): BillOfLadingStateType => ({
  ...state,
  billOfLading: action.billOfLading,
  billOfLadingLoading: false,
  billOfLadingError: null,
});

const fetchBillOfLadingFail = (
  state: BillOfLadingStateType,
  action: BillOfLadingActionType,
): BillOfLadingStateType => ({
  ...state,
  billOfLadingError: action.billOfLadingError,
  billOfLadingLoading: false,
});

const createBillOfLadingStart = (
  state: BillOfLadingStateType,
): BillOfLadingStateType => ({
  ...state,
  billOfLadingCreateLoading: true,
  billOfLadingCreateSuccess: false,
});

const createBillOfLadingSuccess = (
  state: BillOfLadingStateType,
  action: BillOfLadingActionType,
): BillOfLadingStateType => ({
  ...state,
  billOfLadingCreateLoading: false,
  billOfLadingCreateError: null,
  billOfLadingCreateSuccess: true,
  billOfLadingsListUpdateNeeded: true,
});

const createBillOfLadingFail = (
  state: BillOfLadingStateType,
  action: BillOfLadingActionType,
): BillOfLadingStateType => ({
  ...state,
  billOfLadingCreateLoading: false,
  billOfLadingCreateError: action.billOfLadingCreateError,
});

const completeBillOfLadingStart = (
  state: BillOfLadingStateType,
): BillOfLadingStateType => ({
  ...state,
  completeBillOfLadingLoading: true,
  completeBillOfLadingSuccess: false,
});

const completeBillOfLadingSuccess = (
  state: BillOfLadingStateType,
): BillOfLadingStateType => ({
  ...state,
  completeBillOfLadingLoading: false,
  completeBillOfLadingError: null,
  completeBillOfLadingSuccess: true,
});

const completeBillOfLadingFail = (
  state: BillOfLadingStateType,
  action: BillOfLadingActionType,
): BillOfLadingStateType => ({
  ...state,
  completeBillOfLadingLoading: false,
  completeBillOfLadingError: action.completeBillOfLadingError,
});

const resetBillOfLadingStore = (): BillOfLadingStateType => {
  return {
    ...initialState,
  };
};

const logout = (): BillOfLadingStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: BillOfLadingActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_BILL_OF_LADINGS_START:
      return fetchBillOfLadingsStart(state);
    case actionTypes.FETCH_BILL_OF_LADINGS_SUCCESS:
      return fetchBillOfLadingsSuccess(state, action);
    case actionTypes.FETCH_BILL_OF_LADINGS_FAIL:
      return fetchBillOfLadingsFail(state, action);
    case actionTypes.FETCH_BILL_OF_LADING_START:
      return fetchBillOfLadingStart(state);
    case actionTypes.FETCH_BILL_OF_LADING_SUCCESS:
      return fetchBillOfLadingSuccess(state, action);
    case actionTypes.FETCH_BILL_OF_LADING_FAIL:
      return fetchBillOfLadingFail(state, action);
    case actionTypes.CREATE_BILL_OF_LADING_START:
      return createBillOfLadingStart(state);
    case actionTypes.CREATE_BILL_OF_LADING_SUCCESS:
      return createBillOfLadingSuccess(state, action);
    case actionTypes.CREATE_BILL_OF_LADING_FAIL:
      return createBillOfLadingFail(state, action);
    case actionTypes.COMPLETE_BILL_OF_LADING_START:
      return completeBillOfLadingStart(state);
    case actionTypes.COMPLETE_BILL_OF_LADING_SUCCESS:
      return completeBillOfLadingSuccess(state);
    case actionTypes.COMPLETE_BILL_OF_LADING_FAIL:
      return completeBillOfLadingFail(state, action);
    case actionTypes.RESET_BILL_OF_LADING_STORE:
      return resetBillOfLadingStore();
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;
