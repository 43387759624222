import * as actionTypes from './actionTypes';
import { OrderActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Order } from '../../domain/Order';
import { ListResults } from '../../common/List/List';
import { Warehouse } from '../../domain/Warehouse';

export type OrderStateType = {
  ordersList: ListResults<Order> | null;
  ordersLoading: boolean;
  ordersError: HttpError;
  ordersListUpdateNeeded: boolean;
  orderCreateLoading: boolean;
  orderCreateError: HttpError;
  orderCreateSuccess: boolean;
  createdOrder: Order | null;
  order: Order | null;
  orderLoading: boolean;
  orderError: HttpError;
  orderUpdateLoading: boolean;
  orderUpdateError: HttpError;
  orderUpdateSuccess: boolean;
  fulfillmentWarehouses: Warehouse[] | null;
  fulfillmentWarehousesError: HttpError;
  fulfillmentWarehousesLoading: boolean;
  splitOrderLoading: boolean;
  splitOrderError: HttpError;
  splitOrderSuccess: boolean;
  completeOrderError: HttpError;
  completeOrderLoading: boolean;
};

export type OrderActionType = OrderStateType & {
  type: OrderActionTypes;
};

export const initialState: OrderStateType = {
  ordersList: null,
  ordersLoading: true,
  ordersError: null,
  ordersListUpdateNeeded: false,
  orderCreateLoading: false,
  orderCreateError: null,
  orderCreateSuccess: false,
  createdOrder: null,
  order: null,
  orderLoading: false,
  orderError: null,
  orderUpdateError: null,
  orderUpdateLoading: false,
  orderUpdateSuccess: false,
  fulfillmentWarehousesError: null,
  fulfillmentWarehouses: null,
  fulfillmentWarehousesLoading: false,
  splitOrderSuccess: false,
  splitOrderError: null,
  splitOrderLoading: false,
  completeOrderError: null,
  completeOrderLoading: false,
};

const fetchOrdersStart = (state: OrderStateType): OrderStateType => ({
  ...state,
  ordersLoading: true,
});

const fetchOrdersSuccess = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  ordersList: action.ordersList,
  ordersLoading: false,
  ordersError: null,
  ordersListUpdateNeeded: false,
  splitOrderSuccess: false,
  fulfillmentWarehouses: null,
});

const fetchOrdersFail = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  ordersError: action.ordersError,
  ordersLoading: false,
});

const fetchOrderStart = (state: OrderStateType): OrderStateType => ({
  ...state,
  orderLoading: true,
  orderCreateSuccess: false,
  orderCreateError: null,
});

const fetchOrderSuccess = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  order: action.order,
  orderLoading: false,
  orderError: null,
});

const fetchOrderFail = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  orderError: action.orderError,
  orderLoading: false,
});

const createOrderStart = (state: OrderStateType): OrderStateType => ({
  ...state,
  orderCreateLoading: true,
});

const createOrderSuccess = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  orderCreateLoading: false,
  orderCreateError: null,
  orderCreateSuccess: true,
  createdOrder: action.createdOrder,
  ordersListUpdateNeeded: true,
});

const createOrderFail = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  orderCreateLoading: false,
  orderCreateError: action.orderCreateError,
});

const updateOrderStart = (state: OrderStateType): OrderStateType => ({
  ...state,
  orderUpdateLoading: true,
});

const updateOrderSuccess = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  orderUpdateLoading: false,
  orderUpdateError: null,
  orderUpdateSuccess: true,
  ordersListUpdateNeeded: true,
});

const updateOrderFail = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  orderUpdateLoading: false,
  orderUpdateError: action.orderUpdateError,
});

const fetchFulfillmentWarehousesStart = (
  state: OrderStateType,
): OrderStateType => ({
  ...state,
  fulfillmentWarehousesLoading: true,
});

const fetchFulfillmentWarehousesSuccess = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  fulfillmentWarehousesLoading: false,
  fulfillmentWarehousesError: null,
  fulfillmentWarehouses: action.fulfillmentWarehouses,
});

const fetchFulfillmentWarehousesFail = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  fulfillmentWarehousesLoading: false,
  fulfillmentWarehousesError: action.fulfillmentWarehousesError,
});

const splitOrderStart = (state: OrderStateType): OrderStateType => ({
  ...state,
  splitOrderLoading: true,
});

const splitOrderSuccess = (state: OrderStateType): OrderStateType => ({
  ...state,
  splitOrderLoading: false,
  splitOrderError: null,
  ordersListUpdateNeeded: true,
  splitOrderSuccess: true,
});

const splitOrderFail = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  splitOrderLoading: false,
  splitOrderError: action.splitOrderError,
});

const completeOrderStart = (state: OrderStateType): OrderStateType => ({
  ...state,
  completeOrderLoading: true,
});

const completeOrderSuccess = (state: OrderStateType): OrderStateType => ({
  ...state,
  completeOrderLoading: false,
  completeOrderError: null,
  ordersListUpdateNeeded: true,
});

const completeOrderFail = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  completeOrderLoading: false,
  completeOrderError: action.completeOrderError,
});

const resetOrderStore = (): OrderStateType => ({
  ...initialState,
});

const logout = (): OrderStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: OrderActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ORDERS_START:
      return fetchOrdersStart(state);
    case actionTypes.FETCH_ORDERS_SUCCESS:
      return fetchOrdersSuccess(state, action);
    case actionTypes.FETCH_ORDERS_FAIL:
      return fetchOrdersFail(state, action);
    case actionTypes.FETCH_ORDER_START:
      return fetchOrderStart(state);
    case actionTypes.FETCH_ORDER_SUCCESS:
      return fetchOrderSuccess(state, action);
    case actionTypes.FETCH_ORDER_FAIL:
      return fetchOrderFail(state, action);
    case actionTypes.CREATE_ORDER_START:
      return createOrderStart(state);
    case actionTypes.CREATE_ORDER_SUCCESS:
      return createOrderSuccess(state, action);
    case actionTypes.CREATE_ORDER_FAIL:
      return createOrderFail(state, action);
    case actionTypes.UPDATE_ORDER_START:
      return updateOrderStart(state);
    case actionTypes.UPDATE_ORDER_SUCCESS:
      return updateOrderSuccess(state, action);
    case actionTypes.UPDATE_ORDER_FAIL:
      return updateOrderFail(state, action);
    case actionTypes.FETCH_FULFILLMENT_WAREHOUSES_START:
      return fetchFulfillmentWarehousesStart(state);
    case actionTypes.FETCH_FULFILLMENT_WAREHOUSES_SUCCESS:
      return fetchFulfillmentWarehousesSuccess(state, action);
    case actionTypes.FETCH_FULFILLMENT_WAREHOUSES_FAIL:
      return fetchFulfillmentWarehousesFail(state, action);
    case actionTypes.SPLIT_ORDER_START:
      return splitOrderStart(state);
    case actionTypes.SPLIT_ORDER_SUCCESS:
      return splitOrderSuccess(state);
    case actionTypes.SPLIT_ORDER_FAIL:
      return splitOrderFail(state, action);
    case actionTypes.COMPLETE_ORDER_START:
      return completeOrderStart(state);
    case actionTypes.COMPLETE_ORDER_SUCCESS:
      return completeOrderSuccess(state);
    case actionTypes.COMPLETE_ORDER_FAIL:
      return completeOrderFail(state, action);
    case actionTypes.RESET_ORDER_STORE:
      return resetOrderStore();
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;
