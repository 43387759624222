export const CREATE_PROJECT_KEY_START = 'CREATE_PROJECT_KEY_START';
export const CREATE_PROJECT_KEY_SUCCESS = 'CREATE_PROJECT_KEY_SUCCESS';
export const CREATE_PROJECT_KEY_FAIL = 'CREATE_PROJECT_KEY_FAIL';

export const UPDATE_PROJECT_KEY_START = 'UPDATE_PROJECT_KEY_START';
export const UPDATE_PROJECT_KEY_SUCCESS = 'UPDATE_PROJECT_KEY_SUCCESS';
export const UPDATE_PROJECT_KEY_FAIL = 'UPDATE_PROJECT_KEY_FAIL';

export const GENERATE_PROJECT_KEY_KEY_START = 'GENERATE_PROJECT_KEY_KEY_START';
export const GENERATE_PROJECT_KEY_KEY_SUCCESS =
  'GENERATE_PROJECT_KEY_KEY_SUCCESS';
export const GENERATE_PROJECT_KEY_KEY_FAIL = 'GENERATE_PROJECT_KEY_KEY_FAIL';

export const DELETE_PROJECT_KEY_START = 'DELETE_PROJECT_KEY_START';
export const DELETE_PROJECT_KEY_SUCCESS = 'DELETE_PROJECT_KEY_SUCCESS';
export const DELETE_PROJECT_KEY_FAIL = 'DELETE_PROJECT_KEY_FAIL';

export const RESET_PROJECT_KEY_STORE = 'RESET_PROJECT_KEY_STORE';

export const LOGOUT = 'LOGOUT';

export type ProjectKeyActionTypes =
  | typeof CREATE_PROJECT_KEY_START
  | typeof CREATE_PROJECT_KEY_SUCCESS
  | typeof CREATE_PROJECT_KEY_FAIL
  | typeof UPDATE_PROJECT_KEY_START
  | typeof UPDATE_PROJECT_KEY_SUCCESS
  | typeof UPDATE_PROJECT_KEY_FAIL
  | typeof RESET_PROJECT_KEY_STORE
  | typeof DELETE_PROJECT_KEY_START
  | typeof DELETE_PROJECT_KEY_SUCCESS
  | typeof DELETE_PROJECT_KEY_FAIL
  | typeof GENERATE_PROJECT_KEY_KEY_START
  | typeof GENERATE_PROJECT_KEY_KEY_SUCCESS
  | typeof GENERATE_PROJECT_KEY_KEY_FAIL
  | typeof LOGOUT;
