export const routes = {
  homepage: '/admin',
  login: '/login',
  companyRegister: '/register-company',
  register: '/register/:code?',
  registrationConfirmation: '/registration-confirmation/:code',
  remind: '/remind-password',
  resetPassword: '/password-reset/:code',
  profile: '/profile',
  dynamicPage: '/:locale/:slug',
  admin: '/admin',
  stockmans: '/admin/stockmans',
  admins: {
    list: '/admin/admins',
    create: '/admin/admins/new',
    edit: '/admin/admins/:id',
  },
  companies: {
    list: '/admin/companies',
    edit: '/admin/companies/:id',
    create: '/admin/companies/create',
    my: '/admin/companies/my',
  },
  projects: {
    list: '/admin/projects',
    ownerCreate: '/admin/projects/create',
    ownerEdit: '/admin/projects/:id',
    edit: '/admin/company/:companyId/project/:id',
    create: '/admin/company/:companyId/project/create',
  },
  products: {
    list: '/admin/products',
    edit: '/admin/products/:id',
    create: '/admin/products/create',
  },
  orders: {
    list: '/admin/orders',
    create: '/admin/orders/create',
    edit: '/admin/orders/:id',
  },
  billOfLadings: {
    list: '/admin/bill-of-ladings',
    refunds: '/admin/refunds',
    returnedItems: '/admin/returned-items',
    refund: '/admin/refund/:id/details',
    details: '/admin/bill-of-lading/:id/details',
    create: '/admin/bill-of-ladings/create',
  },
  warehouses: {
    list: '/admin/warehouses',
    companyList: '/admin/company-warehouses',
    edit: '/admin/warehouse/:id',
    create: '/admin/warehouse/create',
    my: '/admin/warehouse',
  },
  couriers: {
    list: '/admin/couriers',
  },
  payments: {
    list: '/admin/payments',
    response: '/admin/payment',
  },
  orderFulfillments: {
    list: '/admin/order-fulfillments',
    details: '/admin/order-fulfillments/:id',
  },
  balanceHistory: '/admin/balance-histories',
  translations: '/admin/translations',
};
