import React from 'react';
import { Provider } from 'react-redux';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import authReducer, { AuthStateType } from '../../store/auth/reducer';
import userReducer, { UserStateType } from '../../store/user/reducer';
import companyReducer, { CompanyStateType } from '../../store/company/reducer';
import projectReducer, { ProjectStateType } from '../../store/project/reducer';
import projectKeyReducer, {
  ProjectKeyStateType,
} from '../../store/project-key/reducer';
import productReducer, { ProductStateType } from '../../store/product/reducer';
import productQuantityReducer, {
  ProductQuantityStateType,
} from '../../store/product-quantity/reducer';
import orderReducer, { OrderStateType } from '../../store/order/reducer';
import billOfLadingReducer, {
  BillOfLadingStateType,
} from '../../store/bill-of-lading/reducer';
import billOfLadingProductReducer, {
  BillOfLadingProductStateType,
} from '../../store/bill-of-lading-product/reducer';
import languageReducer, {
  LanguageStateType,
} from '../../store/language/reducer';
import translationReducer, {
  TranslationStateType,
} from '../../store/translation/reducer';
import warehouseReducer, {
  WarehouseStateType,
} from '../../store/warehouse/reducer';
import paymentReducer, { PaymentStateType } from '../../store/payment/reducer';
import courierReducer, { CourierStateType } from '../../store/courier/reducer';
import warehouseCostReducer, {
  WarehouseCostStateType,
} from '../../store/warehouse-cost/reducer';
import balanceHistoryReducer, {
  BalanceHistoryStateType,
} from '../../store/balance-history/reducer';
import orderFulfillmentReducer, {
  OrderFulfillmentStateType,
} from '../../store/order-fulfillment/reducer';
import orderFulfillmentItemReducer, {
  OrderFulfillmentItemStateType,
} from '../../store/order-fulfillment-item/reducer';
import userSettingReducer, {
  UserSettingStateType,
} from '../../store/user-setting/reducer';
import refundItemReducer, {
  RefundItemStateType,
} from '../../store/refund-item/reducer';
import instance from '../Axios/axios-instance';
import * as authService from '../../store/auth/actions';
import { resetUserStore } from '../../store/user/actions';
import { resetWholeCompanyStore } from '../../store/company/actions';
import { resetWholeProjectStore } from '../../store/project/actions';
import { resetWarehouseStore } from '../../store/warehouse/actions';

export type StoreState = {
  auth: AuthStateType;
  user: UserStateType;
  translation: TranslationStateType;
  language: LanguageStateType;
  company: CompanyStateType;
  project: ProjectStateType;
  product: ProductStateType;
  order: OrderStateType;
  billOfLading: BillOfLadingStateType;
  billOfLadingProduct: BillOfLadingProductStateType;
  warehouse: WarehouseStateType;
  projectKey: ProjectKeyStateType;
  payment: PaymentStateType;
  courier: CourierStateType;
  productQuantity: ProductQuantityStateType;
  balanceHistory: BalanceHistoryStateType;
  warehouseCost: WarehouseCostStateType;
  orderFulfillment: OrderFulfillmentStateType;
  orderFulfillmentItem: OrderFulfillmentItemStateType;
  userSetting: UserSettingStateType;
  refundItem: RefundItemStateType;
};

type Props = {
  children?: any;
};

const rootReducer = combineReducers<StoreState>({
  auth: authReducer,
  user: userReducer,
  translation: translationReducer,
  language: languageReducer,
  company: companyReducer,
  project: projectReducer,
  product: productReducer,
  order: orderReducer,
  billOfLading: billOfLadingReducer,
  billOfLadingProduct: billOfLadingProductReducer,
  warehouse: warehouseReducer,
  projectKey: projectKeyReducer,
  payment: paymentReducer,
  courier: courierReducer,
  productQuantity: productQuantityReducer,
  balanceHistory: balanceHistoryReducer,
  warehouseCost: warehouseCostReducer,
  orderFulfillment: orderFulfillmentReducer,
  orderFulfillmentItem: orderFulfillmentItemReducer,
  userSetting: userSettingReducer,
  refundItem: refundItemReducer,
});

export const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store: Store<any, any> = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

const { dispatch } = store;
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.config.url !== '/token'
    ) {
      localStorage.removeItem('companyLogo');
      localStorage.removeItem('token');
      localStorage.removeItem('selectedWarehouse');
      dispatch(resetUserStore());
      dispatch(resetWholeCompanyStore());
      dispatch(resetWholeProjectStore());
      dispatch(resetWarehouseStore());
      dispatch(authService.logout());
    }

    return Promise.reject(error);
  },
);

const StoreProvider = ({ children }: Props) => (
  <Provider store={store}>{children}</Provider>
);

export default StoreProvider;
