import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import {
  createCompanyStart,
  createCompanySuccess,
  createCompanyFail,
  fetchCompaniesStart,
  fetchCompaniesSuccess,
  fetchCompaniesFail,
  fetchCompanyStart,
  fetchCompanySuccess,
  updateCompanyStart,
  fetchCompanyFail,
  updateCompanySuccess,
  updateCompanyFail,
  fetchCompanyOptionsStart,
  fetchCompanyOptionsSuccess,
  fetchCompanyOptionsFail,
  toggleCompanyStatusStart,
  toggleCompanyStatusSuccess,
  toggleCompanyStatusFail,
  deleteCompanyStart,
  deleteCompanySuccess,
  deleteCompanyFail,
  updateCompanyBalanceStart,
  updateCompanyBalanceSuccess,
  updateCompanyBalanceFail,
  removeWarehouseFail,
  removeWarehouseSuccess,
  removeWarehouseStart,
  assignWarehouseFail,
  assignWarehouseSuccess,
  assignWarehouseStart,
  setCompanySettingsStart,
  setCompanySettingsSuccess,
  setCompanySettingsFail,
  registerCompanyStart,
  registerCompanySuccess,
  registerCompanyFail,
} from './actions';
import { showToast } from '../../utility/toast/toast';
import { IntlShape } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { getFormData } from '../../utility/form/formData';

const API_URL = '/companies';

export type CompanyCreateRequest = {
  name: string;
  ownerId: number;
  isActive: boolean;
  logo: File;
  code: string;
  vatCode: string;
  phoneNumber: string;
  address: string;
  postalCode: string;
  country: string;
};

export type CompanyUpdateRequest = {
  id: number;
  name: string;
  ownerId: number;
  isActive: boolean;
  logo: File;
  isOwner: boolean;
  code: string;
  vatCode: string;
  phoneNumber: string;
  address: string;
  postalCode: string;
  country: string;
};

export type CompanyRegisterRequest = {
  firstName: string;
  lastName: string;
  email: string;
  name: string;
  code: string;
  vatCode: string;
  phoneNumber: string;
  address: string;
  postalCode: string;
  country: string;
};

export type CompanyBalanceRequest = {
  amount: number;
  isSubtract: boolean;
};

export type SetCompanySettingsRequest = {
  balanceThreshold: number;
};

export const fetchCompanies =
  (params: ListParams, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(fetchCompaniesStart());
    return axios
      .get(API_URL, { params })
      .then((response) => {
        dispatch(fetchCompaniesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchCompaniesFail(err.response.data.message));
        showToast(translate(intl, err.response.data.message), 'error');
      });
  };

export const fetchCompanyOptions =
  (intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(fetchCompanyOptionsStart());
    return axios
      .get(`${API_URL}/options`)
      .then((response) => {
        dispatch(fetchCompanyOptionsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchCompanyOptionsFail(err.response.data.message));
        showToast(translate(intl, err.response.data.message), 'error');
      });
  };

export const fetchCompany =
  (companyId: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(fetchCompanyStart());
    return axios
      .get(`${API_URL}/${companyId}`)
      .then((response) => {
        dispatch(fetchCompanySuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchCompanyFail(err.response.data.message));
        showToast(translate(intl, err.response.data.message), 'error');
      });
  };

export const createCompany =
  (inputs: CompanyCreateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(createCompanyStart());
    return axios
      .post(API_URL, getFormData(inputs))
      .then((response) => {
        dispatch(createCompanySuccess(response.data));
        showToast(translate(intl, 'SUCCESS.COMPANY_CREATE'), 'success');
      })
      .catch((err) => {
        dispatch(createCompanyFail(err.response.data.message));
      });
  };

export const registerCompany =
  (inputs: CompanyRegisterRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(registerCompanyStart());
    return axios
      .post(`${API_URL}/register`, { ...inputs })
      .then(() => {
        dispatch(registerCompanySuccess());
        showToast(translate(intl, 'SUCCESS.COMPANY_REGISTER'), 'success');
      })
      .catch((err) => {
        dispatch(registerCompanyFail(err.response.data.message));
      });
  };

export const updateCompany =
  (inputs: CompanyUpdateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateCompanyStart());
    return axios
      .patch(`${API_URL}/${inputs.id}`, getFormData(inputs))
      .then(() => {
        dispatch(updateCompanySuccess());
        showToast(translate(intl, 'SUCCESS.COMPANY_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateCompanyFail(err.response.data.message));
      });
  };

export const toggleStatus = (id: number, intl: IntlShape) => {
  return (dispatch: Dispatch) => {
    dispatch(toggleCompanyStatusStart());
    return axios
      .patch(`${API_URL}/${id}/toggle-status`)
      .then(() => {
        dispatch(toggleCompanyStatusSuccess());
        showToast(translate(intl, 'SUCCESS.COMPANY_STATUS_UPDATED'), 'success');
      })
      .catch((err) => {
        dispatch(toggleCompanyStatusFail(err.response.data.message));
        showToast(translate(intl, err.response.data.message), 'error');
      });
  };
};

export const deleteCompany =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(deleteCompanyStart());
    return axios
      .delete(`${API_URL}/${id}`)
      .then(() => {
        dispatch(deleteCompanySuccess());
        showToast(translate(intl, 'SUCCESS.COMPANY_DELETE'), 'success');
      })
      .catch((err) => {
        dispatch(deleteCompanyFail(err.response.data.message));
      });
  };

export const updateCompanyBalance = (
  id: number,
  inputs: CompanyBalanceRequest,
  intl: IntlShape,
) => {
  return (dispatch: Dispatch) => {
    dispatch(updateCompanyBalanceStart());
    return axios
      .patch(`${API_URL}/${id}/balance-update`, { ...inputs })
      .then(() => {
        dispatch(updateCompanyBalanceSuccess());
        showToast(translate(intl, 'SUCCESS.COMPANY_BALANCE_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateCompanyBalanceFail(err.response.data.message));
        showToast(translate(intl, err.response.data.message), 'error');
      });
  };
};

export const assignWarehouse =
  (id: number, warehouseId: number, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(assignWarehouseStart());
    return axios
      .patch(`${API_URL}/${id}/assign-warehouse/${warehouseId}`)
      .then(() => {
        dispatch(assignWarehouseSuccess());
        showToast(translate(intl, 'SUCCESS.ASSIGN_WAREHOUSE'), 'success');
      })
      .catch((err) => {
        dispatch(assignWarehouseFail(err.response.data.message));
      });
  };

export const removeWarehouse =
  (id: number, warehouseId: number, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(removeWarehouseStart());
    return axios
      .patch(`${API_URL}/${id}/remove-warehouse/${warehouseId}`)
      .then(() => {
        dispatch(removeWarehouseSuccess());
        showToast(translate(intl, 'SUCCESS.REMOVE_WAREHOUSE'), 'success');
      })
      .catch((err) => {
        dispatch(removeWarehouseFail(err.response.data.message));
      });
  };

export const setCompanySettings =
  (id: number, inputs: SetCompanySettingsRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(setCompanySettingsStart());
    return axios
      .patch(`${API_URL}/${id}/set-settings`, { ...inputs })
      .then(() => {
        dispatch(setCompanySettingsSuccess());
        showToast(translate(intl, 'SUCCESS.COMPANY_SETTINGS'), 'success');
      })
      .catch((err) => {
        dispatch(setCompanySettingsFail(err.response.data.message));
      });
  };
