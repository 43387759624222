export const FETCH_PRODUCT_QUANTITIES_START = 'FETCH_PRODUCT_QUANTITIES_START';
export const FETCH_PRODUCT_QUANTITIES_SUCCESS =
  'FETCH_PRODUCT_QUANTITIES_SUCCESS';
export const FETCH_PRODUCT_QUANTITIES_FAIL = 'FETCH_PRODUCT_QUANTITIES_FAIL';

export const CREATE_PRODUCT_QUANTITY_START = 'CREATE_PRODUCT_QUANTITY_START';
export const CREATE_PRODUCT_QUANTITY_SUCCESS =
  'CREATE_PRODUCT_QUANTITY_SUCCESS';
export const CREATE_PRODUCT_QUANTITY_FAIL = 'CREATE_PRODUCT_QUANTITY_FAIL';

export const UPDATE_PRODUCT_QUANTITY_START = 'UPDATE_PRODUCT_QUANTITY_START';
export const UPDATE_PRODUCT_QUANTITY_SUCCESS =
  'UPDATE_PRODUCT_QUANTITY_SUCCESS';
export const UPDATE_PRODUCT_QUANTITY_FAIL = 'UPDATE_PRODUCT_QUANTITY_FAIL';

export const SET_PRODUCT_QUANTITY_START = 'SET_PRODUCT_QUANTITY_START';
export const SET_PRODUCT_QUANTITY_SUCCESS = 'SET_PRODUCT_QUANTITY_SUCCESS';
export const SET_PRODUCT_QUANTITY_FAIL = 'SET_PRODUCT_QUANTITY_FAIL';

export const DELETE_PRODUCT_QUANTITY_START = 'DELETE_PRODUCT_QUANTITY_START';
export const DELETE_PRODUCT_QUANTITY_SUCCESS =
  'DELETE_PRODUCT_QUANTITY_SUCCESS';
export const DELETE_PRODUCT_QUANTITY_FAIL = 'DELETE_PRODUCT_QUANTITY_FAIL';

export const RESET_PRODUCT_QUANTITY_STORE = 'RESET_PRODUCT_QUANTITY_STORE';

export const LOGOUT = 'LOGOUT';

export type ProductQuantityActionTypes =
  | typeof FETCH_PRODUCT_QUANTITIES_START
  | typeof FETCH_PRODUCT_QUANTITIES_SUCCESS
  | typeof FETCH_PRODUCT_QUANTITIES_FAIL
  | typeof CREATE_PRODUCT_QUANTITY_START
  | typeof CREATE_PRODUCT_QUANTITY_SUCCESS
  | typeof CREATE_PRODUCT_QUANTITY_FAIL
  | typeof UPDATE_PRODUCT_QUANTITY_START
  | typeof UPDATE_PRODUCT_QUANTITY_SUCCESS
  | typeof UPDATE_PRODUCT_QUANTITY_FAIL
  | typeof RESET_PRODUCT_QUANTITY_STORE
  | typeof DELETE_PRODUCT_QUANTITY_START
  | typeof DELETE_PRODUCT_QUANTITY_SUCCESS
  | typeof DELETE_PRODUCT_QUANTITY_FAIL
  | typeof SET_PRODUCT_QUANTITY_START
  | typeof SET_PRODUCT_QUANTITY_SUCCESS
  | typeof SET_PRODUCT_QUANTITY_FAIL
  | typeof LOGOUT;
