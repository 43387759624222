import * as actionTypes from './actionTypes';
import { WarehouseActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Warehouse } from '../../domain/Warehouse';
import { ListResults } from '../../common/List/List';
import { ProductQuantity } from '../../domain/ProductQuantity';
import { UserSetting, UserSettingType } from '../../domain/UserSetting';

export type WarehouseStateType = {
  warehouseStocksList: ListResults<ProductQuantity> | null;
  warehouseStocksLoading: boolean;
  warehouseStocksError: HttpError;
  warehouseStocksListUpdateNeeded: boolean;
  warehousesList: ListResults<Warehouse> | null;
  warehousesLoading: boolean;
  warehousesError: HttpError;
  warehousesListUpdateNeeded: boolean;
  warehouseCreateLoading: boolean;
  warehouseCreateError: HttpError;
  warehouseCreateSuccess: boolean;
  createdWarehouse: Warehouse | null;
  warehouseUpdateLoading: boolean;
  warehouseUpdateError: HttpError;
  warehouseUpdateSuccess: boolean;
  warehouse: Warehouse | null;
  warehouseLoading: boolean;
  warehouseError: HttpError;
  warehouseDeleteLoading: boolean;
  warehouseDeleteError: HttpError;
  selectedWarehouse: number | null;
  availableWarehousesList: Warehouse[] | null;
  availableWarehousesLoading: boolean;
  availableWarehousesError: HttpError;
};

export type WarehouseActionType = WarehouseStateType & {
  type: WarehouseActionTypes;
  settings: UserSetting[];
};

export const initialState: WarehouseStateType = {
  warehousesList: null,
  warehousesLoading: true,
  warehousesError: null,
  warehousesListUpdateNeeded: false,
  warehouseCreateLoading: false,
  warehouseCreateError: null,
  warehouseCreateSuccess: false,
  createdWarehouse: null,
  warehouseUpdateLoading: false,
  warehouseUpdateError: null,
  warehouseUpdateSuccess: false,
  warehouse: null,
  warehouseLoading: false,
  warehouseError: null,
  warehouseDeleteLoading: false,
  warehouseDeleteError: null,
  warehouseStocksError: null,
  warehouseStocksListUpdateNeeded: false,
  warehouseStocksLoading: false,
  warehouseStocksList: null,
  selectedWarehouse: null,
  availableWarehousesError: null,
  availableWarehousesList: null,
  availableWarehousesLoading: false,
};

const fetchWarehousesStart = (
  state: WarehouseStateType,
): WarehouseStateType => ({
  ...state,
  warehousesLoading: true,
});

const fetchWarehousesSuccess = (
  state: WarehouseStateType,
  action: WarehouseActionType,
): WarehouseStateType => ({
  ...state,
  warehousesList: action.warehousesList,
  warehousesLoading: false,
  warehousesError: null,
  warehousesListUpdateNeeded: false,
});

const fetchWarehousesFail = (
  state: WarehouseStateType,
  action: WarehouseActionType,
): WarehouseStateType => ({
  ...state,
  warehousesError: action.warehousesError,
  warehousesLoading: false,
});

const fetchAvailableWarehousesStart = (
  state: WarehouseStateType,
): WarehouseStateType => ({
  ...state,
  availableWarehousesLoading: true,
});

const fetchAvailableWarehousesSuccess = (
  state: WarehouseStateType,
  action: WarehouseActionType,
): WarehouseStateType => ({
  ...state,
  availableWarehousesList: action.availableWarehousesList,
  availableWarehousesLoading: false,
  availableWarehousesError: null,
});

const fetchAvailableWarehousesFail = (
  state: WarehouseStateType,
  action: WarehouseActionType,
): WarehouseStateType => ({
  ...state,
  availableWarehousesError: action.availableWarehousesError,
  availableWarehousesLoading: false,
});

const fetchWarehouseStart = (
  state: WarehouseStateType,
): WarehouseStateType => ({
  ...state,
  warehouseLoading: true,
  warehouseUpdateSuccess: false,
  warehouseCreateSuccess: false,
  warehouseCreateError: null,
  warehouseUpdateError: null,
});

const fetchWarehouseSuccess = (
  state: WarehouseStateType,
  action: WarehouseActionType,
): WarehouseStateType => ({
  ...state,
  warehouse: action.warehouse,
  warehouseLoading: false,
  warehouseError: null,
});

const fetchWarehouseFail = (
  state: WarehouseStateType,
  action: WarehouseActionType,
): WarehouseStateType => ({
  ...state,
  warehouseError: action.warehouseError,
  warehouseLoading: false,
});

const fetchWarehouseStocksStart = (
  state: WarehouseStateType,
): WarehouseStateType => ({
  ...state,
  warehouseStocksLoading: true,
});

const fetchWarehouseStocksSuccess = (
  state: WarehouseStateType,
  action: WarehouseActionType,
): WarehouseStateType => ({
  ...state,
  warehouseStocksList: action.warehouseStocksList,
  warehouseStocksLoading: false,
  warehouseStocksError: null,
  warehouseStocksListUpdateNeeded: false,
});

const fetchWarehouseStocksFail = (
  state: WarehouseStateType,
  action: WarehouseActionType,
): WarehouseStateType => ({
  ...state,
  warehouseStocksError: action.warehouseStocksError,
  warehouseStocksLoading: false,
});

const createWarehouseStart = (
  state: WarehouseStateType,
): WarehouseStateType => ({
  ...state,
  warehouseCreateLoading: true,
});

const createWarehouseSuccess = (
  state: WarehouseStateType,
  action: WarehouseActionType,
): WarehouseStateType => ({
  ...state,
  warehouseCreateLoading: false,
  warehouseCreateError: null,
  warehouseCreateSuccess: true,
  createdWarehouse: action.createdWarehouse,
  warehousesListUpdateNeeded: true,
});

const createWarehouseFail = (
  state: WarehouseStateType,
  action: WarehouseActionType,
): WarehouseStateType => ({
  ...state,
  warehouseCreateLoading: false,
  warehouseCreateError: action.warehouseCreateError,
});

const updateWarehouseStart = (
  state: WarehouseStateType,
): WarehouseStateType => ({
  ...state,
  warehouseUpdateLoading: true,
});

const updateWarehouseSuccess = (
  state: WarehouseStateType,
): WarehouseStateType => ({
  ...state,
  warehouseUpdateLoading: false,
  warehouseUpdateError: null,
  warehouseUpdateSuccess: true,
  warehousesListUpdateNeeded: true,
});

const updateWarehouseFail = (
  state: WarehouseStateType,
  action: WarehouseActionType,
): WarehouseStateType => ({
  ...state,
  warehouseUpdateLoading: false,
  warehouseUpdateError: action.warehouseUpdateError,
});

const deleteWarehouseStart = (
  state: WarehouseStateType,
): WarehouseStateType => ({
  ...state,
  warehouseDeleteLoading: true,
});

const deleteWarehouseSuccess = (
  state: WarehouseStateType,
): WarehouseStateType => ({
  ...state,
  warehouseDeleteLoading: false,
  warehouseDeleteError: null,
  warehousesListUpdateNeeded: true,
});

const deleteWarehouseFail = (
  state: WarehouseStateType,
  action: WarehouseActionType,
): WarehouseStateType => ({
  ...state,
  warehouseDeleteLoading: false,
  warehouseDeleteError: action.warehouseDeleteError,
});

const setSelectedWarehouse = (
  state: WarehouseStateType,
  action: WarehouseActionType,
): WarehouseStateType => ({
  ...state,
  selectedWarehouse: action.selectedWarehouse,
});

const setSelectedWarehouseSettings = (
  state: WarehouseStateType,
  action: WarehouseActionType,
): WarehouseStateType => ({
  ...state,
  selectedWarehouse: Number(
    action.settings.find(
      (setting) => setting.type === UserSettingType.WAREHOUSE,
    )?.value,
  ),
});

const resetWarehouseStore = (
  state: WarehouseStateType,
): WarehouseStateType => ({
  ...initialState,
  selectedWarehouse: state.selectedWarehouse,
});

const logout = (): WarehouseStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: WarehouseActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_WAREHOUSES_START:
      return fetchWarehousesStart(state);
    case actionTypes.FETCH_WAREHOUSES_SUCCESS:
      return fetchWarehousesSuccess(state, action);
    case actionTypes.FETCH_WAREHOUSES_FAIL:
      return fetchWarehousesFail(state, action);
    case actionTypes.FETCH_WAREHOUSE_START:
      return fetchWarehouseStart(state);
    case actionTypes.FETCH_WAREHOUSE_SUCCESS:
      return fetchWarehouseSuccess(state, action);
    case actionTypes.FETCH_WAREHOUSE_FAIL:
      return fetchWarehouseFail(state, action);
    case actionTypes.FETCH_WAREHOUSE_STOCKS_START:
      return fetchWarehouseStocksStart(state);
    case actionTypes.FETCH_WAREHOUSE_STOCKS_SUCCESS:
      return fetchWarehouseStocksSuccess(state, action);
    case actionTypes.FETCH_WAREHOUSE_STOCKS_FAIL:
      return fetchWarehouseStocksFail(state, action);
    case actionTypes.CREATE_WAREHOUSE_START:
      return createWarehouseStart(state);
    case actionTypes.CREATE_WAREHOUSE_SUCCESS:
      return createWarehouseSuccess(state, action);
    case actionTypes.CREATE_WAREHOUSE_FAIL:
      return createWarehouseFail(state, action);
    case actionTypes.UPDATE_WAREHOUSE_START:
      return updateWarehouseStart(state);
    case actionTypes.UPDATE_WAREHOUSE_SUCCESS:
      return updateWarehouseSuccess(state);
    case actionTypes.UPDATE_WAREHOUSE_FAIL:
      return updateWarehouseFail(state, action);
    case actionTypes.DELETE_WAREHOUSE_START:
      return deleteWarehouseStart(state);
    case actionTypes.DELETE_WAREHOUSE_SUCCESS:
      return deleteWarehouseSuccess(state);
    case actionTypes.DELETE_WAREHOUSE_FAIL:
      return deleteWarehouseFail(state, action);
    case actionTypes.SET_SELECTED_WAREHOUSE:
      return setSelectedWarehouse(state, action);
    case actionTypes.FETCH_AVAILABLE_WAREHOUSES_START:
      return fetchAvailableWarehousesStart(state);
    case actionTypes.FETCH_AVAILABLE_WAREHOUSES_SUCCESS:
      return fetchAvailableWarehousesSuccess(state, action);
    case actionTypes.FETCH_AVAILABLE_WAREHOUSES_FAIL:
      return fetchAvailableWarehousesFail(state, action);
    case actionTypes.SET_WAREHOUSE_SELECTED_SETTINGS:
      return setSelectedWarehouseSettings(state, action);
    case actionTypes.RESET_WAREHOUSE_STORE:
      return resetWarehouseStore(state);
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;
