export const FETCH_ORDERS_START = 'FETCH_ORDERS_START';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAIL = 'FETCH_ORDERS_FAIL';

export const FETCH_ORDER_START = 'FETCH_ORDER_START';
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_FAIL = 'FETCH_ORDER_FAIL';

export const CREATE_ORDER_START = 'CREATE_ORDER_START';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAIL = 'CREATE_ORDER_FAIL';

export const UPDATE_ORDER_START = 'UPDATE_ORDER_START';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAIL = 'UPDATE_ORDER_FAIL';

export const SPLIT_ORDER_START = 'SPLIT_ORDER_START';
export const SPLIT_ORDER_SUCCESS = 'SPLIT_ORDER_SUCCESS';
export const SPLIT_ORDER_FAIL = 'SPLIT_ORDER_FAIL';

export const COMPLETE_ORDER_START = 'COMPLETE_ORDER_START';
export const COMPLETE_ORDER_SUCCESS = 'COMPLETE_ORDER_SUCCESS';
export const COMPLETE_ORDER_FAIL = 'COMPLETE_ORDER_FAIL';

export const FETCH_FULFILLMENT_WAREHOUSES_START =
  'FETCH_FULFILLMENT_WAREHOUSES_START';
export const FETCH_FULFILLMENT_WAREHOUSES_SUCCESS =
  'FETCH_FULFILLMENT_WAREHOUSES_SUCCESS';
export const FETCH_FULFILLMENT_WAREHOUSES_FAIL =
  'FETCH_FULFILLMENT_WAREHOUSES_FAIL';

export const RESET_ORDER_STORE = 'RESET_ORDER_STORE';

export const LOGOUT = 'LOGOUT';

export type OrderActionTypes =
  | typeof FETCH_ORDERS_START
  | typeof FETCH_ORDERS_SUCCESS
  | typeof FETCH_ORDERS_FAIL
  | typeof FETCH_ORDER_START
  | typeof FETCH_ORDER_SUCCESS
  | typeof FETCH_ORDER_FAIL
  | typeof CREATE_ORDER_START
  | typeof CREATE_ORDER_SUCCESS
  | typeof CREATE_ORDER_FAIL
  | typeof UPDATE_ORDER_START
  | typeof UPDATE_ORDER_SUCCESS
  | typeof UPDATE_ORDER_FAIL
  | typeof FETCH_FULFILLMENT_WAREHOUSES_START
  | typeof FETCH_FULFILLMENT_WAREHOUSES_SUCCESS
  | typeof FETCH_FULFILLMENT_WAREHOUSES_FAIL
  | typeof SPLIT_ORDER_START
  | typeof SPLIT_ORDER_SUCCESS
  | typeof SPLIT_ORDER_FAIL
  | typeof COMPLETE_ORDER_START
  | typeof COMPLETE_ORDER_SUCCESS
  | typeof COMPLETE_ORDER_FAIL
  | typeof RESET_ORDER_STORE
  | typeof LOGOUT;
