export const FETCH_ORDER_FULFILLMENTS_START = 'FETCH_ORDER_FULFILLMENTS_START';
export const FETCH_ORDER_FULFILLMENTS_SUCCESS =
  'FETCH_ORDER_FULFILLMENTS_SUCCESS';
export const FETCH_ORDER_FULFILLMENTS_FAIL = 'FETCH_ORDER_FULFILLMENTS_FAIL';

export const FETCH_ORDER_FULFILLMENT_START = 'FETCH_ORDER_FULFILLMENT_START';
export const FETCH_ORDER_FULFILLMENT_SUCCESS =
  'FETCH_ORDER_FULFILLMENT_SUCCESS';
export const FETCH_ORDER_FULFILLMENT_FAIL = 'FETCH_ORDER_FULFILLMENT_FAIL';

export const COMPLETE_ORDER_FULFILLMENT_START =
  'COMPLETE_ORDER_FULFILLMENT_START';
export const COMPLETE_ORDER_FULFILLMENT_SUCCESS =
  'COMPLETE_ORDER_FULFILLMENT_SUCCESS';
export const COMPLETE_ORDER_FULFILLMENT_FAIL =
  'COMPLETE_ORDER_FULFILLMENT_FAIL';

export const UPLOAD_TRACKING_DATA_START = 'UPLOAD_TRACKING_DATA_START';
export const UPLOAD_TRACKING_DATA_SUCCESS = 'UPLOAD_TRACKING_DATA_SUCCESS';
export const UPLOAD_TRACKING_DATA_FAIL = 'UPLOAD_TRACKING_DATA_FAIL';

export const RESET_ORDER_FULFILLMENT_STORE = 'RESET_ORDER_FULFILLMENT_STORE';

export const LOGOUT = 'LOGOUT';

export type OrderFulfillmentActionTypes =
  | typeof FETCH_ORDER_FULFILLMENTS_START
  | typeof FETCH_ORDER_FULFILLMENTS_SUCCESS
  | typeof FETCH_ORDER_FULFILLMENTS_FAIL
  | typeof FETCH_ORDER_FULFILLMENT_START
  | typeof FETCH_ORDER_FULFILLMENT_SUCCESS
  | typeof FETCH_ORDER_FULFILLMENT_FAIL
  | typeof COMPLETE_ORDER_FULFILLMENT_START
  | typeof COMPLETE_ORDER_FULFILLMENT_SUCCESS
  | typeof COMPLETE_ORDER_FULFILLMENT_FAIL
  | typeof UPLOAD_TRACKING_DATA_START
  | typeof UPLOAD_TRACKING_DATA_SUCCESS
  | typeof UPLOAD_TRACKING_DATA_FAIL
  | typeof RESET_ORDER_FULFILLMENT_STORE
  | typeof LOGOUT;
