export const FETCH_PRODUCTS_START = 'FETCH_PRODUCTS_START';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAIL = 'FETCH_PRODUCTS_FAIL';

export const FETCH_PRODUCT_START = 'FETCH_PRODUCT_START';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_PRODUCT_FAIL = 'FETCH_PRODUCT_FAIL';

export const CREATE_PRODUCT_START = 'CREATE_PRODUCT_START';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAIL = 'CREATE_PRODUCT_FAIL';

export const UPDATE_PRODUCT_START = 'UPDATE_PRODUCT_START';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAIL = 'UPDATE_PRODUCT_FAIL';

export const DELETE_PRODUCT_START = 'DELETE_PRODUCT_START';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL';

export const RESET_PRODUCT_STORE = 'RESET_PRODUCT_STORE';

export const LOGOUT = 'LOGOUT';

export type ProductActionTypes =
  | typeof FETCH_PRODUCTS_START
  | typeof FETCH_PRODUCTS_SUCCESS
  | typeof FETCH_PRODUCTS_FAIL
  | typeof FETCH_PRODUCT_START
  | typeof FETCH_PRODUCT_SUCCESS
  | typeof FETCH_PRODUCT_FAIL
  | typeof CREATE_PRODUCT_START
  | typeof CREATE_PRODUCT_SUCCESS
  | typeof CREATE_PRODUCT_FAIL
  | typeof UPDATE_PRODUCT_START
  | typeof UPDATE_PRODUCT_SUCCESS
  | typeof UPDATE_PRODUCT_FAIL
  | typeof RESET_PRODUCT_STORE
  | typeof DELETE_PRODUCT_START
  | typeof DELETE_PRODUCT_SUCCESS
  | typeof DELETE_PRODUCT_FAIL
  | typeof LOGOUT;
