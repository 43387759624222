import * as actionTypes from './actionTypes';
import { ProjectKeyActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ProjectKey } from '../../domain/ProjectKey';

export type ProjectKeyStateType = {
  projectKeyCreateLoading: boolean;
  projectKeyCreateSuccess: boolean;
  projectKeyCreateError: HttpError;
  createdProjectKey: ProjectKey | null;
  projectKeyUpdateLoading: boolean;
  projectKeyUpdateSuccess: boolean;
  projectKeyUpdateError: HttpError;
  updatedProjectKey: ProjectKey | null;
  projectKey: ProjectKey | null;
  projectKeyLoading: boolean;
  projectKeyError: HttpError;
  projectKeyDeleteLoading: boolean;
  projectKeyDeleteSuccess: boolean;
  projectKeyDeleteError: HttpError;
  generateProjectKeyLoading: Record<number, boolean>;
  generateProjectKeySuccess: boolean;
  generateProjectKeyError: HttpError;
};

export type ProjectKeyActionType = ProjectKeyStateType & {
  type: ProjectKeyActionTypes;
  projectKeyId: number;
};

export const initialState: ProjectKeyStateType = {
  projectKeyCreateLoading: false,
  projectKeyCreateError: null,
  projectKeyCreateSuccess: false,
  createdProjectKey: null,
  projectKeyUpdateLoading: false,
  projectKeyUpdateError: null,
  projectKeyUpdateSuccess: false,
  updatedProjectKey: null,
  projectKey: null,
  projectKeyLoading: false,
  projectKeyError: null,
  projectKeyDeleteLoading: false,
  projectKeyDeleteError: null,
  projectKeyDeleteSuccess: false,
  generateProjectKeyError: null,
  generateProjectKeySuccess: false,
  generateProjectKeyLoading: {},
};

const createProjectKeyStart = (
  state: ProjectKeyStateType,
): ProjectKeyStateType => ({
  ...state,
  projectKeyCreateLoading: true,
});

const createProjectKeySuccess = (
  state: ProjectKeyStateType,
  action: ProjectKeyActionType,
): ProjectKeyStateType => ({
  ...state,
  projectKeyCreateLoading: false,
  projectKeyCreateError: null,
  projectKeyCreateSuccess: true,
  createdProjectKey: action.createdProjectKey,
});

const createProjectKeyFail = (
  state: ProjectKeyStateType,
  action: ProjectKeyActionType,
): ProjectKeyStateType => ({
  ...state,
  projectKeyCreateLoading: false,
  projectKeyCreateError: action.projectKeyCreateError,
});

const updateProjectKeyStart = (
  state: ProjectKeyStateType,
): ProjectKeyStateType => ({
  ...state,
  projectKeyUpdateLoading: true,
});

const updateProjectKeySuccess = (
  state: ProjectKeyStateType,
  action: ProjectKeyActionType,
): ProjectKeyStateType => ({
  ...state,
  projectKeyUpdateLoading: false,
  projectKeyUpdateError: null,
  projectKeyUpdateSuccess: true,
  updatedProjectKey: action.updatedProjectKey,
});

const updateProjectKeyFail = (
  state: ProjectKeyStateType,
  action: ProjectKeyActionType,
): ProjectKeyStateType => ({
  ...state,
  projectKeyUpdateLoading: false,
  projectKeyUpdateError: action.projectKeyUpdateError,
});

const deleteProjectKeyStart = (
  state: ProjectKeyStateType,
): ProjectKeyStateType => ({
  ...state,
  projectKeyDeleteLoading: true,
});

const deleteProjectKeySuccess = (
  state: ProjectKeyStateType,
): ProjectKeyStateType => ({
  ...state,
  projectKeyDeleteLoading: false,
  projectKeyDeleteError: null,
  projectKeyDeleteSuccess: true,
});

const deleteProjectKeyFail = (
  state: ProjectKeyStateType,
  action: ProjectKeyActionType,
): ProjectKeyStateType => ({
  ...state,
  projectKeyDeleteLoading: false,
  projectKeyDeleteError: action.projectKeyDeleteError,
});

const generateProjectKeyStart = (
  state: ProjectKeyStateType,
  action: ProjectKeyActionType,
): ProjectKeyStateType => {
  const loadings = state.generateProjectKeyLoading;

  loadings[action.projectKeyId] = true;

  return {
    ...state,
    generateProjectKeyLoading: loadings,
  };
};

const generateProjectKeySuccess = (
  state: ProjectKeyStateType,
  action: ProjectKeyActionType,
): ProjectKeyStateType => {
  const loadings = state.generateProjectKeyLoading;

  loadings[action.projectKeyId] = false;

  return {
    ...state,
    generateProjectKeyLoading: loadings,
    generateProjectKeyError: null,
    generateProjectKeySuccess: true,
  };
};

const generateProjectKeyFail = (
  state: ProjectKeyStateType,
  action: ProjectKeyActionType,
): ProjectKeyStateType => {
  const loadings = state.generateProjectKeyLoading;

  loadings[action.projectKeyId] = true;

  return {
    ...state,
    generateProjectKeyLoading: loadings,
    generateProjectKeyError: action.generateProjectKeyError,
  };
};

const resetProjectKeyStore = (): ProjectKeyStateType => ({
  ...initialState,
});

const logout = (): ProjectKeyStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: ProjectKeyActionType) => {
  switch (action.type) {
    case actionTypes.CREATE_PROJECT_KEY_START:
      return createProjectKeyStart(state);
    case actionTypes.CREATE_PROJECT_KEY_SUCCESS:
      return createProjectKeySuccess(state, action);
    case actionTypes.CREATE_PROJECT_KEY_FAIL:
      return createProjectKeyFail(state, action);
    case actionTypes.UPDATE_PROJECT_KEY_START:
      return updateProjectKeyStart(state);
    case actionTypes.UPDATE_PROJECT_KEY_SUCCESS:
      return updateProjectKeySuccess(state, action);
    case actionTypes.UPDATE_PROJECT_KEY_FAIL:
      return updateProjectKeyFail(state, action);
    case actionTypes.DELETE_PROJECT_KEY_START:
      return deleteProjectKeyStart(state);
    case actionTypes.DELETE_PROJECT_KEY_SUCCESS:
      return deleteProjectKeySuccess(state);
    case actionTypes.DELETE_PROJECT_KEY_FAIL:
      return deleteProjectKeyFail(state, action);
    case actionTypes.GENERATE_PROJECT_KEY_KEY_START:
      return generateProjectKeyStart(state, action);
    case actionTypes.GENERATE_PROJECT_KEY_KEY_SUCCESS:
      return generateProjectKeySuccess(state, action);
    case actionTypes.GENERATE_PROJECT_KEY_KEY_FAIL:
      return generateProjectKeyFail(state, action);
    case actionTypes.RESET_PROJECT_KEY_STORE:
      return resetProjectKeyStore();
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;
