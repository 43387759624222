export const FETCH_COURIERS_START = 'FETCH_COURIERS_START';
export const FETCH_COURIERS_SUCCESS = 'FETCH_COURIERS_SUCCESS';
export const FETCH_COURIERS_FAIL = 'FETCH_COURIERS_FAIL';

export const TOGGLE_COURIER_STATUS_START = 'TOGGLE_COURIER_STATUS_START';
export const TOGGLE_COURIER_STATUS_SUCCESS = 'TOGGLE_COURIER_STATUS_SUCCESS';
export const TOGGLE_COURIER_STATUS_FAIL = 'TOGGLE_COURIER_STATUS_FAIL';

export const RESET_COURIER_STORE = 'RESET_COURIER_STORE';

export const LOGOUT = 'LOGOUT';

export type CourierActionTypes =
  | typeof FETCH_COURIERS_START
  | typeof FETCH_COURIERS_SUCCESS
  | typeof FETCH_COURIERS_FAIL
  | typeof TOGGLE_COURIER_STATUS_START
  | typeof TOGGLE_COURIER_STATUS_SUCCESS
  | typeof TOGGLE_COURIER_STATUS_FAIL
  | typeof RESET_COURIER_STORE
  | typeof LOGOUT;
