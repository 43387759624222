import * as actionTypes from './actionTypes';
import { BillOfLadingProductsActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { BillOfLadingProduct } from '../../domain/BillOfLadingProduct';

export type BillOfLadingProductStateType = {
  billOfLadingProduct: BillOfLadingProduct | null;
  setArrivedQuantityLoading: boolean;
  setArrivedQuantitySuccess: boolean;
  setArrivedQuantityError: HttpError;
  confirmArrivedQuantityLoading: Record<string, boolean>;
  confirmArrivedQuantitySuccess: Record<string, boolean>;
  confirmArrivedQuantityError: HttpError;
};

export type BillOfLadingProductActionType = BillOfLadingProductStateType & {
  type: BillOfLadingProductsActionTypes;
  id: string;
};

export const initialState: BillOfLadingProductStateType = {
  billOfLadingProduct: null,
  confirmArrivedQuantityError: null,
  confirmArrivedQuantityLoading: {},
  confirmArrivedQuantitySuccess: {},
  setArrivedQuantityError: null,
  setArrivedQuantityLoading: false,
  setArrivedQuantitySuccess: false,
};

const setArrivedQuantityStart = (
  state: BillOfLadingProductStateType,
  action: BillOfLadingProductActionType,
): BillOfLadingProductStateType => {
  const confirmedLoadings = state.confirmArrivedQuantityLoading;
  const confirmedSuccesses = state.confirmArrivedQuantitySuccess;

  confirmedLoadings[action.id] = false;
  confirmedSuccesses[action.id] = false;

  return {
    ...state,
    setArrivedQuantityLoading: true,
    billOfLadingProduct: null,
    setArrivedQuantitySuccess: false,
    confirmArrivedQuantitySuccess: confirmedSuccesses,
    confirmArrivedQuantityLoading: confirmedLoadings,
  };
};

const setArrivedQuantitySuccess = (
  state: BillOfLadingProductStateType,
  action: BillOfLadingProductActionType,
): BillOfLadingProductStateType => ({
  ...state,
  billOfLadingProduct: action.billOfLadingProduct,
  setArrivedQuantityLoading: false,
  setArrivedQuantitySuccess: true,
  setArrivedQuantityError: null,
});

const setArrivedQuantityFail = (
  state: BillOfLadingProductStateType,
  action: BillOfLadingProductActionType,
): BillOfLadingProductStateType => ({
  ...state,
  setArrivedQuantityError: action.setArrivedQuantityError,
  setArrivedQuantityLoading: false,
});

const confirmArrivedQuantityStart = (
  state: BillOfLadingProductStateType,
  action: BillOfLadingProductActionType,
): BillOfLadingProductStateType => {
  const confirmedLoadings = state.confirmArrivedQuantityLoading;
  const confirmedSuccesses = state.confirmArrivedQuantitySuccess;

  confirmedLoadings[action.id] = true;
  confirmedSuccesses[action.id] = false;

  return {
    ...state,
    confirmArrivedQuantityLoading: confirmedLoadings,
    confirmArrivedQuantitySuccess: confirmedSuccesses,
    setArrivedQuantitySuccess: false,
    billOfLadingProduct: null,
  };
};

const confirmArrivedQuantitySuccess = (
  state: BillOfLadingProductStateType,
  action: BillOfLadingProductActionType,
): BillOfLadingProductStateType => {
  const confirmedLoadings = state.confirmArrivedQuantityLoading;
  const confirmedSuccesses = state.confirmArrivedQuantitySuccess;

  confirmedLoadings[action.id] = false;
  confirmedSuccesses[action.id] = true;

  return {
    ...state,
    billOfLadingProduct: action.billOfLadingProduct,
    confirmArrivedQuantityLoading: confirmedLoadings,
    confirmArrivedQuantitySuccess: confirmedSuccesses,
  };
};

const confirmArrivedQuantityFail = (
  state: BillOfLadingProductStateType,
  action: BillOfLadingProductActionType,
): BillOfLadingProductStateType => {
  const confirmedLoadings = state.confirmArrivedQuantityLoading;

  confirmedLoadings[action.id] = false;

  return {
    ...state,
    confirmArrivedQuantityError: action.confirmArrivedQuantityError,
    confirmArrivedQuantityLoading: confirmedLoadings,
  };
};

const resetBillOfLadingProductStore = (): BillOfLadingProductStateType => {
  return {
    ...initialState,
    confirmArrivedQuantityLoading: {},
    confirmArrivedQuantitySuccess: {},
  };
};

const logout = (): BillOfLadingProductStateType => ({
  ...initialState,
  confirmArrivedQuantityLoading: {},
  confirmArrivedQuantitySuccess: {},
});

const reducer = (
  state = initialState,
  action: BillOfLadingProductActionType,
) => {
  switch (action.type) {
    case actionTypes.SET_ARRIVED_QUANTITY_START:
      return setArrivedQuantityStart(state, action);
    case actionTypes.SET_ARRIVED_QUANTITY_SUCCESS:
      return setArrivedQuantitySuccess(state, action);
    case actionTypes.SET_ARRIVED_QUANTITY_FAIL:
      return setArrivedQuantityFail(state, action);
    case actionTypes.CONFIRM_ARRIVED_QUANTITY_START:
      return confirmArrivedQuantityStart(state, action);
    case actionTypes.CONFIRM_ARRIVED_QUANTITY_SUCCESS:
      return confirmArrivedQuantitySuccess(state, action);
    case actionTypes.CONFIRM_ARRIVED_QUANTITY_FAIL:
      return confirmArrivedQuantityFail(state, action);
    case actionTypes.RESET_BILL_OF_LADING_PRODUCTS_STORE:
      return resetBillOfLadingProductStore();
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;
