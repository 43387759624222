import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import {
  createPaymentStart,
  createPaymentSuccess,
  createPaymentFail,
  fetchPaymentsSuccess,
  fetchPaymentsStart,
  fetchPaymentsFail,
} from './actions';
import { IntlShape } from 'react-intl';
import { showToast } from '../../utility/toast/toast';
import { translate } from '../../utility/messageTranslator/translate';
import { ListParams } from '../../hooks/useList/useList';

const API_URL = '/payments';

export type PaymentCreateRequest = {
  amount: number;
};

export const fetchPayments =
  (params: ListParams, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(fetchPaymentsStart());
    return axios
      .get(API_URL, { params })
      .then((response) => {
        dispatch(fetchPaymentsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchPaymentsFail(err.response.data.message));
        showToast(translate(intl, err.response.data.message), 'error');
      });
  };

export const createPayment =
  (inputs: PaymentCreateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(createPaymentStart());
    return axios
      .post(API_URL, { ...inputs })
      .then((response) => {
        dispatch(createPaymentSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createPaymentFail(err.response.data.message));
      });
  };
