import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import styles from './ProjectSelectForm.module.scss';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import Form from '../../common/Form/Form';
import FormCard from '../../common/FormCard/FormCard';
import TextField from '../../common/TextField/TextField';
import Button from '../../common/Button/Button';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { useForm } from '../../hooks/useForm/useForm';
import { Project } from '../../domain/Project';
import { setSelectedProject } from '../../store/project/actions';
import { routes } from '../../config/Router/routes';
import { useNavigate } from 'react-router-dom';
import { UserSetting, UserSettingType } from '../../domain/UserSetting';
import * as userSettingService from '../../store/user-setting/service';

type Props = {
  projects: Project[];
  onProjectSelect: (projectId: number) => void;
  onSuccessSelect?: () => void;
  selectedProject: number | null;
  onSetSetting: (type: UserSettingType, value: string) => void;
  createdUserSetting: UserSetting | null;
};

type FormInputs = {
  projectId: string;
};

export const ProjectSelectForm = ({
  projects,
  onProjectSelect,
  onSuccessSelect,
  selectedProject,
  createdUserSetting,
  onSetSetting,
}: Props) => {
  const [isSelectedLoading, setIsSelectLoading] = useState<boolean>(false);
  const intl = useIntl();
  const navigate = useNavigate();

  const projectOptions = useMemo(
    () =>
      projects.map((project: Project) => {
        return {
          value: project.id.toString(),
          label: project.name,
        };
      }) || [],
    [projects],
  );

  const INPUTS = [
    {
      name: 'projectId',
      label: translate(intl, 'PROJECTS.SELECT_FORM_NAME'),
      type: 'autocomplete',
      validation: [
        {
          type: 'required',
        },
      ],
      options: [],
      value: selectedProject?.toString() ?? '',
    },
  ];

  useEffect(() => {
    if (
      createdUserSetting?.type === UserSettingType.PROJECT &&
      isSelectedLoading
    ) {
      const project = projects.find(
        (project) => project.id === Number(createdUserSetting.value),
      );

      if (project) {
        onProjectSelect(project.id);
      }

      onSuccessSelect?.();
      navigate(routes.products.list);
      setTimeout(() => {
        navigate(routes.products.list);
      }, 50);
      onSuccessSelect?.();

      setIsSelectLoading(false);
    }
  }, [createdUserSetting]);

  const handleSubmit = async (submitInputs: FormInputs) => {
    onSetSetting(UserSettingType.PROJECT, submitInputs.projectId);
    setIsSelectLoading(true);
  };

  const {
    inputs,
    onSubmit,
    onInputChange,
    onLoseInputFocus,
    onSelectChange,
    setNewInputObject,
  } = useForm<FormInputs>(INPUTS, handleSubmit);

  useEffect(() => {
    if (projectOptions) {
      setNewInputObject('projectId', {
        options: projectOptions,
      });
    }
  }, [projectOptions]);

  return (
    <div className={styles.projectSelectForm}>
      <Form className={styles.form} error={null} onSubmit={onSubmit}>
        <FormCard>
          {inputs.map((input) => (
            <TextField
              key={input.name}
              onChange={onInputChange}
              value={input.value?.toString() ?? ''}
              label={input.label ?? ''}
              errors={input.validationErrors ?? []}
              onBlur={onLoseInputFocus}
              name={input.name}
              type={input.type}
              options={input.options}
              onSelectChange={onSelectChange}
            />
          ))}
          <Button
            isLoadingButton
            onClick={onSubmit}
            buttonVariant="contained"
            color="primary"
            type="submit"
          >
            {translate(intl, 'PROJECTS.SELECT_PROJECT')}
          </Button>
        </FormCard>
      </Form>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedProject: state.project.selectedProject,
  createdUserSetting: state.userSetting.createdUserSetting,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onProjectSelect: (projectId: number) =>
    dispatch(setSelectedProject(projectId)),
  onSetSetting: (type: UserSettingType, value: string) =>
    dispatch(
      userSettingService.setUserSetting({
        type,
        value,
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSelectForm);
