export const FETCH_BILL_OF_LADINGS_START = 'FETCH_BILL_OF_LADINGS_START';
export const FETCH_BILL_OF_LADINGS_SUCCESS = 'FETCH_BILL_OF_LADINGS_SUCCESS';
export const FETCH_BILL_OF_LADINGS_FAIL = 'FETCH_BILL_OF_LADINGS_FAIL';

export const FETCH_BILL_OF_LADING_START = 'FETCH_BILL_OF_LADING_START';
export const FETCH_BILL_OF_LADING_SUCCESS = 'FETCH_BILL_OF_LADING_SUCCESS';
export const FETCH_BILL_OF_LADING_FAIL = 'FETCH_BILL_OF_LADING_FAIL';

export const CREATE_BILL_OF_LADING_START = 'CREATE_BILL_OF_LADING_START';
export const CREATE_BILL_OF_LADING_SUCCESS = 'CREATE_BILL_OF_LADING_SUCCESS';
export const CREATE_BILL_OF_LADING_FAIL = 'CREATE_BILL_OF_LADING_FAIL';

export const COMPLETE_BILL_OF_LADING_START = 'COMPLETE_BILL_OF_LADING_START';
export const COMPLETE_BILL_OF_LADING_SUCCESS =
  'COMPLETE_BILL_OF_LADING_SUCCESS';
export const COMPLETE_BILL_OF_LADING_FAIL = 'COMPLETE_BILL_OF_LADING_FAIL';

export const RESET_BILL_OF_LADING_STORE = 'RESET_BILL_OF_LADING_STORE';

export const LOGOUT = 'LOGOUT';

export type BillOfLadingActionTypes =
  | typeof CREATE_BILL_OF_LADING_START
  | typeof CREATE_BILL_OF_LADING_SUCCESS
  | typeof CREATE_BILL_OF_LADING_FAIL
  | typeof FETCH_BILL_OF_LADINGS_START
  | typeof FETCH_BILL_OF_LADINGS_SUCCESS
  | typeof FETCH_BILL_OF_LADINGS_FAIL
  | typeof FETCH_BILL_OF_LADING_START
  | typeof FETCH_BILL_OF_LADING_SUCCESS
  | typeof FETCH_BILL_OF_LADING_FAIL
  | typeof COMPLETE_BILL_OF_LADING_START
  | typeof COMPLETE_BILL_OF_LADING_SUCCESS
  | typeof COMPLETE_BILL_OF_LADING_FAIL
  | typeof RESET_BILL_OF_LADING_STORE
  | typeof LOGOUT;
