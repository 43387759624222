import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { User } from '../../domain/User';
import { ListResults } from '../../common/List/List';

export const fetchUserStart = () => ({
  type: actionTypes.FETCH_USER_START,
});

export const fetchUserSuccess = (user: User) => ({
  type: actionTypes.FETCH_USER_SUCCESS,
  user,
});

export const fetchUserFail = (userError: HttpError) => ({
  type: actionTypes.FETCH_USER_FAIL,
  userError,
});

export const fetchUsersStart = () => ({
  type: actionTypes.FETCH_USERS_START,
});

export const fetchUsersSuccess = (usersList: ListResults<User>) => ({
  type: actionTypes.FETCH_USERS_SUCCESS,
  usersList,
});

export const fetchUsersFail = (usersError: HttpError) => ({
  type: actionTypes.FETCH_USERS_FAIL,
  usersError,
});

export const fetchAllUsersStart = () => ({
  type: actionTypes.FETCH_ALL_USERS_START,
});

export const fetchAllUsersSuccess = (allUsersList: ListResults<User>) => ({
  type: actionTypes.FETCH_ALL_USERS_SUCCESS,
  allUsersList,
});

export const fetchAllUsersFail = (allUsersError: HttpError) => ({
  type: actionTypes.FETCH_ALL_USERS_FAIL,
  allUsersError,
});

export const createUserStart = () => ({
  type: actionTypes.CREATE_USER_START,
});

export const createUserSuccess = (createdUser: User) => ({
  type: actionTypes.CREATE_USER_SUCCESS,
  createdUser,
});

export const createUserFail = (userCreateError: HttpError) => ({
  type: actionTypes.CREATE_USER_FAIL,
  userCreateError,
});

export const updateUserStart = () => ({
  type: actionTypes.UPDATE_USER_START,
});

export const updateUserSuccess = () => ({
  type: actionTypes.UPDATE_USER_SUCCESS,
});

export const updateUserFail = (userUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_USER_FAIL,
  userUpdateError,
});

export const deleteUserStart = () => ({
  type: actionTypes.DELETE_USER_START,
});

export const deleteUserSuccess = () => ({
  type: actionTypes.DELETE_USER_SUCCESS,
});

export const deleteUserFail = (cityUserError: HttpError) => ({
  type: actionTypes.DELETE_USER_FAIL,
  cityUserError,
});

export const fetchCurrentUserStart = () => ({
  type: actionTypes.FETCH_ME_START,
});

export const fetchCurrentUserSuccess = (currentUser: User) => ({
  type: actionTypes.FETCH_ME_SUCCESS,
  currentUser,
});

export const fetchCurrentUserFail = () => ({
  type: actionTypes.FETCH_ME_FAIL,
});

export const updateUserInfoStart = () => ({
  type: actionTypes.UPDATE_USER_INFO_START,
});

export const updateUserInfoSuccess = () => ({
  type: actionTypes.UPDATE_USER_INFO_SUCCESS,
});

export const updateUserInfoFail = (userUpdateInfoError: HttpError) => ({
  type: actionTypes.UPDATE_USER_INFO_FAIL,
  userUpdateInfoError,
});

export const resetUserInfoStore = () => ({
  type: actionTypes.RESET_USER_INFO_STORE,
});

export const resetUserStore = () => ({
  type: actionTypes.RESET_USER_STORE,
});

export const resetCreatedUser = () => ({
  type: actionTypes.RESET_CREATED_USER,
});

export const logout = () => ({
  type: actionTypes.LOGOUT,
});
