export const FETCH_COMPANIES_START = 'FETCH_COMPANIES_START';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_FAIL = 'FETCH_COMPANIES_FAIL';

export const FETCH_COMPANY_OPTIONS_START = 'FETCH_COMPANY_OPTIONS_START';
export const FETCH_COMPANY_OPTIONS_SUCCESS = 'FETCH_COMPANY_OPTIONS_SUCCESS';
export const FETCH_COMPANY_OPTIONS_FAIL = 'FETCH_COMPANY_OPTIONS_FAIL';

export const FETCH_COMPANY_START = 'FETCH_COMPANY_START';
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_FAIL = 'FETCH_COMPANY_FAIL';

export const CREATE_COMPANY_START = 'CREATE_COMPANY_START';
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_FAIL = 'CREATE_COMPANY_FAIL';

export const REGISTER_COMPANY_START = 'REGISTER_COMPANY_START';
export const REGISTER_COMPANY_SUCCESS = 'REGISTER_COMPANY_SUCCESS';
export const REGISTER_COMPANY_FAIL = 'REGISTER_COMPANY_FAIL';

export const UPDATE_COMPANY_START = 'UPDATE_COMPANY_START';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAIL = 'UPDATE_COMPANY_FAIL';

export const TOGGLE_COMPANY_STATUS_START = 'TOGGLE_COMPANY_STATUS_START';
export const TOGGLE_COMPANY_STATUS_SUCCESS = 'TOGGLE_COMPANY_STATUS_SUCCESS';
export const TOGGLE_COMPANY_STATUS_FAIL = 'TOGGLE_COMPANY_STATUS_FAIL';

export const DELETE_COMPANY_START = 'DELETE_COMPANY_START';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_FAIL = 'DELETE_COMPANY_FAIL';

export const UPDATE_COMPANY_BALANCE_START = 'UPDATE_COMPANY_BALANCE_START';
export const UPDATE_COMPANY_BALANCE_SUCCESS = 'UPDATE_COMPANY_BALANCE_SUCCESS';
export const UPDATE_COMPANY_BALANCE_FAIL = 'UPDATE_COMPANY_BALANCE_FAIL';

export const ASSIGN_WAREHOUSE_START = 'ASSIGN_WAREHOUSE_START';
export const ASSIGN_WAREHOUSE_SUCCESS = 'ASSIGN_WAREHOUSE_SUCCESS';
export const ASSIGN_WAREHOUSE_FAIL = 'ASSIGN_WAREHOUSE_FAIL';

export const REMOVE_WAREHOUSE_START = 'REMOVE_WAREHOUSE_START';
export const REMOVE_WAREHOUSE_SUCCESS = 'REMOVE_WAREHOUSE_SUCCESS';
export const REMOVE_WAREHOUSE_FAIL = 'REMOVE_WAREHOUSE_FAIL';

export const SET_COMPANY_SETTINGS_START = 'SET_COMPANY_SETTINGS_START';
export const SET_COMPANY_SETTINGS_SUCCESS = 'SET_COMPANY_SETTINGS_SUCCESS';
export const SET_COMPANY_SETTINGS_FAIL = 'SET_COMPANY_SETTINGS_FAIL';

export const RESET_COMPANY_WAREHOUSE_STORE = 'RESET_COMPANY_WAREHOUSE_STORE';
export const RESET_COMPANY_STORE = 'RESET_COMPANY_STORE';
export const RESET_WHOLE_COMPANY_STORE = 'RESET_WHOLE_COMPANY_STORE';

export const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY';
export const SET_COMPANY_SELECTED_SETTINGS = 'SET_COMPANY_SELECTED_SETTINGS';

export const LOGOUT = 'LOGOUT';

export type CompanyActionTypes =
  | typeof FETCH_COMPANIES_START
  | typeof FETCH_COMPANIES_SUCCESS
  | typeof FETCH_COMPANIES_FAIL
  | typeof FETCH_COMPANY_OPTIONS_START
  | typeof FETCH_COMPANY_OPTIONS_SUCCESS
  | typeof FETCH_COMPANY_OPTIONS_FAIL
  | typeof FETCH_COMPANY_START
  | typeof FETCH_COMPANY_SUCCESS
  | typeof FETCH_COMPANY_FAIL
  | typeof CREATE_COMPANY_START
  | typeof CREATE_COMPANY_SUCCESS
  | typeof CREATE_COMPANY_FAIL
  | typeof UPDATE_COMPANY_START
  | typeof UPDATE_COMPANY_SUCCESS
  | typeof UPDATE_COMPANY_FAIL
  | typeof TOGGLE_COMPANY_STATUS_START
  | typeof TOGGLE_COMPANY_STATUS_SUCCESS
  | typeof TOGGLE_COMPANY_STATUS_FAIL
  | typeof RESET_COMPANY_STORE
  | typeof DELETE_COMPANY_START
  | typeof DELETE_COMPANY_SUCCESS
  | typeof DELETE_COMPANY_FAIL
  | typeof UPDATE_COMPANY_BALANCE_START
  | typeof UPDATE_COMPANY_BALANCE_SUCCESS
  | typeof UPDATE_COMPANY_BALANCE_FAIL
  | typeof ASSIGN_WAREHOUSE_START
  | typeof ASSIGN_WAREHOUSE_SUCCESS
  | typeof ASSIGN_WAREHOUSE_FAIL
  | typeof REMOVE_WAREHOUSE_START
  | typeof REMOVE_WAREHOUSE_SUCCESS
  | typeof REMOVE_WAREHOUSE_FAIL
  | typeof SET_COMPANY_SETTINGS_START
  | typeof SET_COMPANY_SETTINGS_SUCCESS
  | typeof SET_COMPANY_SETTINGS_FAIL
  | typeof REGISTER_COMPANY_START
  | typeof REGISTER_COMPANY_SUCCESS
  | typeof REGISTER_COMPANY_FAIL
  | typeof RESET_COMPANY_WAREHOUSE_STORE
  | typeof RESET_WHOLE_COMPANY_STORE
  | typeof SET_COMPANY_SELECTED_SETTINGS
  | typeof SET_SELECTED_COMPANY
  | typeof LOGOUT;
