export const SET_WAREHOUSE_COST_START = 'SET_WAREHOUSE_COST_START';
export const SET_WAREHOUSE_COST_SUCCESS = 'SET_WAREHOUSE_COST_SUCCESS';
export const SET_WAREHOUSE_COST_FAIL = 'SET_WAREHOUSE_COST_FAIL';

export const RESET_WAREHOUSE_COST_STORE = 'RESET_WAREHOUSE_COST_STORE';

export const LOGOUT = 'LOGOUT';

export type WarehouseCostActionTypes =
  | typeof SET_WAREHOUSE_COST_START
  | typeof SET_WAREHOUSE_COST_SUCCESS
  | typeof SET_WAREHOUSE_COST_FAIL
  | typeof RESET_WAREHOUSE_COST_STORE
  | typeof LOGOUT;
