import * as actionTypes from './actionTypes';
import { BalanceHistoryActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { BalanceHistory } from '../../domain/BalanceHistory';

export type BalanceHistoryStateType = {
  balanceHistoriesList: ListResults<BalanceHistory> | null;
  balanceHistoriesLoading: boolean;
  balanceHistoriesError: HttpError;
};

export type BalanceHistoryActionType = BalanceHistoryStateType & {
  type: BalanceHistoryActionTypes;
};

export const initialState: BalanceHistoryStateType = {
  balanceHistoriesList: null,
  balanceHistoriesLoading: true,
  balanceHistoriesError: null,
};

const fetchBalanceHistoriesStart = (
  state: BalanceHistoryStateType,
): BalanceHistoryStateType => ({
  ...state,
  balanceHistoriesLoading: true,
});

const fetchBalanceHistoriesSuccess = (
  state: BalanceHistoryStateType,
  action: BalanceHistoryActionType,
): BalanceHistoryStateType => ({
  ...state,
  balanceHistoriesList: action.balanceHistoriesList,
  balanceHistoriesLoading: false,
  balanceHistoriesError: null,
});

const fetchBalanceHistoriesFail = (
  state: BalanceHistoryStateType,
  action: BalanceHistoryActionType,
): BalanceHistoryStateType => ({
  ...state,
  balanceHistoriesError: action.balanceHistoriesError,
  balanceHistoriesLoading: false,
});

const resetBalanceHistoryStore = (): BalanceHistoryStateType => ({
  ...initialState,
});

const logout = (): BalanceHistoryStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: BalanceHistoryActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_BALANCE_HISTORIES_START:
      return fetchBalanceHistoriesStart(state);
    case actionTypes.FETCH_BALANCE_HISTORIES_SUCCESS:
      return fetchBalanceHistoriesSuccess(state, action);
    case actionTypes.FETCH_BALANCE_HISTORIES_FAIL:
      return fetchBalanceHistoriesFail(state, action);
    case actionTypes.RESET_BALANCE_HISTORY_STORE:
      return resetBalanceHistoryStore();
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;
