export enum Roles {
  // eslint-disable-next-line no-unused-vars
  ADMIN = 'ADMIN',
  // eslint-disable-next-line no-unused-vars
  OWNER = 'OWNER',
  // eslint-disable-next-line no-unused-vars
  STOCKMAN = 'STOCKMAN',
  // eslint-disable-next-line no-unused-vars
  WAREHOUSE_OWNER = 'WAREHOUSE_OWNER',
}

export interface Role {
  id: number;
  name: Roles;
}
