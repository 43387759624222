import React, { ReactNode, useEffect, useState } from 'react';
import styles from './Layout.module.scss';
import Sidebar from '../Sidebar/Sidebar';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import cx from 'classnames';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import Navigation from '../Navigation/Navigation';
import {
  faBagShopping,
  faBuilding,
  faDiagramProject,
  faGlobe,
  faMoneyBill,
  faShop,
  faTruck,
  faTruckRampBox,
  faUsers,
  faWarehouse,
  faPerson,
  faListCheck,
  faBoxes,
  faShoppingCart,
} from '@fortawesome/free-solid-svg-icons';
import { routes } from '../../config/Router/routes';
import { IntlShape, useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import { Roles } from '../../domain/Role';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { Asset } from '../../domain/Asset';
import { setSelectedCompany } from '../../store/company/actions';
import * as companyService from '../../store/company/service';
import { Company } from '../../domain/Company';
import Modal from '../Modal/Modal';
import CurrentAccount from '../CurrentAccount/CurrentAccount';
import CompanySelectForm from '../CompanySelectForm/CompanySelectForm';
import { setSelectedProject } from '../../store/project/actions';
import { Project } from '../../domain/Project';
import ProjectSelectForm from '../ProjectSelectForm/ProjectSelectForm';
import { User } from '../../domain/User';
import { setSelectedWarehouse } from '../../store/warehouse/actions';
import { Warehouse } from '../../domain/Warehouse';
import WarehouseSelectForm from '../WarehouseSelectForm/WarehouseSelectForm';

export type Props = {
  children: ReactNode;
  userRoles: Roles[];
  companyOptions: Company[];
  onCompanyOptionsFetch: (intl: IntlShape) => void;
  onCompanySelect: (
    companyId: number | null,
    companyLogo: Asset | null,
  ) => void;
  onProjectSelect: (selectedProjectId: number | null) => void;
  companyLogo: Asset | null;
  selectedCompany: number | null;
  isAuthenticated: boolean;
  selectedProject: number | null;
  selectedWarehouse: number | null;
  currentUser: User | null;
  onWarehouseSelect: (selectedWarehouseId: number | null) => void;
};

export type NavigationItem = {
  label: string;
  to: string;
  icon?: IconProp;
};

export type NavigationGroup = {
  label: string;
  items: NavigationItem[];
};

const MOBILE_BREAK_POINT = 900;

const Layout = ({
  children,
  selectedCompany,
  onCompanySelect,
  companyLogo,
  companyOptions,
  onCompanyOptionsFetch,
  userRoles,
  isAuthenticated,
  selectedProject,
  onProjectSelect,
  selectedWarehouse,
  currentUser,
  onWarehouseSelect,
}: Props) => {
  const [company, setCompany] = useState<Company | undefined>(undefined);
  const [project, setProject] = useState<Project | undefined>(undefined);
  const [warehouse, setWarehouse] = useState<Warehouse | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
  const [isWarehouseModalOpen, setIsWarehouseModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const routeLocation = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [routeLocation.key]);

  const { width } = useWindowSize();

  const SIDEBAR_ITEMS = [
    {
      label: translate(intl, 'NAVIGATION.GROUP_GLOBAL'),
      roles: [Roles.ADMIN, Roles.STOCKMAN, Roles.WAREHOUSE_OWNER],
      items: [
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_COMPANIES'),
          to: routes.companies.list,
          icon: faBuilding,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_WAREHOUSES'),
          to: routes.warehouses.list,
          icon: faWarehouse,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_COURIERS'),
          to: routes.couriers.list,
          icon: faTruck,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_PAYMENTS'),
          to: routes.payments.list,
          icon: faMoneyBill,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_ARRIVALS'),
          to: routes.billOfLadings.list,
          icon: faTruckRampBox as IconProp,
          roles: [Roles.STOCKMAN, Roles.WAREHOUSE_OWNER],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_REFUNDS'),
          to: routes.billOfLadings.refunds,
          icon: faBoxes as IconProp,
          roles: [Roles.STOCKMAN, Roles.WAREHOUSE_OWNER],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_ORDER_FULFILLMENTS'),
          to: routes.orderFulfillments.list,
          icon: faListCheck as IconProp,
          roles: [Roles.STOCKMAN, Roles.WAREHOUSE_OWNER],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_MY_WAREHOUSE'),
          to: routes.warehouses.my,
          icon: faWarehouse,
          roles: [Roles.WAREHOUSE_OWNER],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_STOCKMANS'),
          to: routes.stockmans,
          icon: faPerson,
          roles: [Roles.WAREHOUSE_OWNER],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_ADMINS'),
          to: routes.admins.list,
          icon: faUsers as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_TRANSLATIONS'),
          to: routes.translations,
          icon: faGlobe as IconProp,
          roles: [Roles.ADMIN],
        },
      ],
    },
  ];

  useEffect(() => {
    if (isMobileMenuOpen) {
      window.scroll({ top: 0 });
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMobileMenuOpen]);

  useEffect(() => {
    if (
      isAuthenticated &&
      !userRoles.includes(Roles.STOCKMAN) &&
      !userRoles.includes(Roles.WAREHOUSE_OWNER)
    ) {
      onCompanyOptionsFetch(intl);
    }
  }, []);

  useEffect(() => {
    if (selectedCompany && companyOptions.length === 0) {
      return;
    }

    const company = companyOptions.find(
      (companyOption) => companyOption.id === selectedCompany,
    );

    const project = company?.projects.find(
      (project) => project.id === selectedProject,
    );

    setCompany(company);
    setProject(project);
    onProjectSelect(project ? project.id : null);
    onCompanySelect(company ? company.id : null, company ? company.logo : null);
  }, [companyOptions, selectedCompany, selectedProject]);

  useEffect(() => {
    if (selectedWarehouse && !currentUser?.ownedWarehouses.length) {
      return;
    }

    const warehouse = currentUser?.ownedWarehouses.find(
      (warehouse) => warehouse.id === selectedWarehouse,
    );

    setWarehouse(warehouse);
    onWarehouseSelect(warehouse ? warehouse.id : null);
  }, [currentUser, selectedWarehouse]);

  useEffect(() => {
    if (selectedCompany) {
      setIsModalOpen(false);
    }
  }, [selectedCompany]);

  const getNavigationGroups = () => {
    if (
      (userRoles.includes(Roles.STOCKMAN) && !currentUser?.warehouseId) ||
      (userRoles.includes(Roles.WAREHOUSE_OWNER) && !selectedWarehouse) ||
      (userRoles.includes(Roles.OWNER) && !currentUser?.companies?.length)
    ) {
      return [];
    }

    let navigationItems = SIDEBAR_ITEMS;

    if (selectedCompany) {
      navigationItems = [
        {
          label: translate(intl, 'NAVIGATION.GROUP_COMPANY'),
          roles: [Roles.ADMIN, Roles.OWNER],
          items: [
            {
              label: translate(intl, 'NAVIGATION.SIDEBAR_WAREHOUSES'),
              to: routes.warehouses.companyList,
              icon: faWarehouse,
              roles: [Roles.ADMIN, Roles.OWNER],
            },
            {
              label: translate(intl, 'NAVIGATION.SIDEBAR_PROJECTS'),
              to: routes.projects.list,
              icon: faDiagramProject,
              roles: [Roles.OWNER],
            },
            {
              label: translate(intl, 'NAVIGATION.SIDEBAR_BALANCE_HISTORIES'),
              to: routes.balanceHistory,
              icon: faMoneyBill,
              roles: [Roles.ADMIN, Roles.OWNER],
            },
            {
              label: translate(intl, 'NAVIGATION.SIDEBAR_MY_COMPANY'),
              to: routes.companies.my,
              icon: faBuilding,
              roles: [Roles.OWNER],
            },
          ],
        },
        ...navigationItems,
      ];
    }

    if (selectedProject) {
      navigationItems = [
        {
          label: translate(intl, 'NAVIGATION.GROUP_PROJECT'),
          roles: [Roles.ADMIN, Roles.OWNER],
          items: [
            {
              label: translate(intl, 'NAVIGATION.SIDEBAR_PRODUCTS'),
              to: routes.products.list,
              icon: faShop as IconProp,
              roles: [Roles.ADMIN, Roles.OWNER],
            },
            {
              label: translate(intl, 'NAVIGATION.SIDEBAR_ORDERS'),
              to: routes.orders.list,
              icon: faBagShopping as IconProp,
              roles: [Roles.ADMIN, Roles.OWNER],
            },
            {
              label: translate(intl, 'NAVIGATION.SIDEBAR_ARRIVALS'),
              to: routes.billOfLadings.list,
              icon: faTruckRampBox as IconProp,
              roles: [Roles.ADMIN, Roles.OWNER],
            },
            {
              label: translate(intl, 'NAVIGATION.SIDEBAR_REFUNDED_ITEMS'),
              to: routes.billOfLadings.returnedItems,
              icon: faShoppingCart as IconProp,
              roles: [Roles.ADMIN, Roles.OWNER],
            },
          ],
        },
        ...navigationItems,
      ];
    }

    return navigationItems
      .filter(
        (sidebarItem) =>
          !!userRoles.find((role) => sidebarItem.roles.includes(role)),
      )
      .map((sidebarItemGroup) => ({
        label: sidebarItemGroup.label,
        items: sidebarItemGroup.items.filter(
          (sidebarItem) =>
            !!userRoles.find((role) => sidebarItem.roles.includes(role)),
        ),
      }));
  };

  const onProjectSelectSuccess = () => {
    setIsProjectModalOpen(false);

    if (routeLocation.pathname === routes.admin) {
      navigate(routes.products.list);
    }
  };

  const getCurrentProject = () => (
    <CurrentAccount
      title={translate(intl, 'PROJECTS.NO_PROJECT_SELECTED')}
      subTitle={
        company
          ? `${translate(intl, 'PROJECTS.SELECTED_PROJECT')}:`
          : translate(intl, 'PROJECTS.CLICK_TO_SELECT')
      }
      companyName={project?.name}
      onClick={() => !isProjectModalOpen && setIsProjectModalOpen(true)}
    >
      <Modal
        onClose={() => setIsProjectModalOpen(false)}
        isOpen={isProjectModalOpen}
        title={translate(intl, 'PROJECTS.SELECT_PROJECT_TITLE')}
        className={styles.companySelectModal}
      >
        {isProjectModalOpen && (
          <ProjectSelectForm
            onSuccessSelect={onProjectSelectSuccess}
            projects={company?.projects ?? []}
          />
        )}
      </Modal>
    </CurrentAccount>
  );

  const getCurrentCompany = () => {
    if (
      userRoles.includes(Roles.STOCKMAN) ||
      userRoles.includes(Roles.WAREHOUSE_OWNER)
    ) {
      return null;
    }

    return (
      <div className={styles.currentCompanyContainer}>
        <CurrentAccount
          logo={companyLogo?.thumbLocation ?? ''}
          title={translate(intl, 'COMPANIES.NO_COMPANY_SELECTED')}
          subTitle={
            company
              ? `${translate(intl, 'COMPANIES.SELECTED_COMPANY')}:`
              : translate(intl, 'COMPANIES.CLICK_TO_SELECT')
          }
          companyName={company?.name}
          onClick={() => !isModalOpen && setIsModalOpen(true)}
        >
          <Modal
            onClose={() => setIsModalOpen(false)}
            isOpen={isModalOpen}
            title={translate(intl, 'COMPANIES.SELECT_COMPANY_TITLE')}
            className={styles.companySelectModal}
          >
            {isModalOpen && (
              <CompanySelectForm
                onSuccessSelect={() => setIsModalOpen(false)}
              />
            )}
          </Modal>
        </CurrentAccount>
        {company && !!company.projects.length && getCurrentProject()}
      </div>
    );
  };

  const getCurrentWarehouse = () => {
    if (!userRoles.includes(Roles.WAREHOUSE_OWNER)) {
      return null;
    }

    return (
      <CurrentAccount
        title={translate(intl, 'WAREHOUSE.NO_WAREHOUSE_SELECTED')}
        subTitle={
          warehouse
            ? `${translate(intl, 'WAREHOUSE.SELECTED_WAREHOUSE')}:`
            : translate(intl, 'WAREHOUSE.CLICK_TO_SELECT')
        }
        companyName={warehouse?.name}
        onClick={() => !isWarehouseModalOpen && setIsWarehouseModalOpen(true)}
      >
        <Modal
          onClose={() => setIsWarehouseModalOpen(false)}
          isOpen={isWarehouseModalOpen}
          title={translate(intl, 'WAREHOUSE.SELECT_WAREHOUSE_TITLE')}
          className={styles.companySelectModal}
        >
          {isWarehouseModalOpen && (
            <WarehouseSelectForm
              warehouseOptions={currentUser?.ownedWarehouses ?? []}
              onSuccessSelect={() => setIsWarehouseModalOpen(false)}
            />
          )}
        </Modal>
      </CurrentAccount>
    );
  };

  if (!routeLocation.pathname.includes(routes.admin)) {
    return (
      <>
        <div className={styles.publicContainer}>{children}</div>
      </>
    );
  }

  if (routeLocation.pathname.includes(routes.login)) {
    return <>{children}</>;
  }

  return (
    <>
      <div className={styles.container}>
        {width && width >= MOBILE_BREAK_POINT && (
          <Sidebar
            navigationGroups={getNavigationGroups()}
            currentAccount={
              userRoles.includes(Roles.WAREHOUSE_OWNER)
                ? getCurrentWarehouse()
                : getCurrentCompany()
            }
          />
        )}
        <div
          className={cx(styles.rightSection, {
            [styles.noScroll]: isMobileMenuOpen,
          })}
        >
          <Navigation
            onDrawerClick={() => setIsMobileMenuOpen((prev) => !prev)}
            isMobileMenuOpen={isMobileMenuOpen}
          />
          <div className={styles.content}>{children}</div>
        </div>
      </div>
      {isMobileMenuOpen && width && width < MOBILE_BREAK_POINT && (
        <div className={styles.mobileDrawer}>
          {getNavigationGroups().map((navigationGroup) => (
            <div className={styles.navigationGroup} key={navigationGroup.label}>
              <div className={styles.groupName}>{navigationGroup.label}</div>
              {navigationGroup.items.map((item) => (
                <NavLink
                  key={item.label}
                  to={item.to}
                  className={({ isActive }) =>
                    cx(styles.navigationItem, {
                      [styles.activeSubItem]: isActive,
                    })
                  }
                >
                  {item.label}
                </NavLink>
              ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLocale: state.auth.selectedLocale,
  languages: state.language.languages,
  userRoles: state.auth.userRoles,
  selectedCompany: state.company.selectedCompany,
  selectedProject: state.project.selectedProject,
  selectedWarehouse: state.warehouse.selectedWarehouse,
  companyOptions: state.company.companyOptions,
  companyLogo: state.company.companyLogo,
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onCompanyOptionsFetch: (intl: IntlShape) =>
    dispatch(companyService.fetchCompanyOptions(intl)),
  onCompanySelect: (
    selectCompanyId: number | null,
    companyLogo: Asset | null,
  ) => dispatch(setSelectedCompany(selectCompanyId, companyLogo)),
  onProjectSelect: (selectedProjectId: number | null) =>
    dispatch(setSelectedProject(selectedProjectId)),
  onWarehouseSelect: (selectedWarehouseId: number | null) =>
    dispatch(setSelectedWarehouse(selectedWarehouseId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
