import * as actionTypes from './actionTypes';
import { ProjectActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Project } from '../../domain/Project';
import { ListResults } from '../../common/List/List';
import { UserSetting, UserSettingType } from '../../domain/UserSetting';

export type ProjectStateType = {
  projectsList: ListResults<Project> | null;
  projectsLoading: boolean;
  projectsError: HttpError;
  projectsListUpdateNeeded: boolean;
  projectCreateLoading: boolean;
  projectCreateError: HttpError;
  projectCreateSuccess: boolean;
  createdProject: Project | null;
  projectUpdateLoading: boolean;
  projectUpdateError: HttpError;
  projectUpdateSuccess: boolean;
  project: Project | null;
  projectLoading: boolean;
  projectError: HttpError;
  selectedProject: number | null;
  projectStatusLoading: boolean;
  projectStatusSuccess: boolean;
  projectStatusError: HttpError;
  projectDeleteLoading: boolean;
  projectDeleteError: HttpError;
};

export type ProjectActionType = ProjectStateType & {
  type: ProjectActionTypes;
  settings: UserSetting[];
};

export const initialState: ProjectStateType = {
  projectsList: null,
  projectsLoading: true,
  projectsError: null,
  projectsListUpdateNeeded: false,
  projectCreateLoading: false,
  projectCreateError: null,
  projectCreateSuccess: false,
  createdProject: null,
  projectUpdateLoading: false,
  projectUpdateError: null,
  projectUpdateSuccess: false,
  project: null,
  projectLoading: false,
  projectError: null,
  selectedProject: null,
  projectStatusError: null,
  projectStatusSuccess: false,
  projectStatusLoading: false,
  projectDeleteLoading: false,
  projectDeleteError: null,
};

const fetchProjectsStart = (state: ProjectStateType): ProjectStateType => ({
  ...state,
  projectsLoading: true,
});

const fetchProjectsSuccess = (
  state: ProjectStateType,
  action: ProjectActionType,
): ProjectStateType => ({
  ...state,
  projectsList: action.projectsList,
  projectsLoading: false,
  projectsError: null,
  projectsListUpdateNeeded: false,
});

const fetchProjectsFail = (
  state: ProjectStateType,
  action: ProjectActionType,
): ProjectStateType => ({
  ...state,
  projectsError: action.projectsError,
  projectsLoading: false,
});

const fetchProjectStart = (state: ProjectStateType): ProjectStateType => ({
  ...state,
  projectLoading: true,
  projectUpdateSuccess: false,
  projectCreateSuccess: false,
  projectCreateError: null,
  projectUpdateError: null,
});

const fetchProjectSuccess = (
  state: ProjectStateType,
  action: ProjectActionType,
): ProjectStateType => ({
  ...state,
  project: action.project,
  projectLoading: false,
  projectError: null,
});

const fetchProjectFail = (
  state: ProjectStateType,
  action: ProjectActionType,
): ProjectStateType => ({
  ...state,
  projectError: action.projectError,
  projectLoading: false,
});

const createProjectStart = (state: ProjectStateType): ProjectStateType => ({
  ...state,
  projectCreateLoading: true,
});

const createProjectSuccess = (
  state: ProjectStateType,
  action: ProjectActionType,
): ProjectStateType => ({
  ...state,
  projectCreateLoading: false,
  projectCreateError: null,
  projectCreateSuccess: true,
  createdProject: action.createdProject,
  projectsListUpdateNeeded: true,
});

const createProjectFail = (
  state: ProjectStateType,
  action: ProjectActionType,
): ProjectStateType => ({
  ...state,
  projectCreateLoading: false,
  projectCreateError: action.projectCreateError,
});

const updateProjectStart = (state: ProjectStateType): ProjectStateType => ({
  ...state,
  projectUpdateLoading: true,
});

const updateProjectSuccess = (state: ProjectStateType): ProjectStateType => ({
  ...state,
  projectUpdateLoading: false,
  projectUpdateError: null,
  projectUpdateSuccess: true,
  projectsListUpdateNeeded: true,
});

const updateProjectFail = (
  state: ProjectStateType,
  action: ProjectActionType,
): ProjectStateType => ({
  ...state,
  projectUpdateLoading: false,
  projectUpdateError: action.projectUpdateError,
});

const setSelectedProject = (
  state: ProjectStateType,
  action: ProjectActionType,
): ProjectStateType => ({
  ...state,
  selectedProject: action.selectedProject,
});

const toggleProjectStatusStart = (
  state: ProjectStateType,
): ProjectStateType => ({
  ...state,
  projectStatusLoading: true,
});

const toggleProjectStatusSuccess = (
  state: ProjectStateType,
): ProjectStateType => ({
  ...state,
  projectStatusLoading: false,
  projectStatusError: null,
  projectStatusSuccess: true,
  projectsListUpdateNeeded: true,
});

const toggleProjectStatusFail = (
  state: ProjectStateType,
  action: ProjectActionType,
): ProjectStateType => ({
  ...state,
  projectStatusLoading: false,
  projectStatusError: action.projectStatusError,
});

const deleteProjectStart = (state: ProjectStateType): ProjectStateType => ({
  ...state,
  projectDeleteLoading: true,
});

const deleteProjectSuccess = (state: ProjectStateType): ProjectStateType => ({
  ...state,
  projectDeleteLoading: false,
  projectDeleteError: null,
  projectsListUpdateNeeded: true,
});

const deleteProjectFail = (
  state: ProjectStateType,
  action: ProjectActionType,
): ProjectStateType => ({
  ...state,
  projectDeleteLoading: false,
  projectDeleteError: action.projectDeleteError,
});

const setSelectedProjectSettings = (
  state: ProjectStateType,
  action: ProjectActionType,
): ProjectStateType => ({
  ...state,
  selectedProject: Number(
    action.settings.find((setting) => setting.type === UserSettingType.PROJECT)
      ?.value,
  ),
});

const resetProjectStore = (state: ProjectStateType): ProjectStateType => {
  return {
    ...initialState,
    selectedProject: state.selectedProject,
  };
};

const resetWholeProjectStore = (): ProjectStateType => {
  return {
    ...initialState,
    selectedProject: null,
  };
};

const logout = (): ProjectStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: ProjectActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_PROJECTS_START:
      return fetchProjectsStart(state);
    case actionTypes.FETCH_PROJECTS_SUCCESS:
      return fetchProjectsSuccess(state, action);
    case actionTypes.FETCH_PROJECTS_FAIL:
      return fetchProjectsFail(state, action);
    case actionTypes.FETCH_PROJECT_START:
      return fetchProjectStart(state);
    case actionTypes.FETCH_PROJECT_SUCCESS:
      return fetchProjectSuccess(state, action);
    case actionTypes.FETCH_PROJECT_FAIL:
      return fetchProjectFail(state, action);
    case actionTypes.CREATE_PROJECT_START:
      return createProjectStart(state);
    case actionTypes.CREATE_PROJECT_SUCCESS:
      return createProjectSuccess(state, action);
    case actionTypes.CREATE_PROJECT_FAIL:
      return createProjectFail(state, action);
    case actionTypes.UPDATE_PROJECT_START:
      return updateProjectStart(state);
    case actionTypes.UPDATE_PROJECT_SUCCESS:
      return updateProjectSuccess(state);
    case actionTypes.UPDATE_PROJECT_FAIL:
      return updateProjectFail(state, action);
    case actionTypes.SET_SELECTED_PROJECT:
      return setSelectedProject(state, action);
    case actionTypes.TOGGLE_PROJECT_STATUS_START:
      return toggleProjectStatusStart(state);
    case actionTypes.TOGGLE_PROJECT_STATUS_SUCCESS:
      return toggleProjectStatusSuccess(state);
    case actionTypes.TOGGLE_PROJECT_STATUS_FAIL:
      return toggleProjectStatusFail(state, action);
    case actionTypes.DELETE_PROJECT_START:
      return deleteProjectStart(state);
    case actionTypes.DELETE_PROJECT_SUCCESS:
      return deleteProjectSuccess(state);
    case actionTypes.DELETE_PROJECT_FAIL:
      return deleteProjectFail(state, action);
    case actionTypes.SET_PROJECT_SELECTED_SETTINGS:
      return setSelectedProjectSettings(state, action);
    case actionTypes.RESET_PROJECT_STORE:
      return resetProjectStore(state);
    case actionTypes.RESET_WHOLE_PROJECT_STORE:
      return resetWholeProjectStore();
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;
