import * as actionTypes from './actionTypes';
import { WarehouseCostActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { WarehouseCost } from '../../domain/WarehouseCost';

export type WarehouseCostStateType = {
  setWarehouseCostLoading: boolean;
  setWarehouseCostSuccess: boolean;
  setWarehouseCostError: HttpError;
  warehouseCost: WarehouseCost | null;
};

export type WarehouseCostActionType = WarehouseCostStateType & {
  type: WarehouseCostActionTypes;
};

export const initialState: WarehouseCostStateType = {
  setWarehouseCostError: null,
  setWarehouseCostLoading: false,
  setWarehouseCostSuccess: false,
  warehouseCost: null,
};

const setWarehouseCostStart = (
  state: WarehouseCostStateType,
): WarehouseCostStateType => ({
  ...state,
  setWarehouseCostLoading: true,
  setWarehouseCostSuccess: false,
});

const setWarehouseCostSuccess = (
  state: WarehouseCostStateType,
  action: WarehouseCostActionType,
): WarehouseCostStateType => ({
  ...state,
  setWarehouseCostLoading: false,
  setWarehouseCostSuccess: true,
  setWarehouseCostError: null,
  warehouseCost: action.warehouseCost,
});

const setWarehouseCostFail = (
  state: WarehouseCostStateType,
  action: WarehouseCostActionType,
): WarehouseCostStateType => ({
  ...state,
  setWarehouseCostLoading: false,
  setWarehouseCostError: action.setWarehouseCostError,
});

const resetWarehouseStore = (): WarehouseCostStateType => ({
  ...initialState,
});

const logout = (): WarehouseCostStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: WarehouseCostActionType) => {
  switch (action.type) {
    case actionTypes.SET_WAREHOUSE_COST_START:
      return setWarehouseCostStart(state);
    case actionTypes.SET_WAREHOUSE_COST_SUCCESS:
      return setWarehouseCostSuccess(state, action);
    case actionTypes.SET_WAREHOUSE_COST_FAIL:
      return setWarehouseCostFail(state, action);
    case actionTypes.RESET_WAREHOUSE_COST_STORE:
      return resetWarehouseStore();
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;
