import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Project } from '../../domain/Project';
import { ListResults } from '../../common/List/List';
import { UserSetting } from '../../domain/UserSetting';

export const fetchProjectsStart = () => ({
  type: actionTypes.FETCH_PROJECTS_START,
});

export const fetchProjectsSuccess = (projectsList: ListResults<Project>) => ({
  type: actionTypes.FETCH_PROJECTS_SUCCESS,
  projectsList,
});

export const fetchProjectsFail = (projectsError: HttpError) => ({
  type: actionTypes.FETCH_PROJECTS_FAIL,
  projectsError,
});

export const fetchProjectStart = () => ({
  type: actionTypes.FETCH_PROJECT_START,
});

export const fetchProjectSuccess = (project: Project) => ({
  type: actionTypes.FETCH_PROJECT_SUCCESS,
  project,
});

export const fetchProjectFail = (projectError: HttpError) => ({
  type: actionTypes.FETCH_PROJECT_FAIL,
  projectError,
});

export const createProjectStart = () => ({
  type: actionTypes.CREATE_PROJECT_START,
});

export const createProjectSuccess = (createdProject: Project) => ({
  type: actionTypes.CREATE_PROJECT_SUCCESS,
  createdProject,
});

export const createProjectFail = (projectCreateError: HttpError) => ({
  type: actionTypes.CREATE_PROJECT_FAIL,
  projectCreateError,
});

export const updateProjectStart = () => ({
  type: actionTypes.UPDATE_PROJECT_START,
});

export const updateProjectSuccess = () => ({
  type: actionTypes.UPDATE_PROJECT_SUCCESS,
});

export const updateProjectFail = (projectUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_PROJECT_FAIL,
  projectUpdateError,
});

export const setSelectedProject = (selectedProject: number | null) => {
  return {
    type: actionTypes.SET_SELECTED_PROJECT,
    selectedProject,
  };
};

export const toggleProjectStatusStart = () => ({
  type: actionTypes.TOGGLE_PROJECT_STATUS_START,
});

export const toggleProjectStatusSuccess = () => ({
  type: actionTypes.TOGGLE_PROJECT_STATUS_SUCCESS,
});

export const toggleProjectStatusFail = (projectStatusError: HttpError) => ({
  type: actionTypes.TOGGLE_PROJECT_STATUS_FAIL,
  projectStatusError,
});

export const deleteProjectStart = () => ({
  type: actionTypes.DELETE_PROJECT_START,
});

export const deleteProjectSuccess = () => ({
  type: actionTypes.DELETE_PROJECT_SUCCESS,
});

export const deleteProjectFail = (projectDeleteError: HttpError) => ({
  type: actionTypes.DELETE_PROJECT_FAIL,
  projectDeleteError,
});

export const resetProjectStore = () => ({
  type: actionTypes.RESET_PROJECT_STORE,
});

export const resetWholeProjectStore = () => ({
  type: actionTypes.RESET_WHOLE_PROJECT_STORE,
});

export const setSelectedProjectSettings = (settings: UserSetting[]) => {
  return {
    type: actionTypes.SET_PROJECT_SELECTED_SETTINGS,
    settings,
  };
};
