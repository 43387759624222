import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import styles from './PaymentForm.module.scss';
import { StoreState } from '../../../config/StoreProvider/StoreProvider';
import Alert from '../../../common/Alert/Alert';
import TextField from '../../../common/TextField/TextField';
import { useForm } from '../../../hooks/useForm/useForm';
import Button from '../../../common/Button/Button';
import { HttpError } from '../../../config/Axios/axios-instance';
import Form from '../../../common/Form/Form';
import * as paymentService from '../../../store/payment/service';
import FormCard from '../../../common/FormCard/FormCard';
import { IntlShape, useIntl } from 'react-intl';
import { translate } from '../../../utility/messageTranslator/translate';
import { PaymentCreateRequest } from '../../../store/payment/service';
import { resetPaymentStore } from '../../../store/payment/actions';
import { getGlobalError } from '../../../utility/error/httpErrorParser';

type Props = {
  onCreate: (inputs: PaymentCreateRequest, intl: IntlShape) => void;
  isLoading?: boolean;
  error: HttpError;
  redirectUrl: string | null;
  onResetPaymentStore: () => void;
};

type FormInputs = {
  amount: number;
};

export const PaymentForm = ({
  onCreate,
  isLoading,
  error,
  redirectUrl,
  onResetPaymentStore,
}: Props) => {
  const intl = useIntl();

  useEffect(() => {
    return () => onResetPaymentStore();
  }, []);

  useEffect(() => {
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  }, [redirectUrl]);

  const INPUTS = [
    {
      name: 'amount',
      label: translate(intl, 'PAYMENT.FORM_AMOUNT'),
      type: 'currency',
      validation: [
        {
          type: 'required',
        },
        {
          type: 'min',
          parameter: 1,
        },
      ],
      value: 0,
    },
  ];

  const handleSubmit = (submitInputs: FormInputs) => {
    onCreate?.(
      {
        amount: submitInputs.amount,
      },
      intl,
    );
  };

  const {
    inputs,
    onSubmit,
    onInputChange,
    onLoseInputFocus,
    onCheckboxChange,
  } = useForm<any>(INPUTS, handleSubmit);

  const globalError = getGlobalError(error, intl);

  return (
    <div className={styles.paymentForm}>
      <Form className={styles.form} error={globalError} onSubmit={onSubmit}>
        <FormCard>
          {globalError && (
            <Alert variant="danger" capitalize={false}>
              {globalError.toString()}
            </Alert>
          )}
          {inputs.map((input) => (
            <TextField
              key={input.name}
              onChange={onInputChange}
              value={input.value?.toString()}
              label={input.label ?? ''}
              errors={input.validationErrors ?? []}
              onBlur={onLoseInputFocus}
              onCheckboxChange={onCheckboxChange}
              name={input.name}
              type={input.type}
              asset={input.asset}
            />
          ))}
          <Button
            isLoadingButton
            onClick={onSubmit}
            buttonVariant="contained"
            color="primary"
            type="submit"
            isLoading={isLoading}
          >
            {translate(intl, 'PAYMENT.TOP_UP')}
          </Button>
        </FormCard>
      </Form>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  error: state.payment.paymentCreateError,
  isLoading: state.payment.paymentCreateLoading,
  redirectUrl: state.payment.redirectUrl,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onCreate: (inputs: PaymentCreateRequest, intl: IntlShape) =>
    dispatch(paymentService.createPayment(inputs, intl)),
  onResetPaymentStore: () => dispatch(resetPaymentStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);
