export const FETCH_RETURNED_ITEMS_START = 'FETCH_RETURNED_ITEMS_START';
export const FETCH_RETURNED_ITEMS_SUCCESS = 'FETCH_RETURNED_ITEMS_SUCCESS';
export const FETCH_RETURNED_ITEMS_FAIL = 'FETCH_RETURNED_ITEMS_FAIL';

export const SET_REFUND_ITEM_DATA_START = 'SET_REFUND_ITEM_DATA_START';
export const SET_REFUND_ITEM_DATA_SUCCESS = 'SET_REFUND_ITEM_DATA_SUCCESS';
export const SET_REFUND_ITEM_DATA_FAIL = 'SET_REFUND_ITEM_DATA_FAIL';

export const CONFIRM_REFUND_ITEM_START = 'CONFIRM_REFUND_ITEM_START';
export const CONFIRM_REFUND_ITEM_SUCCESS = 'CONFIRM_REFUND_ITEM_SUCCESS';
export const CONFIRM_REFUND_ITEM_FAIL = 'CONFIRM_REFUND_ITEM_FAIL';

export const DESTROY_REFUND_ITEMS_START = 'DESTROY_REFUND_ITEMS_START';
export const DESTROY_REFUND_ITEMS_SUCCESS = 'DESTROY_REFUND_ITEMS_SUCCESS';
export const DESTROY_REFUND_ITEMS_FAIL = 'DESTROY_REFUND_ITEMS_FAIL';

export const SEND_REFUND_ITEM_START = 'SEND_REFUND_ITEM_START';
export const SEND_REFUND_ITEM_SUCCESS = 'SEND_REFUND_ITEM_SUCCESS';
export const SEND_REFUND_ITEM_FAIL = 'SEND_REFUND_ITEM_FAIL';

export const RETURN_REFUND_ITEM_START = 'RETURN_REFUND_ITEM_START';
export const RETURN_REFUND_ITEM_SUCCESS = 'RETURN_REFUND_ITEM_SUCCESS';
export const RETURN_REFUND_ITEM_FAIL = 'RETURN_REFUND_ITEM_FAIL';

export const RESET_REFUND_ITEMS_STORE = 'RESET_REFUND_ITEMS_STORE';

export const LOGOUT = 'LOGOUT';

export type RefundItemActionTypes =
  | typeof FETCH_RETURNED_ITEMS_START
  | typeof FETCH_RETURNED_ITEMS_SUCCESS
  | typeof FETCH_RETURNED_ITEMS_FAIL
  | typeof SET_REFUND_ITEM_DATA_START
  | typeof SET_REFUND_ITEM_DATA_SUCCESS
  | typeof SET_REFUND_ITEM_DATA_FAIL
  | typeof CONFIRM_REFUND_ITEM_START
  | typeof CONFIRM_REFUND_ITEM_SUCCESS
  | typeof CONFIRM_REFUND_ITEM_FAIL
  | typeof DESTROY_REFUND_ITEMS_START
  | typeof DESTROY_REFUND_ITEMS_SUCCESS
  | typeof DESTROY_REFUND_ITEMS_FAIL
  | typeof SEND_REFUND_ITEM_START
  | typeof SEND_REFUND_ITEM_SUCCESS
  | typeof SEND_REFUND_ITEM_FAIL
  | typeof RETURN_REFUND_ITEM_START
  | typeof RETURN_REFUND_ITEM_SUCCESS
  | typeof RETURN_REFUND_ITEM_FAIL
  | typeof RESET_REFUND_ITEMS_STORE
  | typeof LOGOUT;
