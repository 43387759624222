import React, { useMemo, useState } from 'react';
import styles from './Navigation.module.scss';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { IconButton } from '@mui/material';
import {
  faBars,
  faSignOutAlt,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as authService from '../../store/auth/service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { Roles } from '../../domain/Role';
import { translate } from '../../utility/messageTranslator/translate';
import { Company } from '../../domain/Company';
import { useIntl } from 'react-intl';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import PaymentForm from '../../component/Payment/PaymentForm/PaymentForm';

export type Props = {
  onLogout: () => void;
  onDrawerClick: () => void;
  isMobileMenuOpen: boolean;
  userRoles: Roles[];
  selectedCompany: number | null;
  companies: Company[];
};

const MOBILE_BREAK_POINT = 900;

export const Navigation = ({
  onLogout,
  onDrawerClick,
  isMobileMenuOpen,
  userRoles,
  selectedCompany,
  companies,
}: Props) => {
  const [isCreditPackagesModalOpen, setIsCreditPackagesModalOpen] =
    useState<boolean>(false);
  const { width } = useWindowSize();
  const intl = useIntl();

  const company = useMemo(
    () => companies?.find((company) => company.id === selectedCompany),
    [selectedCompany, companies],
  );

  return (
    <>
      <header className={styles.navigationContainer}>
        <div className={styles.iconItems}>
          {width && width < MOBILE_BREAK_POINT && (
            <IconButton onClick={() => onDrawerClick()}>
              <FontAwesomeIcon
                icon={(isMobileMenuOpen ? faTimes : faBars) as IconProp}
                fixedWidth
                size="sm"
              />
            </IconButton>
          )}
        </div>

        <div className={styles.rightButtonContainer}>
          {userRoles.includes(Roles.OWNER) && !!company && (
            <div className={styles.balanceInfo}>
              <div>{translate(intl, 'NAVIGATION.BALANCE')}</div>
              <div className={styles.balance}>{company?.balance} &euro;</div>
              <Button
                className={styles.topUpButton}
                onClick={() => setIsCreditPackagesModalOpen(true)}
                buttonVariant="contained"
                color="primary"
                type="submit"
              >
                {translate(intl, 'BALANCE.TOP_UP')}
              </Button>
            </div>
          )}
          <div className={styles.iconItems}>
            <IconButton onClick={() => onLogout()}>
              <FontAwesomeIcon
                icon={faSignOutAlt as IconProp}
                fixedWidth
                size="sm"
              />
            </IconButton>
          </div>
        </div>
      </header>
      <Modal
        title={translate(intl, 'PAYMENT.FORM_TITLE')}
        isOpen={isCreditPackagesModalOpen}
        onClose={() => setIsCreditPackagesModalOpen(false)}
        isLoading={false}
        isFooterHidden
      >
        {isCreditPackagesModalOpen && <PaymentForm />}
      </Modal>
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  userRoles: state.auth.userRoles,
  selectedCompany: state.company.selectedCompany,
  companies: state.company.companyOptions,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onLogout: () => dispatch(authService.logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
