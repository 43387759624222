import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ProductQuantity } from '../../domain/ProductQuantity';
import { ListResults } from '../../common/List/List';
import { ProductQuantityActionTypes } from './actionTypes';

export type ProductQuantityStateType = {
  productQuantitiesList: ListResults<ProductQuantity> | null;
  productQuantitiesLoading: boolean;
  productQuantitiesError: HttpError;
  productQuantitiesListUpdateNeeded: boolean;
  productQuantityCreateLoading: boolean;
  productQuantityCreateError: HttpError;
  productQuantityCreateSuccess: boolean;
  createdProductQuantity: ProductQuantity | null;
  updatedProductQuantity: ProductQuantity | null;
  productQuantityUpdateLoading: boolean;
  productQuantityUpdateError: HttpError;
  productQuantityUpdateSuccess: boolean;
  productQuantitySetLoading: boolean;
  productQuantitySetError: HttpError;
  productQuantitySetSuccess: boolean;
  productQuantityDeleteLoading: boolean;
  productQuantityDeleteError: HttpError;
};

export type ProductQuantityActionType = ProductQuantityStateType & {
  type: ProductQuantityActionTypes;
};

export const initialState: ProductQuantityStateType = {
  productQuantitiesError: null,
  productQuantitiesList: null,
  productQuantitiesLoading: false,
  productQuantitiesListUpdateNeeded: false,
  productQuantityCreateLoading: false,
  productQuantityCreateError: null,
  productQuantityCreateSuccess: false,
  createdProductQuantity: null,
  productQuantityUpdateLoading: false,
  productQuantityUpdateError: null,
  productQuantityUpdateSuccess: false,
  productQuantitySetLoading: false,
  productQuantitySetError: null,
  productQuantitySetSuccess: false,
  productQuantityDeleteLoading: false,
  productQuantityDeleteError: null,
  updatedProductQuantity: null,
};

const fetchProductQuantitiesStart = (
  state: ProductQuantityStateType,
): ProductQuantityStateType => ({
  ...state,
  productQuantitiesLoading: true,
  productQuantityUpdateSuccess: false,
  productQuantityCreateSuccess: false,
});

const fetchProductQuantitiesSuccess = (
  state: ProductQuantityStateType,
  action: ProductQuantityActionType,
): ProductQuantityStateType => ({
  ...state,
  productQuantitiesList: action.productQuantitiesList,
  productQuantitiesLoading: false,
  productQuantitiesError: null,
  productQuantitiesListUpdateNeeded: false,
});

const fetchProductQuantitiesFail = (
  state: ProductQuantityStateType,
  action: ProductQuantityActionType,
): ProductQuantityStateType => ({
  ...state,
  productQuantitiesError: action.productQuantitiesError,
  productQuantitiesLoading: false,
});

const createProductQuantityStart = (
  state: ProductQuantityStateType,
): ProductQuantityStateType => ({
  ...state,
  productQuantityCreateLoading: true,
});

const createProductQuantitySuccess = (
  state: ProductQuantityStateType,
  action: ProductQuantityActionType,
): ProductQuantityStateType => ({
  ...state,
  productQuantityCreateLoading: false,
  productQuantityCreateError: null,
  productQuantityCreateSuccess: true,
  productQuantitiesListUpdateNeeded: true,
  createdProductQuantity: action.createdProductQuantity,
});

const createProductQuantityFail = (
  state: ProductQuantityStateType,
  action: ProductQuantityActionType,
): ProductQuantityStateType => ({
  ...state,
  productQuantityCreateLoading: false,
  productQuantityCreateError: action.productQuantityCreateError,
});

const updateProductQuantityStart = (
  state: ProductQuantityStateType,
): ProductQuantityStateType => ({
  ...state,
  productQuantityUpdateLoading: true,
});

const updateProductQuantitySuccess = (
  state: ProductQuantityStateType,
): ProductQuantityStateType => ({
  ...state,
  productQuantityUpdateLoading: false,
  productQuantityUpdateError: null,
  productQuantityUpdateSuccess: true,
  productQuantitiesListUpdateNeeded: true,
});

const updateProductQuantityFail = (
  state: ProductQuantityStateType,
  action: ProductQuantityActionType,
): ProductQuantityStateType => ({
  ...state,
  productQuantityUpdateLoading: false,
  productQuantityUpdateError: action.productQuantityUpdateError,
});

const setProductQuantityStart = (
  state: ProductQuantityStateType,
): ProductQuantityStateType => ({
  ...state,
  productQuantitySetLoading: true,
});

const setProductQuantitySuccess = (
  state: ProductQuantityStateType,
  action: ProductQuantityActionType,
): ProductQuantityStateType => ({
  ...state,
  productQuantitySetLoading: false,
  productQuantitySetError: null,
  productQuantitySetSuccess: true,
  productQuantitiesListUpdateNeeded: true,
  updatedProductQuantity: action.updatedProductQuantity,
});

const setProductQuantityFail = (
  state: ProductQuantityStateType,
  action: ProductQuantityActionType,
): ProductQuantityStateType => ({
  ...state,
  productQuantitySetLoading: false,
  productQuantitySetError: action.productQuantitySetError,
});

const deleteProductQuantityStart = (
  state: ProductQuantityStateType,
): ProductQuantityStateType => ({
  ...state,
  productQuantityDeleteLoading: true,
});

const deleteProductQuantitySuccess = (
  state: ProductQuantityStateType,
): ProductQuantityStateType => ({
  ...state,
  productQuantityDeleteLoading: false,
  productQuantityDeleteError: null,
  productQuantitiesListUpdateNeeded: true,
});

const deleteProductQuantityFail = (
  state: ProductQuantityStateType,
  action: ProductQuantityActionType,
): ProductQuantityStateType => ({
  ...state,
  productQuantityDeleteLoading: false,
  productQuantityDeleteError: action.productQuantityDeleteError,
});

const resetProductQuantityStore = (): ProductQuantityStateType => ({
  ...initialState,
});

const logout = (): ProductQuantityStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: ProductQuantityActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_PRODUCT_QUANTITIES_START:
      return fetchProductQuantitiesStart(state);
    case actionTypes.FETCH_PRODUCT_QUANTITIES_SUCCESS:
      return fetchProductQuantitiesSuccess(state, action);
    case actionTypes.FETCH_PRODUCT_QUANTITIES_FAIL:
      return fetchProductQuantitiesFail(state, action);
    case actionTypes.CREATE_PRODUCT_QUANTITY_START:
      return createProductQuantityStart(state);
    case actionTypes.CREATE_PRODUCT_QUANTITY_SUCCESS:
      return createProductQuantitySuccess(state, action);
    case actionTypes.CREATE_PRODUCT_QUANTITY_FAIL:
      return createProductQuantityFail(state, action);
    case actionTypes.UPDATE_PRODUCT_QUANTITY_START:
      return updateProductQuantityStart(state);
    case actionTypes.UPDATE_PRODUCT_QUANTITY_SUCCESS:
      return updateProductQuantitySuccess(state);
    case actionTypes.UPDATE_PRODUCT_QUANTITY_FAIL:
      return updateProductQuantityFail(state, action);
    case actionTypes.DELETE_PRODUCT_QUANTITY_START:
      return deleteProductQuantityStart(state);
    case actionTypes.DELETE_PRODUCT_QUANTITY_SUCCESS:
      return deleteProductQuantitySuccess(state);
    case actionTypes.DELETE_PRODUCT_QUANTITY_FAIL:
      return deleteProductQuantityFail(state, action);
    case actionTypes.SET_PRODUCT_QUANTITY_START:
      return setProductQuantityStart(state);
    case actionTypes.SET_PRODUCT_QUANTITY_SUCCESS:
      return setProductQuantitySuccess(state, action);
    case actionTypes.SET_PRODUCT_QUANTITY_FAIL:
      return setProductQuantityFail(state, action);
    case actionTypes.RESET_PRODUCT_QUANTITY_STORE:
      return resetProductQuantityStore();
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;
