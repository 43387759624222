import * as actionTypes from './actionTypes';
import { RefundItemActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { RefundItem } from '../../domain/RefundItem';
import { ListResults } from '../../common/List/List';

export type RefundItemStateType = {
  refundedItems: ListResults<RefundItem> | null;
  refundedItemsLoading: boolean;
  refundedItemsError: HttpError;
  refundedItemsListUpdateNeeded: boolean;
  refundItem: RefundItem | null;
  setRefundItemDataLoading: boolean;
  setRefundItemDataSuccess: boolean;
  setRefundItemDataError: HttpError;
  confirmRefundItemLoading: Record<string, boolean>;
  confirmRefundItemSuccess: Record<string, boolean>;
  confirmRefundItemError: HttpError;
  destroyRefundItemsLoading: boolean;
  destroyRefundItemsError: HttpError;
  sendRefundItemsLoading: boolean;
  sendRefundItemsError: HttpError;
  returnRefundItemLoading: boolean;
  returnRefundItemError: HttpError;
};

export type RefundItemActionType = RefundItemStateType & {
  type: RefundItemActionTypes;
  id: string;
};

export const initialState: RefundItemStateType = {
  refundedItems: null,
  refundedItemsError: null,
  refundedItemsLoading: false,
  refundItem: null,
  confirmRefundItemError: null,
  confirmRefundItemLoading: {},
  confirmRefundItemSuccess: {},
  setRefundItemDataError: null,
  setRefundItemDataLoading: false,
  setRefundItemDataSuccess: false,
  destroyRefundItemsError: null,
  refundedItemsListUpdateNeeded: false,
  destroyRefundItemsLoading: false,
  sendRefundItemsError: null,
  sendRefundItemsLoading: false,
  returnRefundItemError: null,
  returnRefundItemLoading: false,
};

const fetchProjectsStart = (
  state: RefundItemStateType,
): RefundItemStateType => ({
  ...state,
  refundedItemsLoading: true,
});

const fetchProjectsSuccess = (
  state: RefundItemStateType,
  action: RefundItemActionType,
): RefundItemStateType => ({
  ...state,
  refundedItems: action.refundedItems,
  refundedItemsLoading: false,
  refundedItemsError: null,
  refundedItemsListUpdateNeeded: false,
});

const fetchProjectsFail = (
  state: RefundItemStateType,
  action: RefundItemActionType,
): RefundItemStateType => ({
  ...state,
  refundedItemsError: action.refundedItemsError,
  refundedItemsLoading: false,
});

const setRefundItemDataStart = (
  state: RefundItemStateType,
  action: RefundItemActionType,
): RefundItemStateType => {
  const confirmedLoadings = state.confirmRefundItemLoading;
  const confirmedSuccesses = state.confirmRefundItemSuccess;

  confirmedLoadings[action.id] = false;
  confirmedSuccesses[action.id] = false;

  return {
    ...state,
    setRefundItemDataLoading: true,
    refundItem: null,
    setRefundItemDataSuccess: false,
    confirmRefundItemSuccess: confirmedSuccesses,
    confirmRefundItemLoading: confirmedLoadings,
  };
};

const setRefundItemDataSuccess = (
  state: RefundItemStateType,
  action: RefundItemActionType,
): RefundItemStateType => ({
  ...state,
  refundItem: action.refundItem,
  setRefundItemDataLoading: false,
  setRefundItemDataSuccess: true,
  setRefundItemDataError: null,
});

const setRefundItemDataFail = (
  state: RefundItemStateType,
  action: RefundItemActionType,
): RefundItemStateType => ({
  ...state,
  setRefundItemDataError: action.setRefundItemDataError,
  setRefundItemDataLoading: false,
});

const confirmRefundItemStart = (
  state: RefundItemStateType,
  action: RefundItemActionType,
): RefundItemStateType => {
  const confirmedLoadings = state.confirmRefundItemLoading;
  const confirmedSuccesses = state.confirmRefundItemSuccess;

  confirmedLoadings[action.id] = true;
  confirmedSuccesses[action.id] = false;

  return {
    ...state,
    confirmRefundItemLoading: confirmedLoadings,
    confirmRefundItemSuccess: confirmedSuccesses,
    setRefundItemDataSuccess: false,
    refundItem: null,
  };
};

const confirmRefundItemSuccess = (
  state: RefundItemStateType,
  action: RefundItemActionType,
): RefundItemStateType => {
  const confirmedLoadings = state.confirmRefundItemLoading;
  const confirmedSuccesses = state.confirmRefundItemSuccess;

  confirmedLoadings[action.id] = false;
  confirmedSuccesses[action.id] = true;

  return {
    ...state,
    refundItem: action.refundItem,
    confirmRefundItemLoading: confirmedLoadings,
    confirmRefundItemSuccess: confirmedSuccesses,
  };
};

const confirmRefundItemFail = (
  state: RefundItemStateType,
  action: RefundItemActionType,
): RefundItemStateType => {
  const confirmedLoadings = state.confirmRefundItemLoading;

  confirmedLoadings[action.id] = false;

  return {
    ...state,
    confirmRefundItemError: action.confirmRefundItemError,
    confirmRefundItemLoading: confirmedLoadings,
  };
};

const destroyRefundItemsStart = (
  state: RefundItemStateType,
): RefundItemStateType => ({
  ...state,
  destroyRefundItemsLoading: true,
});

const destroyRefundItemsSuccess = (
  state: RefundItemStateType,
): RefundItemStateType => ({
  ...state,
  destroyRefundItemsLoading: false,
  refundedItemsListUpdateNeeded: true,
  destroyRefundItemsError: null,
});

const destroyRefundItemsFail = (
  state: RefundItemStateType,
  action: RefundItemActionType,
): RefundItemStateType => ({
  ...state,
  destroyRefundItemsError: action.destroyRefundItemsError,
  destroyRefundItemsLoading: false,
});

const sendRefundItemStart = (
  state: RefundItemStateType,
): RefundItemStateType => ({
  ...state,
  sendRefundItemsLoading: true,
});

const sendRefundItemSuccess = (
  state: RefundItemStateType,
): RefundItemStateType => ({
  ...state,
  sendRefundItemsLoading: false,
  refundedItemsListUpdateNeeded: true,
  sendRefundItemsError: null,
});

const sendRefundItemFail = (
  state: RefundItemStateType,
  action: RefundItemActionType,
): RefundItemStateType => ({
  ...state,
  sendRefundItemsError: action.sendRefundItemsError,
  sendRefundItemsLoading: false,
});

const returnRefundItemStart = (
  state: RefundItemStateType,
): RefundItemStateType => ({
  ...state,
  returnRefundItemLoading: true,
});

const returnRefundItemSuccess = (
  state: RefundItemStateType,
): RefundItemStateType => ({
  ...state,
  returnRefundItemLoading: false,
  refundedItemsListUpdateNeeded: true,
  returnRefundItemError: null,
});

const returnRefundItemFail = (
  state: RefundItemStateType,
  action: RefundItemActionType,
): RefundItemStateType => ({
  ...state,
  returnRefundItemError: action.returnRefundItemError,
  returnRefundItemLoading: false,
});

const resetRefundItemStore = (): RefundItemStateType => {
  return {
    ...initialState,
    confirmRefundItemLoading: {},
    confirmRefundItemSuccess: {},
  };
};

const logout = (): RefundItemStateType => ({
  ...initialState,
  confirmRefundItemLoading: {},
  confirmRefundItemSuccess: {},
});

const reducer = (state = initialState, action: RefundItemActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_RETURNED_ITEMS_START:
      return fetchProjectsStart(state);
    case actionTypes.FETCH_RETURNED_ITEMS_SUCCESS:
      return fetchProjectsSuccess(state, action);
    case actionTypes.FETCH_RETURNED_ITEMS_FAIL:
      return fetchProjectsFail(state, action);
    case actionTypes.SET_REFUND_ITEM_DATA_START:
      return setRefundItemDataStart(state, action);
    case actionTypes.SET_REFUND_ITEM_DATA_SUCCESS:
      return setRefundItemDataSuccess(state, action);
    case actionTypes.SET_REFUND_ITEM_DATA_FAIL:
      return setRefundItemDataFail(state, action);
    case actionTypes.CONFIRM_REFUND_ITEM_START:
      return confirmRefundItemStart(state, action);
    case actionTypes.CONFIRM_REFUND_ITEM_SUCCESS:
      return confirmRefundItemSuccess(state, action);
    case actionTypes.CONFIRM_REFUND_ITEM_FAIL:
      return confirmRefundItemFail(state, action);
    case actionTypes.DESTROY_REFUND_ITEMS_START:
      return destroyRefundItemsStart(state);
    case actionTypes.DESTROY_REFUND_ITEMS_SUCCESS:
      return destroyRefundItemsSuccess(state);
    case actionTypes.DESTROY_REFUND_ITEMS_FAIL:
      return destroyRefundItemsFail(state, action);
    case actionTypes.SEND_REFUND_ITEM_START:
      return sendRefundItemStart(state);
    case actionTypes.SEND_REFUND_ITEM_SUCCESS:
      return sendRefundItemSuccess(state);
    case actionTypes.SEND_REFUND_ITEM_FAIL:
      return sendRefundItemFail(state, action);
    case actionTypes.RETURN_REFUND_ITEM_START:
      return returnRefundItemStart(state);
    case actionTypes.RETURN_REFUND_ITEM_SUCCESS:
      return returnRefundItemSuccess(state);
    case actionTypes.RETURN_REFUND_ITEM_FAIL:
      return returnRefundItemFail(state, action);
    case actionTypes.RESET_REFUND_ITEMS_STORE:
      return resetRefundItemStore();
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;
